/*
  Creating Analytic Logs
  1. Add eventName to the config below: `example_event_name`
  2. Define an array as the key: `example_event_name: [ ... ]`
  3. Define the analytic vendor as a string ie: loggly, segment, ga.  ex: `example_event_name: [ 'segment' ]`
  4. Define the analytic action for the analytic vendor separated by a colon ie: `example_event_name: [ 'segment:track' ]`
  5. Define any further props necessary for the analytic vendor, type is used to define the analytic vendor action type separated by a colon ie: `example_event_name: [ 'segment:track:info' ]`
*/

const ANALYTIC_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

// {'Event Name':['vendor:action:actionType:excludeCommonProps']}
// vendor: segment|braze
// action: track|page|event|...
// actionType: info|log|...
// excludeCommonProps: excludeCommonProps | undefined
const ANALYTIC_CONFIG = {
  'Login Button Clicked': ['segment:track:info'],
  'OS ML Auth API': ['segment:track:info'],
  'Logout Button Clicked': ['segment:track:info'],
  'Logout Result': ['segment:track:info'],
  'Search Session Started': ['segment:track:info'],
  'Search Autocomplete Suggestion': ['segment:track:info'],
  'Search Requested': ['segment:track:info'],
  'Search Responded': ['segment:track:info'],
  'Search Filter Clicked': ['segment:track:info'],
  'Asset Clicked': ['segment:track:info'],
  'Offer Popup Viewed': ['segment:track:info'],
  'Offer Popup Accepted': ['segment:track:info'],
  'Offer Popup Dismissed': ['segment:track:info'],
  'Offer Churned After Accepting': ['segment:track:info'],
  'Purchase Successful': ['segment:track:info'],
  'Purchase Errored': ['segment:track:info'],
  'Subscription Update Successful': ['segment:track:info'],
  'Subscription Update Errored': ['segment:track:info'],
  'Get Retention Offers Successful': ['segment:track:info'],
  'Get Retention Offers Errored': ['segment:track:info'],
  'Autoplay Popup Displayed': ['segment:track:info'],
  'Autoplay Next Video Triggered': ['segment:track:info'],
  'Autoplay Popup Interaction': ['segment:track:info'],
  'Complete Profile Saved': ['segment:track:info'],
  'Incomplete Profile Saved': ['segment:track:info'],
  'Logout All Devices Performed': ['segment:track:info'],
  'Checkout Form Loaded Success': ['segment:track:info'],
  'Checkout Form Loaded Failure': ['segment:track:info'],
  'Checkout Form Token Failure': ['segment:track:info'],
  'Checkout Form Token Success': ['segment:track:info'],
  'Support Issue List Errored': ['segment:track:info'],
  'Support Issue Errored': ['segment:track:info'],
  'Support Chat Errored': ['segment:track:info'],
  'Competition Entered': [
    'segment:track:info',
    'braze:event:log:excludeCommonProps',
  ],
  '/usersettings/devices': ['segment:page:info'],
  Home: ['braze:event:log:excludeCommonProps'],
  Competition: ['braze:event:log:excludeCommonProps'],
  Search: ['braze:event:log:excludeCommonProps'],
  'Live Channel': ['braze:event:log:excludeCommonProps'],
  'Support New Issue': ['braze:event:log:excludeCommonProps'],
  'Support Issue List': ['braze:event:log:excludeCommonProps'],
  'Support Chat View': ['braze:event:log:excludeCommonProps'],
  Support: ['braze:event:log:excludeCommonProps'],
  'Manage Subscription': ['braze:event:log:excludeCommonProps'],
  'Device Management': ['braze:event:log:excludeCommonProps'],
  'Match Prematch': ['braze:event:log:excludeCommonProps'],
  'Match Live': ['braze:event:log:excludeCommonProps'],
  'Match Postmatch': ['braze:event:log:excludeCommonProps'],
  'VOD Detail': ['braze:event:log:excludeCommonProps'],
  Article: ['braze:event:log:excludeCommonProps'],
  'Video Playback Requested': ['braze:event:log:excludeCommonProps'],
  'Video Playback Interrupted': ['braze:event:log:excludeCommonProps'],
  'Video Playback Completed': ['braze:event:log:excludeCommonProps'],
  'Cancel Subscription Clicked': ['braze:event:log:excludeCommonProps'],
  'Signup Email Submitted': ['braze:event:log:excludeCommonProps'],
  'Signup Mobile Verified': ['braze:event:log:excludeCommonProps'],
  'Signup Personal Details Submitted': ['braze:event:log:excludeCommonProps'],
  'Signup Password Submitted': ['braze:event:log:excludeCommonProps'],
  'Signup Successful': ['braze:event:log:excludeCommonProps'],
  'Signup Errored': ['braze:event:log:excludeCommonProps'],
  'Paywall Monthly Plan Link Clicked': ['braze:event:log:excludeCommonProps'],
  'Paywall Subhub Link Clicked': ['braze:event:log:excludeCommonProps'],
  'Paywall Annual Plan Link Clicked': ['braze:event:log:excludeCommonProps'],
  'SubHub Modal Continue': ['braze:event:log:excludeCommonProps'],
  'SubHub Modal Cancel': ['braze:event:log:excludeCommonProps'],
  'Paywall Pay Clicked': ['braze:event:log:excludeCommonProps'],
  'Article 100% Read': ['braze:event:log:excludeCommonProps'],
  'Favourite Add Saved': ['braze:event:log:excludeCommonProps'],
  'Manage SubHub Subscription': ['braze:event:log:excludeCommonProps'],
  'Content Card Clicked': ['segment:track:info'],
  'Help & Support Clicked': ['segment:track:info'],
};

const ANALYTIC_VENDOR_CONFIG = {
  segment: {
    actions: {
      identify: 'identify',
      track: 'track',
      reset: 'reset',
      page: 'page',
    },
  },
  braze: {
    actions: {
      event: 'event',
    },
  },
};

const ANALYTIC_DEFAULT_VENDOR = ANALYTIC_VENDOR_CONFIG['segment'];
const ANALYTIC_DEFAULT_ACTION = 'track';

const NEEDLES = {
  userName: 'userName',
  password: 'password',
  AccessToken: 'AccessToken',
  RefreshToken: 'RefreshToken',
  IdToken: 'IdToken',
  Authorization: 'Authorization',
  drmData: 'drmData',
  key: 'key',
};

const ANALYTIC_NEEDLES = Object.keys(NEEDLES);

const ANALYTIC_NEEDLE_REPLACER = 'hidden-PII';

const APIEVENT = {
  AUTH: 'OS ML Auth API',
  LOGOUT: 'Logout Result',
};
export {
  ANALYTIC_CONFIG,
  ANALYTIC_VENDOR_CONFIG,
  ANALYTIC_TIME_FORMAT,
  ANALYTIC_NEEDLES,
  ANALYTIC_NEEDLE_REPLACER,
  ANALYTIC_DEFAULT_VENDOR,
  ANALYTIC_DEFAULT_ACTION,
  APIEVENT,
};
