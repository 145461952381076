import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getActionHandlers, getImageProps } from '../utils';

import {
  ContentCardProvider,
  ContentCardDispatchContext,
  ContentCardContext,
} from '../ContentCardContext';
import { getEditorialById } from 'analytic/util';
import useContentCard from 'utils/hooks/useContentCard';

function ContentCardAssetWithContext({
  assetId,
  uiComponent,
  className,
  brazeContentCard,
  editorialId,
  isDynamic,
  ...props
}) {
  const contentCard = useContentCard(assetId, isDynamic);

  if (!contentCard) {
    return <></>;
  }

  return (
    <ContentCardProvider>
      <ContentCardAsset
        key={`${editorialId}-${contentCard.id}`}
        asset={contentCard}
        brazeContentCard={brazeContentCard}
        uiComponent={uiComponent}
        className={className}
        editorialId={editorialId}
        {...props}
      />
    </ContentCardProvider>
  );
}

function ContentCardAsset({
  asset,
  brazeContentCard,
  uiComponent,
  className,
  editorialId,
  ...props
}) {
  const pages = useSelector((state) => state.pages);
  const editorials = useSelector((state) => state.editorials);
  const { navigations } = useSelector((state) => state.navigation) || {};

  const dispatch = useContext(ContentCardDispatchContext);
  const contentCardContext = useContext(ContentCardContext);

  useEffect(() => {
    if (asset) {
      dispatch({
        type: 'assetReady',
        asset: { ...asset },
      });
    }
  }, [asset, dispatch]);

  const assetContext = contentCardContext?.asset;
  if (!assetContext || !uiComponent) return <></>; // to check if context is ready so it can be consumed in getActionHandlers

  const editorial = getEditorialById(editorials, editorialId);

  const actionHandlers = getActionHandlers({
    dispatch,
    pages,
    editorial,
    asset: assetContext,
    brazeContentCard,
    navigations,
    type: 'card',
  });

  const { defaultImageAspectRatio } = props;
  const { imgSrc, srcSet } = getImageProps(
    assetContext,
    defaultImageAspectRatio,
  );

  return (
    <>
      {uiComponent({
        asset: assetContext,
        actionHandlers,
        className,
        imgSrc,
        srcSet,
        ...props,
      })}{' '}
    </>
  );
}

export default React.memo(ContentCardAssetWithContext);
