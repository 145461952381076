import _ from 'lodash';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getPlaybackPath, getMobileDeviceQueryString } from 'utils/path';
import VideoInfoBox from './AssetInfoBox';
import { getArticleSectionPathName } from 'utils/getAssetData';
import { assetClickedEventDispatcher } from 'analytic/util';

class AssetCard extends Component {
  constructor(props, context) {
    super(props, context);

    this.assetOnClick = this.assetOnClick.bind(this);

    this.state = {
      isLive: false,
    };
  }

  assetOnClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const {
      isArticle,
      asset,
      categoryMapping,
      navigations,
      pages,
      editorials,
      editorialId,
    } = this.props;

    assetClickedEventDispatcher({
      pages,
      navigations,
      asset,
      editorialId,
      editorials,
    });

    let externalUrl = _.get(asset, 'externalUrl', null);

    if (externalUrl) {
      window.open(externalUrl, '_blank');
      return;
    }

    let sectionPath = _.get(asset, 'sectionPath', null);
    if (sectionPath) {
      this.props.history.push(sectionPath);
      return;
    }

    const assetId = _.get(asset, 'id');
    const assetSlug = _.get(asset, 'slug');
    let assetPath =
      isArticle && assetId && assetSlug
        ? getArticleSectionPathName(asset)
        : getPlaybackPath(asset, categoryMapping);
    this.props.history.push(
      `${assetPath}${getMobileDeviceQueryString(assetPath)}`,
    );
  }

  render() {
    let className = this.props.className;
    const localTime = moment();
    const { isArticle, asset, categoryMapping } = this.props;
    const isLive = asset ? asset.live : false;

    const extURL = _.get(this.props.asset, 'externalUrl', null);
    const sectionPath = _.get(this.props.asset, 'sectionPath', null);
    const assetId = _.get(asset, 'id');
    const assetSlug = _.get(asset, 'slug');
    const hasAssetSlug = isArticle && assetId && assetSlug;
    let assetPath = hasAssetSlug
      ? getArticleSectionPathName(asset)
      : extURL || sectionPath || getPlaybackPath(asset, categoryMapping);

    return (
      <a
        href={`${assetPath}${getMobileDeviceQueryString(assetPath)}`}
        target={extURL ? '_blank' : ''}
        rel='noreferrer'
        className={`
          text-justify
          font-white
          cursor-pointer
          text-white
          no-underline
          h-full flex
          flex-col
          justify-between
          ${className}
        `}
        onClick={this.assetOnClick}
      >
        <VideoInfoBox localTime={localTime} isLive={isLive} {...this.props} />
      </a>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { navigation, pages, editorials } = state;
  const assetTypeName = _.get(ownProps, 'asset.assetTypeName', '');
  return {
    isArticle: assetTypeName === 'article',
    assetTypeName: assetTypeName,
    navigations: navigation?.navigations,
    pages,
    editorials,
  };
}

AssetCard.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  lazyLoaded: PropTypes.bool,
  isArticle: PropTypes.bool,
};

AssetCard.defaultProps = {
  className: '',
  type: 'default',
  lazyLoaded: true,
};

export default withRouter(connect(mapStateToProps)(AssetCard));
