import React from 'react';
// import AssistIcon from '../components/ui/ui-kit/Icons/AssistIcon';
import CornerKickIcon from '../components/ui/ui-kit/Icons/CornerKickIcon';
import GoalIcon from '../components/ui/ui-kit/Icons/GoalIcon';
import GoalSaved from '../components/ui/ui-kit/Icons/GoalSaved';
import OwnGoalIcon from '../components/ui/ui-kit/Icons/OwnGoalIcon';
import PenaltyIcon from '../components/ui/ui-kit/Icons/PenaltyIcon';
import PenaltyMissedIcon from '../components/ui/ui-kit/Icons/PenaltyMissedIcon';
import RedCardIcon from '../components/ui/ui-kit/Icons/RedCardIcon';
// import SubIcon from '../components/ui/ui-kit/Icons/SubIcon';
// import SubOffIcon from '../components/ui/ui-kit/Icons/SubOffIcon';
import SubOnIcon from '../components/ui/ui-kit/Icons/SubOnIcon';
import WhistleIcon from '../components/ui/ui-kit/Icons/WhistleIcon';
import YellowCardIcon from '../components/ui/ui-kit/Icons/YellowCardIcon';
// import YellowsecondIcon from '../components/ui/ui-kit/Icons/YellowSecondIcon';

export function getIcon(eType) {
  switch (eType) {
    case 'lineup':
      return <WhistleIcon />;

    case 'start':
      return <WhistleIcon />;

    case 'miss':
      return <PenaltyMissedIcon />;

    case 'corner':
      return <CornerKickIcon />;

    case 'redcard':
      return <RedCardIcon />;

    case 'yellowcard':
      return <YellowCardIcon />;

    case 'attemptsaved':
      return <GoalSaved />;

    case 'substitution':
      return <SubOnIcon />;

    case 'goal':
      return <GoalIcon />;

    default:
      return <WhistleIcon />;
  }
}

export function getIconForHighlights(eType) {
  switch (eType) {
    case 'GOAL':
      return <GoalIcon />;
    case 'YELLOW':
      return <YellowCardIcon />;
    case 'RED':
      return <RedCardIcon />;
    case 'PENALTY':
    case 'SHOOTOUT':
      return <PenaltyIcon />;
    case 'SHOOTOUT MISS':
      return <PenaltyMissedIcon />;
    case 'OWN GOAL':
      return <OwnGoalIcon />;
    default:
      return <WhistleIcon />;
  }
}

// case "WARM UP":
// case "START 1ST HALF":
// case "END 1ST HALF":
// case "START 2ND HALF"
// case "MATCH ENDED":
