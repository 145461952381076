import _ from 'lodash';
import isNumeric from '../utils/isNumeric';
import { replaceCategoryId } from '../utils/path';
import { ContentCardTypeName } from '../components/ui/PromotionalPlacements/constants';
import { ASSET_PLAYBACK_MODES } from 'utils/getAssetData';

function getOptaItemFromNavigations(navigations, competitionId) {
  let seasonId = null;
  let parsedOptaItem = {};

  navigations.map((navigation) => {
    const optaItems = _.get(navigation, 'optaItems', []);
    const opta = _.find(optaItems, {
      competitionId: _.toNumber(competitionId),
    });

    if (optaItems.length > 0 && !_.isEmpty(opta)) {
      _.forEach(optaItems, (optaItem) => {
        const optaCompetitionId = _.toString(_.get(optaItem, 'competitionId'));
        const isActive = _.get(optaItem, 'active');
        if (isActive) {
          seasonId = _.get(optaItem, 'season');
          competitionId = _.get(optaItem, 'competitionId');
          parsedOptaItem['seasonId'] = seasonId;
          parsedOptaItem['competitionId'] = competitionId;
          return false;
        } else if (optaCompetitionId === competitionId) {
          seasonId = _.get(optaItem, 'season');
          parsedOptaItem['seasonId'] = seasonId;
          competitionId = _.get(optaItem, 'competitionId');
          parsedOptaItem['competitionId'] = competitionId;
        }
      });
    } else {
      const opta = _.get(navigation, 'opta', {});
      const optaCompetitionId = _.toString(_.get(opta, 'competitionId'));
      if (optaCompetitionId === competitionId) {
        seasonId = _.get(opta, 'season');
        parsedOptaItem['seasonId'] = seasonId;
        parsedOptaItem['competitionId'] = competitionId;
      }
    }
    return parsedOptaItem;
  });

  return parsedOptaItem;
}

export function assetMapping(asset, navigations) {
  const accessType = _.get(asset, 'accessGroups', ['PREMIUM']);
  const assetTypeName = _.get(asset, 'type', null);
  const categoryId = _.get(
    asset,
    'categorySlug',
    _.get(asset, 'categoryId', null),
  );
  const isSSAIEnabled = _.get(asset, 'isSSAIEnabled', null);
  const categoryTitle = _.get(asset, 'categoryTitle', 'Optus Sport');
  const contentType = _.get(asset, 'contentType', 'asset');
  const contentProvider = _.get(asset, 'contentProvider', 'OPTUS-SPORT');
  const cmsTags = _.get(asset, 'cmsTags', null);
  const customMetadata = _.get(asset, 'customMetadata', null);
  const description = _.get(asset, 'description', null);
  const drmProtected = _.get(asset, 'drmProtected', true);
  const duration = _.get(asset, 'duration', 0);
  const id = _.get(asset, 'id', null);
  const externalId = _.get(asset, 'externalId', null);
  const images = _.get(asset, 'images', null);
  const imagePack = _.get(asset, 'imagePack', null);
  const imagePackId = _.get(asset, 'imagePackId', null);
  const imageUrl = _.get(asset, 'imageUrl', null);
  const lastUpdateTime = _.get(asset, 'lastUpdateTime', 0);
  const live = _.get(asset, 'isLive', false);
  const onDemandTimeBegin = _.get(asset, 'onDemandTimeBegin', 0);
  const onDemandTimeEnd = _.get(asset, 'onDemandTimeEnd', 0);
  const playbackUri = `/api/web/asset/${id}/play`;
  const pbMode = _.get(
    asset,
    'pbMode',
    ASSET_PLAYBACK_MODES.FREE_WITHOUT_COGNITO_AND_CLEENG,
  );
  const published = _.get(asset, 'published', true);
  const title = _.get(asset, 'title', 'Optus Sport');
  const imageUrl_16_9 = _.get(asset, 'imageUrl_16:9', null);
  const optaHomeTeamId = _.get(asset, 'match.homeTeam.id', null);
  const optaAwayTeamId = _.get(asset, 'match.awayTeam.id', null);
  const liveBroadcastTime = _.get(asset, 'broadcastStartTime', null);
  const broadcastEndTime = _.get(asset, 'broadcastEndTime', null);
  const createdAt = _.get(asset, 'createdAt', null);
  const seoMetaDescription =
    _.get(asset, 'seoMetaDescription', '') || _.get(asset, 'description', '');
  const seoTitle = _.get(asset, 'seoTitle', title);
  const source = _.get(asset, 'source', '');
  const type = _.get(asset, 'type', '');
  const updatedAt = _.get(asset, 'updatedAt', '');
  const useVccVod = _.get(asset, 'useVccVod', '');
  const expireDate = _.get(asset, 'publishEndDate', null);
  const startDate = _.get(asset, 'publishStartDate', null);

  // TODO: convert competitionId to number here and remove conversion to number from inside the core logic
  let competitionId = _.get(asset, 'match.competition.id', null);
  const matchId = _.get(asset, 'match.id', null);
  const slug = _.get(asset, 'slug', null);
  const position = _.get(asset, 'position', '');
  const { match = null, tags = '' } = asset;
  let optaSeasonId = null;
  if (competitionId && !_.isEmpty(navigations)) {
    const parsedOptaItem = getOptaItemFromNavigations(
      navigations,
      competitionId,
    );
    optaSeasonId = _.get(parsedOptaItem, 'seasonId', null);
    competitionId = _.get(parsedOptaItem, 'competitionId', null);
  }
  const isInTransition = _.get(asset, 'isInTransition', false);

  const channel = _.get(asset, 'channel', {});

  // We should create the section path here by running through the category id mapping
  // and cross check it against the valid competitions in navigations
  let categorySectionPath = null;
  if (_.isEmpty(categoryId) || !isNumeric(categoryId)) {
    categorySectionPath = replaceCategoryId(categoryId);

    if (
      !_.find(
        navigations,
        (competition) => _.get(competition, 'path') === categorySectionPath,
      )
    ) {
      categorySectionPath = '';
    }
  }

  const contentCard = asset?.contentCard;

  return {
    accessType: accessType,
    assetTypeName: assetTypeName,
    categoryId: categoryId,
    categoryTitle: categoryTitle,
    cmsTags: cmsTags,
    customMetadata: customMetadata,
    contentType: contentType,
    contentProvider,
    description: description,
    drmProtected: drmProtected,
    duration: duration,
    id: id,
    imagePack: imagePack,
    imagePackId: imagePackId,
    imageUrl: imageUrl,
    imageUrl_16_9: imageUrl_16_9,
    lastUpdateTime: lastUpdateTime,
    live: live,
    liveBroadcastTime: liveBroadcastTime,
    broadcastEndTime: broadcastEndTime,
    createdAt: createdAt,
    seoMetaDescription: seoMetaDescription,
    seoTitle: seoTitle,
    source: source,
    type: type,
    updatedAt: updatedAt,
    useVccVod: useVccVod,
    onDemandTimeBegin: onDemandTimeBegin,
    onDemandTimeEnd: onDemandTimeEnd,
    playbackUri: playbackUri,
    published: published,
    title: title,
    optaHomeTeamId: optaHomeTeamId,
    optaAwayTeamId: optaAwayTeamId,
    expireDate: expireDate,
    startDate,
    optaCompetitionId: competitionId,
    optaMatchId: matchId,
    optaSeasonId: optaSeasonId,
    slug,
    isInTransition: isInTransition,
    pbMode,
    externalId,
    match,
    tags,
    channel,
    categorySectionPath,
    isSSAIEnabled,
    position,
    images,
    ...{ contentCard },
  };
}

export function contentCardFilter(assets, isPromotionalPlacementsEnable) {
  const filteredAssets = assets.filter(
    (asset) =>
      asset?.type !== ContentCardTypeName || isPromotionalPlacementsEnable,
  );
  return filteredAssets;
}
