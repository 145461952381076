import _ from 'lodash';

const user = (state) => state?.user;
const initObserver = (state) => state?.user && state.user?.initObserver;
const isLoggedIn = (state) =>
  state?.user && state?.user.initObserver && state.user?.loggedIn;
const userType = (state) => state?.user && state.user?.userType;
const userTypeUpdated = (state) => userType(state)?.updated;
const userStrictType = (state) =>
  state?.user &&
  state.user?.loggedIn &&
  userType(state)?.updated &&
  userType(state)?.type;
const isPremiumUser = (state) =>
  isLoggedIn(state) && userStrictType(state) === 'premium';

const getUserEmail = (user) =>
  _.get(user, ['authData', 'cognitoUser', 'username'], '');

const getUserId = (user) => {
  // Sometimes there is race condition as analytics.analyticUserId is not ready/set
  // on hard page loads so we will fallback onto user management id (Cognito user id)
  const userAuthDataId = _.get(user, [
    'authData',
    'cognitoUser',
    'attributes',
    'sub',
  ]);
  let userId = _.get(user, `analytics.analyticUserId`);

  userId =
    (!userId || userId === '-1') && !_.isEmpty(userAuthDataId)
      ? userAuthDataId
      : userId;

  return userId;
};

const isInternationalUser = (state) =>
  state?.user && state?.user?.loggedIn && state?.user?.isIntlUser;

const getUserSegmentTags = (user) => {
  const userSubscriptions = _.get(user, 'orders') || [];

  return userSubscriptions.map((subscription) =>
    _.get(subscription, 'segmentTag'),
  );
};

const isUserSettingsInProgress = (state) =>
  state?.user && state?.user?.loggedIn && state?.user?.settings?.inProgress;

const getUserTags = (state) => {
  if (!initObserver(state)) return false; // login process is not done
  if (!isLoggedIn(state)) return ['NOT_LOGGED_IN'];
  if (isUserSettingsInProgress(state)) return false; // even the user is logged in, there is a time gap until the user details loaded
  const {
    user: { userTags },
  } = state;
  return userTags || [];
};

const getFavTeamCode = (state) => {
  const initState = { isReady: false, data: false }; // to differenciate the state between user not logged in and login in progress
  if (!initObserver(state) || isUserSettingsInProgress(state)) return initState;
  if (!isLoggedIn(state)) return { ...initState, isReady: true };
  const favouriteTeams = state.user?.settings?.football?.favouriteTeams;
  const favTeam =
    favouriteTeams && _.isArray(favouriteTeams) && favouriteTeams[0];
  return { isReady: true, data: favTeam && favTeam.optusTeamCode };
};

const getOptusTeamCodeById = (id) => (state) => {
  const allTeams = _.get(state, 'teams.allTeams', []);
  if (allTeams.length) {
    return _.get(_.find(allTeams, { id }), 'optusTeamCode', '');
  }
};

const getAllFavouriteTeams = (state) => {
  return _.get(state, ['user', 'settings', 'football', 'favouriteTeams'], []);
};

const getAllFavouriteTeamIds = (state) => {
  const favouriteTeams = getAllFavouriteTeams(state);
  if (favouriteTeams.length) {
    return favouriteTeams.map((team) => ({
      id: team.id,
    }));
  }

  return [];
};

const getIsCybersourceUser = (state) => {
  const { user: { previewCyberSource } = {} } = state || {};
  return previewCyberSource;
};

export {
  user,
  initObserver,
  isLoggedIn,
  userType,
  userStrictType,
  isPremiumUser,
  getUserId,
  getUserEmail,
  userTypeUpdated,
  getUserSegmentTags,
  isInternationalUser,
  isUserSettingsInProgress,
  getUserTags,
  getFavTeamCode,
  getOptusTeamCodeById,
  getAllFavouriteTeams,
  getAllFavouriteTeamIds,
  getIsCybersourceUser,
};
