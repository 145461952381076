import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as matchHighlightsActions from '../../../actions/matchHighlights';
import ResultSummaryPopover from './ResultSummaryPopover';

class FixtureScore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSummary: false,
      competitionId: this.props.competitionId,
      season: this.props.season,
      matchId: this.props.matchId,
    };
  }

  loadMatchHighlights = (competitionId, season, matchId) => {
    this.props.matchHighlightsActions.getMatchHighlights(
      competitionId,
      season,
      matchId,
    );
  };

  render() {
    const {
      score1,
      score1Pk,
      score2,
      score2Pk,
      boldLeft,
      boldRight,
      className,
      showPopover,
    } = this.props;

    return (
      <div className='relative'>
        <div
          onMouseEnter={() =>
            this.setState({ showSummary: true }, () =>
              this.loadMatchHighlights(
                this.state.competitionId,
                this.state.season,
                this.state.matchId,
              ),
            )
          }
          onMouseLeave={() => this.setState({ showSummary: false })}
          className={`${
            this.props.mini ? 'mini h-20 min-w-48' : 'hidden'
          } relative bg-medium-dark-grey sm:flex sm:flex-row sm:items-center sm:justify-center text-center py-2 ${
            className !== null ? ` ${className}` : ''
          }`}
        >
          {showPopover && (
            <ResultSummaryPopover
              showSummary={this.state.showSummary}
              competitionId={this.props.competitionId}
              season={this.props.season}
              matchId={this.props.matchId}
              sectionHighlights={this.props.matchhighlights}
              inProgress={this.props.inProgress}
            />
          )}

          <p
            className={`${
              this.props.mini
                ? 'text-base leading-none py-0 relative  md:static'
                : 'min-w-148 px-5 lg:px-28 leading-base py-2 lg:py-8'
            } text-white`}
          >
            <span
              className={`leading-base os-tabular-nums ${
                boldLeft ? 'font-MarkProHeavy text-base' : 'font-MarkPro'
              }`}
            >
              {score1}
              {score1Pk && (
                <span className={'font-MarkPro text-light-indicator-grey'}>
                  {' '}
                  ({score1Pk})
                </span>
              )}
            </span>
            <span className='mx-5'>-</span>
            <span
              className={`leading-base os-tabular-nums ${
                boldRight ? 'font-MarkProHeavy text-base' : 'font-MarkPro'
              }`}
            >
              {score2}
              {score2Pk && (
                <span className={'font-MarkPro text-light-indicator-grey'}>
                  {' '}
                  ({score2Pk})
                </span>
              )}
            </span>
          </p>
        </div>
      </div>
    );
  }
}

FixtureScore.propTypes = {
  /** Team 1 score */
  score1: PropTypes.string.isRequired,
  /** Team 1 Penalty Kick score */
  score1Pk: PropTypes.string,
  /** Team 2 score */
  score2: PropTypes.string.isRequired,
  /** Team 2 Penalty Kick score */
  score2Pk: PropTypes.string,
  /** Summary to show inside the popup on hover. */
  resultSummary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Whether the score on left is bold or not. */
  boldLeft: PropTypes.bool,
  /** Whether the score on Right is bold or not. */
  boldRight: PropTypes.bool,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** To render a compact version . */
  mini: PropTypes.bool,
};

FixtureScore.defaultProps = {
  boldLeft: false,
  boldRight: false,
  className: null,
  resultSummary: null,
  showPopover: true,
};

function mapStateToProps(state, ownProps) {
  return {
    matchhighlights: state.matchhighlights.matchhighlights,
    inProgress: state.matchhighlights.inProgress,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    matchHighlightsActions: bindActionCreators(
      matchHighlightsActions,
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FixtureScore);
