import React, { Component } from 'react';
import Tappable from 'react-tappable';
import FixtureTeam from './FixtureTeam';
import FixtureScore from '../FixtureScore';
import DrawIcon from './DrawIcon';
import WinIcon from '../Icons/WinIcon';
import LossIcon from '../Icons/LossIcon';

class WinLossDrawBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      winlossdrawPopoverShown: false,
    };
  }

  findTeamShortCode = (teamID, teamlist) => {
    if (teamlist && teamlist.length !== 0) {
      const team = teamlist.find((e) => e.TeamID === teamID);
      const teamShortCode = team ? team.TeamCodeName : '';
      return teamShortCode;
    }
  };

  handleTap = (e) => {
    this.setState({
      winlossdrawPopoverShown: !this.state.winlossdrawPopoverShown,
    });
  };

  render() {
    const {
      HomeScore = '',
      HomeScorePK = '',
      AwayScore = '',
      AwayScorePK = '',
    } = this.props;
    const homeTeamID = this.props.HomeTeamID;
    const awayTeamID = this.props.AwayTeamID;
    const homeTeamShortCode = this.findTeamShortCode(
      homeTeamID,
      this.props.sectionTables,
    );
    const awayTeamShortCode = this.findTeamShortCode(
      awayTeamID,
      this.props.sectionTables,
    );

    const homeTeamImg = this.props.HomeTeamImageURL || '';
    const awayTeamImg = this.props.AwayTeamImageURL || '';

    let boldLeft = Number(HomeScore) > Number(AwayScore);
    let boldRight = Number(this.props.AwayScore) > Number(this.props.HomeScore);

    if (HomeScorePK && AwayScorePK) {
      boldLeft = Number(HomeScorePK) > Number(AwayScorePK);
      boldRight = Number(AwayScorePK) > Number(HomeScorePK);
    }

    return (
      <Tappable onTap={(e) => this.handleTap(e)}>
        <span
          className='inline-block cursor-pointer p-0 m-0 relative pointer-events-none xl:pointer-events-auto outline-none focus:outline-none active:outline-none'
          onMouseEnter={() => this.setState({ winlossdrawPopoverShown: true })}
          onMouseLeave={() => this.setState({ winlossdrawPopoverShown: false })}
        >
          <div
            className={`winlossdraw-popover-wrap absolute w-279 h-80 bg-medium-dark-grey os-triangle-after-popup p-16 shadow-fixture-score z-99999 ${
              this.state.winlossdrawPopoverShown
                ? 'xl:flex xl:flex-wrap xl:justify-center popover-fadein'
                : 'hidden popover-fadeout'
            }`}
          >
            <h2 className='text-center text-white text-xs font-MarkPro mb-10 normal-case'>
              {this.props.nextMatchDate}
            </h2>

            <div className='flex flex-no-wrap justify-center items-center'>
              <div className='team-one flex-1 text-right'>
                <FixtureTeam
                  imgUrl={homeTeamImg}
                  team={homeTeamShortCode || ''}
                  reverse
                  teamShortCode={homeTeamShortCode || ''}
                />
              </div>

              <div className='score-card mx-7 flex-no-grow flex-no-shrink min-w-48 relative os-top--2'>
                <FixtureScore
                  score1={HomeScore || ''}
                  score1Pk={HomeScorePK || ''}
                  score2={AwayScore || ''}
                  score2Pk={AwayScorePK || ''}
                  boldLeft={boldLeft}
                  boldRight={boldRight}
                  type='time'
                  mini
                />
              </div>

              <div className='team-two flex-1'>
                <FixtureTeam
                  imgUrl={awayTeamImg}
                  team={awayTeamShortCode || ''}
                  teamShortCode={awayTeamShortCode || ''}
                />
              </div>
            </div>
          </div>

          {this.props.type === 'win' ? (
            <WinIcon height='20' width='20' />
          ) : this.props.type === 'loss' ? (
            <LossIcon height='20' width='20' />
          ) : this.props.type === 'draw' ? (
            <DrawIcon height='20' width='20' />
          ) : (
            ''
          )}
        </span>
      </Tappable>
    );
  }
}

export default WinLossDrawBlock;
