import _ from 'lodash';
import { categoryUrlMapping } from 'utils/path';

const tagKeys = {
  fitness: [
    'WORKOUT_TIME',
    'INTENSITY',
    'WORKOUT_TYPE',
    'LEVEL',
    'TARGET_AREA',
    'GOALS',
    'ENVIRONMENT',
  ],
};

export const ASSET_PLAYBACK_MODES = {
  FREE_WITHOUT_COGNITO_AND_CLEENG: 'FREE_WITHOUT_COGNITO_AND_CLEENG',
  FREE_WITHOUT_LOGIN: 'FREE_WITHOUT_LOGIN',
  FREE_WITH_LOGIN: 'FREE_WITH_LOGIN',
  PREMIUM: 'PREMIUM',
};

const TagKeyMapping = {
  WORKOUT_TIME: 'Time',
  INTENSITY: 'Intensity',
  WORKOUT_TYPE: 'Style',
  LEVEL: 'Level',
  TARGET_AREA: 'Target Area',
  GOALS: 'Goal',
  ENVIRONMENT: 'Environment',
};

export const getAllTags = (data, tagClass) => {
  const allTags = [];
  tagKeys[tagClass].forEach((tagKey) => {
    const value = getCmsTags(data, tagKey);
    if (value) {
      allTags.push({
        title: TagKeyMapping[tagKey],
        description: value,
      });
    }
  });
  return allTags;
};

export const isCmsTagValue = (asset, cmsTagKey, value) => {
  const cmsTags = getCmsTags(asset, cmsTagKey, true);
  if (cmsTags) {
    if (Array.isArray(cmsTags)) {
      return cmsTags
        .map((tag) => tag.toLowerCase())
        .includes(value?.toLowerCase());
    }
    return cmsTags.toLowerCase() === value?.toLowerCase();
  }
  return false;
};

export const getCmsTags = (data, objKey, allValues = false) => {
  const cmsTags = _.get(data, 'cmsTags', '');
  if (Array.isArray(cmsTags)) {
    const value = cmsTags.filter((obj) => obj.key === objKey)[0]?.values;
    if (allValues) return value;
    if (value) return value[0];
  }
  return null;
};

export const getTags = (data, objKey) => {
  const tags = _.get(data, 'tags', '');
  if (Array.isArray(tags)) {
    const value = tags.filter((obj) => obj.type === objKey)[0]?.name;
    if (value) return value;
  }
  return null;
};

export const isFitnessSectionEnabled = (data) => {
  const path = window.location.pathname.toLowerCase();
  const isFitnessPage = path.split('/')[1] === 'fitness';
  return (
    isFitnessPage ||
    data === 'fitness' ||
    _.get(data, 'assetTypeName', '') === 'fitness'
  );
};

export const isFitnessVOD = (data) => {
  const assetTypeName = _.get(data, 'assetTypeName', '');
  return isFitnessSectionEnabled(data) && assetTypeName !== '';
};

export const getArticleSectionPath = (data, definedSectionPath) => {
  const categoryId = _.get(data, 'categoryId');
  const categorySlug = _.get(data, ['category', 'slug']);
  const sectionPath =
    definedSectionPath ||
    categoryUrlMapping(categoryId) ||
    categoryUrlMapping(categorySlug);
  return sectionPath ? `${sectionPath}` : 'all';
};

/*
 * Replaces url with correct sectionid based on asset data.
 * eg: articles with EPL content will return '/epl/articles/...' if current pathname is '/articles/...' or '/j-league/articles'
 * @param {data}           asset data of the article
 */
export const getArticleSectionPathName = (data) => {
  const section = getArticleSectionPath(data) || '';
  const id = _.get(data, 'id', '');
  const slug = _.get(data, 'slug', '');
  return `/news/${section}/${id}/${slug}`;
};

export const getImageUrlByResolution = (images, title, width) => {
  const foundImages = _.get(_.find(images, { title }), 'resolutions');
  return _.get(_.find(foundImages, { width }), 'url', '');
};

export const shouldShowFreeBadge = (
  isPremiumUser = false,
  isLoggedIn = false,
  asset,
) => {
  const isFitness =
    _.get(asset, 'type', _.get(asset, 'assetTypeName', false)) === 'fitness';
  const pbMode = _.get(asset, 'pbMode');
  if (isFitness) {
    // show free badge if asset is fitness && is FREE_WITHOUT_LOGIN || FREE_WITHOUT_COGNITO_AND_CLEENG && user is logged out
    return (
      !isLoggedIn &&
      (pbMode === ASSET_PLAYBACK_MODES.FREE_WITHOUT_LOGIN ||
        pbMode === ASSET_PLAYBACK_MODES.FREE_WITHOUT_COGNITO_AND_CLEENG)
    );
  }
  const isArticle =
    _.get(asset, 'type', _.get(asset, 'assetTypeName', false)) === 'article';
  if (isArticle) {
    return false;
  }
  return pbMode === ASSET_PLAYBACK_MODES.PREMIUM ? false : !isPremiumUser;
};
