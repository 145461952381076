import _, { get } from 'lodash';
import { start, success, fail, apiAction } from '../middleware/fetch';
import { resetLoader } from './loading';
import { getSubscription } from './subscription';
import { analyticAction } from './analytic';
import getFlags from './features';
import { getPlatformTypeByTag } from 'components/ui/CustomPage/widgets/OffersList/util';
import { getUserId } from 'selectors/user';
import { CybersourceKey } from 'lib/cybersource/const';
import { isApiUrlV3 } from 'utils/api';

export const PAYMENT_ONE_TIME_TOKEN = `payment/ONE_TIME_TOKEN`;
export const PAYMENT_INTENT = `payment/INTENT`;
export const PAYMENT_DETAILS_FETCH = `payment/DETAILS_FETCH`;
export const PAYMENT_DETAILS_DELETE = `payment/DETAILS_DELETE`;
export const PAYMENT_DETAILS_UPDATE = `payment/DETAILS_UPDATE`;

const initialState = {
  token: null,
  ok: null,
  paymentResult: null,
  paymentOfferId: null,
  sessionId: null,
  isCybersource: false,
};

// TODO: Move to utils
const getErrorCode = (error = {}) => {
  const { errors = [] } = error;
  const [firstError = {}] = errors;
  const { code } = firstError;
  return code;
};

// TODO: Move to utils
const isNoDetails = (code) => code === 'GET_PAYMENTS_NO_DETAILS';

const isValidToken = (token) => token && !token.error;

const paymentReducer = (state = initialState, action) => {
  const { type, payload, apiActionContext } = action;
  switch (type) {
    case start(PAYMENT_ONE_TIME_TOKEN): {
      const { isCybersource } = payload || {};
      return {
        ...state,
        ok: null,
        token: null,
        error: null,
        sessionId: null,
        ...(isCybersource ? { isCybersource } : {}),
      };
    }
    case success(PAYMENT_ONE_TIME_TOKEN): {
      const { isCybersource } = state;
      const { token } = payload;
      const sessionId = isCybersource && token?.sessionId;
      const payloadToken = isCybersource && token?.token ? token.token : token;
      return {
        ...state,
        token: payloadToken,
        ...(sessionId ? { sessionId } : {}),
      };
    }
    case start(PAYMENT_INTENT): {
      return {
        ...state,
        ok: null,
        error: null,
        paymentIntent: null,
        paymentResult: null,
        paymentOfferId: null,
      };
    }
    case success(PAYMENT_INTENT): {
      const { apiActionContext } = action;
      const { url, options } = apiActionContext;

      const isApiV3 = isApiUrlV3(url);

      const paymentResponse = isApiV3
        ? payload?.data?.payment
        : payload?.payment || {};

      triggerSegmentAnalytics('Purchase Successful', action);
      return {
        ...state,
        ok: true,
        token: null,
        paymentIntent: url,
        paymentResult: paymentResponse,
        paymentOfferId: options?.paymentOfferId,
        sessionId: null,
      };
    }
    case fail(PAYMENT_INTENT): {
      triggerSegmentAnalytics('Purchase Errored', action);
      const { apiActionContext: { url } = {} } = action || {};
      const isApiV3 = isApiUrlV3(url);
      const { error } = payload;
      const errorV3 = {
        errors: [error],
      };
      return {
        ...state,
        ok: false,
        // TODO: Decide if we keep the token on fail or not...
        token: null,
        error: isApiV3 ? errorV3 : error,
        sessionId: null,
      };
    }
    case start(PAYMENT_DETAILS_FETCH): {
      const { keepErrorOk } = payload;
      return {
        ...state,
        ok: keepErrorOk ? state.ok : null,
        token: null,
        error: keepErrorOk ? state.error : null,
        details: null,
        sessionId: null,
      };
    }
    case success(PAYMENT_DETAILS_FETCH): {
      const { paymentDetails = [] } = payload || {};
      const details = paymentDetails.map((p) => ({
        ...p,
        cardExpirationDate: _.get(
          p,
          `paymentMethodSpecificParams.cardExpirationDate`,
        ),
        cardLastFourDigits: _.get(
          p,
          `paymentMethodSpecificParams.lastCardFourDigits`,
        ),
        cardBrand: _.get(p, `paymentMethodSpecificParams.variant`),
      }));
      return {
        ...state,
        details,
      };
    }
    case fail(PAYMENT_DETAILS_FETCH): {
      // Use case where error object does not wrap i.e. errors: [] instead of error: { errors: [] }
      // This occurs when an error is returned in 200 status response
      const error = _.get(payload, 'error', { ...payload });

      const code = getErrorCode(error);
      // Only report errors that are legitimate, user with no existing details are not considered
      // actual errors
      const errorData = !isNoDetails(code) && { error };
      const { details } = state;
      return {
        ...state,
        ...errorData,
        details: details || [],
      };
    }
    case start(PAYMENT_DETAILS_UPDATE): {
      return {
        ...state,
        ok: null,
        error: null,
      };
    }
    case success(PAYMENT_DETAILS_UPDATE): {
      const paymentDetailsId = _.get(payload, 'paymentDetails.id', '');
      const {
        options: {
          additionalParams: { items = [], orderId = '' },
        },
      } = apiActionContext;
      if (!_.isEmpty(items) && paymentDetailsId) {
        action.asyncDispatch(
          sendPaymentIntent({
            items,
            orderId,
            paymentDetailsId,
          }),
        );
      }
      return {
        ...state,
        ok: true,
      };
    }
    case fail(PAYMENT_DETAILS_DELETE): {
      return {
        ...state,
        ...payload,
      };
    }
    case fail(PAYMENT_DETAILS_UPDATE): {
      return {
        ...state,
        ...payload,
        ok: false,
      };
    }
    default:
      return state;
  }
};

const hasPaymentDetails = (payment) => {
  return payment.details && !_.isEmpty(payment.details);
};

const onOneTimeToken = (payload) => {
  return {
    type: start(PAYMENT_ONE_TIME_TOKEN),
    payload,
  };
};

const saveOneTimeToken = (token) => {
  return {
    type: success(PAYMENT_ONE_TIME_TOKEN),
    payload: { token },
  };
};

const sendPaymentIntent =
  ({
    token,
    items,
    orderId = '',
    paymentDetailsId = '',
    sessionId,
    tag = '',
  }) =>
  (dispatch, getState) => {
    const { subscription, payment, systemConfig, user } = getState() || {};

    const getFeatureFlags = getFlags({
      systemConfig,
    });

    const isSelfServeOffers = getFeatureFlags(`SELF_SERVE_OFFERS`);

    const payload = {
      token: token?.token?.source !== CybersourceKey ? token : { ...token },
    };

    const userId = getUserId(user);
    const currentSubscription = getSubscription(subscription);
    const paymentDetailId =
      paymentDetailsId || _.get(payment, 'details.0.id', '');

    const currentSubscriptionOfferId = _.get(
      currentSubscription,
      ['snapshotOffer', 'id'],
      _.get(currentSubscription, 'id', null),
    );
    const isChangePlan = !!currentSubscriptionOfferId;

    if (orderId && orderId !== '') {
      _.set(payload, 'orderId', orderId);
    }

    let path = undefined;
    let useApi = 'fe-api-newPlan';

    if (isSelfServeOffers) {
      const selectedOfferId = items[0];
      const ctvConfig = getFeatureFlags(`CTV_CONFIG`);
      const platformType = getPlatformTypeByTag({ tag, ctvConfig });
      _.set(payload, 'platformType', platformType);
      const userId = getUserId(user);
      useApi = 'fe-api-makePurchaseOffer';
      path = `users/${userId}/osOffers/${selectedOfferId}`;
      if (isChangePlan) {
        _.set(payload, 'mode', 'upgrade');
      }
    } else {
      let payloadOsOfferId = '';
      const parsedItems = items.map((item) => {
        const [offerId, osOfferId] = item.split('::');
        payloadOsOfferId = osOfferId;
        return offerId;
      });
      _.set(payload, 'items', parsedItems);
      _.set(payload, 'osOfferId', payloadOsOfferId);

      if (isChangePlan) {
        useApi = 'fe-api-changePlan';
        path = `/users/${userId}/switch`;

        _.set(payload, 'from', currentSubscriptionOfferId);
      }
    }

    if (isChangePlan && paymentDetailId && !token) {
      _.set(payload, 'token', paymentDetailId);
    }

    // Fingerprint sessionId
    if (sessionId) {
      _.set(payload, 'sessionId', sessionId);
    }

    dispatch(
      apiAction(PAYMENT_INTENT, useApi, {
        method: 'post',
        body: payload,
        paymentOfferId: subscription?.selections,
        ...(path && { path }),
      }),
    );
  };

const triggerSegmentAnalytics = (eventName, action) => {
  const { apiActionContext, payload } = action;
  const url = _.get(apiActionContext, 'url', '');

  const isApiV3 = isApiUrlV3(url);
  const isUpgrade =
    _.get(apiActionContext, 'options.body.mode', '') === 'upgrade';

  const isNewPlan = url === 'fe-api-newPlan' || (isApiV3 && !isUpgrade);

  const offerId = _.get(apiActionContext, 'options.paymentOfferId[0]', '');

  const { code: errorCode = '', message: errorMessage = '' } = _.get(
    payload,
    isApiV3 ? 'error' : 'error.errors[0]',
    {},
  );
  let analyticObj = {
    eventName,
    id: offerId,
    name: offerId,
    ...(errorCode && { errorCode }),
    ...(errorMessage && { errorMessage }),
  };

  if (isNewPlan) {
    action.asyncDispatch(
      analyticAction({
        ...analyticObj,
        type: 'payment',
        userType: 'premium',
      }),
    );
  } else {
    action.asyncDispatch(
      analyticAction({
        ...analyticObj,
        type: 'switch',
      }),
    );
  }
};

const getPaymentDetails = (keepErrorOk = false) => {
  return (dispatch) => {
    dispatch(
      resetLoader([
        PAYMENT_DETAILS_DELETE,
        PAYMENT_DETAILS_UPDATE,
        PAYMENT_INTENT,
      ]),
    );
    dispatch(
      apiAction(PAYMENT_DETAILS_FETCH, `fe-api-payments`, { keepErrorOk }),
    );
  };
};

const deletePaymentDetails = (paymentDetailsId) => {
  return apiAction(PAYMENT_DETAILS_DELETE, `fe-api-payments`, {
    method: `delete`,
    body: {
      paymentDetailsId,
    },
  });
};

const updatePaymentDetails = ({ paymentDetailsId, token, items, orderId }) => {
  const bodyPayload =
    token?.token?.source !== CybersourceKey
      ? {
          paymentDetailsId,
          token,
        }
      : { paymentDetailsId, ...token };
  return apiAction(PAYMENT_DETAILS_UPDATE, `fe-api-payments`, {
    method: `put`,
    body: bodyPayload,
    additionalParams: {
      items,
      orderId,
    },
  });
};

export {
  paymentReducer,
  isValidToken,
  hasPaymentDetails,
  onOneTimeToken,
  saveOneTimeToken,
  getPaymentDetails,
  deletePaymentDetails,
  sendPaymentIntent,
  updatePaymentDetails,
};

export default paymentReducer;
