import { init } from 'lib/braze';
import * as braze from '@braze/web-sdk';
import { isInPrivateWindow, isSSRUserAgent } from './userAgent';
import { log } from 'components/ui/PromotionalPlacements/utils';

const brazeStore = () => {
  const initData = {
    isInitialized: false,
    enableDebug: false,
    analytics: {
      logContentCardImpressions: false,
      logContentCardClick: false,
    },
  };

  let storeData = {};

  const setData = (data) => {
    storeData = { ...storeData, ...data };
  };
  const getData = (key) => {
    return storeData[key];
  };

  return {
    init(data) {
      setData({ ...initData, ...data });
    },
    setData(newState) {
      setData(newState);
    },
    getData(key) {
      return getData(key);
    },
  };
};

export const brazeState = brazeStore();
brazeState.init();

export const getIsBrazeReady = () => brazeState.getData('isInitialized');

export function isBrazeEnabled({ featureConfig, userLoggedIn }) {
  const {
    enabled: isPromotionalPlacementEnabled,
    braze: {
      enabled: isBrazeEnabled,
      disableLoggedOutUsers: {
        always: alwaysDisableForLoggedOutUsers,
        whenUsingIncognito: disableWhenLoggedoutUserUsingIncognito,
      },
    },
  } = featureConfig || {};

  if (!userLoggedIn) {
    if (
      alwaysDisableForLoggedOutUsers ||
      (disableWhenLoggedoutUserUsingIncognito && isInPrivateWindow())
    ) {
      return false;
    }
  }
  return (
    isPromotionalPlacementEnabled &&
    isBrazeEnabled &&
    navigator.cookieEnabled &&
    !isSSRUserAgent()
  );
}

export function setupBraze({
  featureConfig,
  userId,
  userLoggedIn,
  brazeContentCardsUpdatedHandler,
}) {
  const isBrazeReady = getIsBrazeReady();
  const brazeUserId = getBrazeUserId(userId, userLoggedIn);
  const {
    braze: { sessionTimeoutInSeconds = 180, analytics },
  } = featureConfig;
  if (!isBrazeReady) {
    const isInitialized = init({
      featureConfig,
      userId: brazeUserId,
      initOption: {
        enableLogging: brazeState.getData('enableDebug'),
        allowUserSuppliedJavascript: true,
        sessionTimeoutInSeconds,
      },
      option: { automaticallyShowInAppMessages: true },
      brazeContentCardsUpdatedHandler,
    });
    // braze.requestPushPermission();
    brazeState.setData({ isInitialized });
    analytics && brazeState.setData({ analytics });
  }

  return true;
}

export const destroyBraze = () => {
  const isBrazeReady = getIsBrazeReady();
  if (isBrazeReady) {
    log('====destroyBraze=====');
    braze.wipeData();
    braze.destroy();
    braze.disableSDK();
    brazeState.setData({ isInitialized: false });
  }
};

export function setBrazeUserProfile(userProfile) {
  const isBrazeReady = getIsBrazeReady();
  if (isBrazeReady) {
    const brazeUser = braze.getUser();
    if (brazeUser && userProfile) {
      const { firstName } = userProfile;

      firstName &&
        typeof brazeUser.setFirstName === 'function' &&
        brazeUser.setFirstName(firstName);
    }
  }
}

function getBrazeUserId(userId, userLoggedIn) {
  const brazeUserId = userLoggedIn ? userId : false;
  return brazeUserId;
}

// const genderMap = new Map([
//   ['female', 'f'],
//   ['male', 'm'],
//   ['not-applicable', 'n'],
//   ['non-binary', 'o'],
// ]);

export function brazeEvent({ eventName, ...payload }) {
  const { eventProps } = payload || {};
  const isBrazeReady = getIsBrazeReady();
  isBrazeReady && eventName && braze.logCustomEvent(eventName, eventProps);
}

export const logContentCardImpressions = (card) => {
  const isBrazeReady = getIsBrazeReady();
  if (isBrazeReady && card) {
    const { logContentCardImpressions: isCCIEnabled } =
      brazeState.getData('analytics');
    isCCIEnabled && braze.logContentCardImpressions([card]);
  }
};

export const logContentCardClick = (card) => {
  const isBrazeReady = getIsBrazeReady();
  if (isBrazeReady && card) {
    const { logContentCardClick: isCCCEnabled } =
      brazeState.getData('analytics');
    isCCCEnabled && braze.logContentCardClick(card);
  }
};
