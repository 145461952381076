import { get, first, isEmpty } from 'lodash';
import { start, success, fail, apiAction } from '../middleware/fetch';
import { getUserId } from 'selectors/user';
import { resetLoader } from './loading';
import {
  CUSTOMER_SUBSCRIPTION_UPDATE,
  CUSTOMER_SUBSCRIPTION_GET_RECURRING,
  getUserDetails,
  getRecurringSubscriptionsAction,
} from './subscription';
import { analyticAction } from './analytic';
import getFlags from './features';
import { isApiUrlV3 } from 'utils/api';

export const RETENTION_DETAILS_FETCH = `retention/DETAILS_FETCH`;
export const RETENTION_OFFER_REDEEM = `retention/OFFER_REDEEM`;
export const RETENTION_REDEEM_RESET = `retention/REDEEM_RESET`;
export const RETENTION_ERROR_RESET = `retention/RESET_ERROR`;
export const RETENTION_REDEEM_HISTORY_FETCH = 'retention/REDEEM_HISTORY_FETCH';

const initialState = {
  offers: null,
  error: null,
};

export function fetchRetentions() {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const currentUserId = getUserId(user);
    dispatch(resetLoader(CUSTOMER_SUBSCRIPTION_UPDATE));
    dispatch(
      apiAction(RETENTION_DETAILS_FETCH, `fe-api-userEligibleCampaigns`, {
        path: `/${currentUserId}/offerCampaigns`,
      }),
    );
  };
}

export function fetchRetentionRedeemHistory(params = { activeOnly: true }) {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const currentUserId = getUserId(user);
    dispatch(
      apiAction(RETENTION_REDEEM_HISTORY_FETCH, `fe-api-redeemHistory`, {
        path: `/${currentUserId}/redeemHistory`,
        queryStringParameters: {
          include: 'creditCard',
          ...params,
        },
      }),
    );
  };
}

export function resetRetentionOfferLoader(retention) {
  return (dispatch) => {
    dispatch(resetLoader(CUSTOMER_SUBSCRIPTION_UPDATE));
    dispatch(resetLoader(RETENTION_DETAILS_FETCH));
    dispatch(resetLoader(RETENTION_OFFER_REDEEM));
    if (!getRetentionRedeemErrorSelector(retention)) {
      dispatch({
        type: RETENTION_REDEEM_RESET,
      });
    }
  };
}

export function resetError() {
  return (dispatch) => {
    dispatch({
      type: RETENTION_ERROR_RESET,
    });
  };
}

export function getRetentionRedeemHistory(retention) {
  return get(retention, 'redeemHistory[0]', []);
}

export function redeemRetentionOffer(osOfferId) {
  return (dispatch, getState) => {
    const state = getState();
    const { user, systemConfig } = state;

    const currentUserId = getUserId(user);

    const getFeatureFlags = getFlags({
      systemConfig,
    });
    const isSelfServeOffers = getFeatureFlags(`SELF_SERVE_OFFERS`);
    let apiName = 'fe-api-redeemRetentionOffer';
    let path = `/${currentUserId}/osOffers/${osOfferId}`;
    let body = undefined;
    if (isSelfServeOffers) {
      apiName = 'fe-api-makePurchaseOffer';
      path = `users/${currentUserId}/osOffers/${osOfferId}`;
      body = { platformType: 'web' };
    }

    dispatch(
      apiAction(RETENTION_OFFER_REDEEM, apiName, {
        method: `post`,
        path,
        ...(body && { body }),
        // isTest: true,
      }),
    );
  };
}

export function getCustomerEligibleRetentionOffer(retentionOffers) {
  return get(first(retentionOffers), 'osOffers.creditCard[0]', '') || {};
}

export function getCustomerEligibleRetentionOfferConfig(
  retentionOffers,
  getFeatureFlags,
) {
  const retentionOffersConfig =
    getFeatureFlags('RETENTION_OFFERS_CONFIG') || {};
  const offer =
    retentionOffersConfig[
      get(getCustomerEligibleRetentionOffer(retentionOffers), 'id', '')
    ] || {};
  return offer;
}

export function getRetentionRedeemErrorSelector(retention) {
  return (
    !isEmpty(retention.error) ||
    get(retention, 'subscription.status', '') === 'failed'
  );
}

export const triggerSubscriptionSegmentAnalytics = (
  eventName,
  action,
  redeemHistory,
) => {
  const { payload, apiActionContext } = action;
  const offerId =
    get(payload, 'data.item.offerId', '') ||
    get(apiActionContext, 'options.body.offerId', '');
  const status =
    get(payload, 'data.item.status', '') ||
    get(apiActionContext, 'options.body.status', '');
  const osOfferId = get(first(redeemHistory), 'osOfferId', '');
  const { code: errorCode = '', message: errorMessage = '' } = get(
    payload,
    'error.errors[0]',
    {},
  );
  const type = {
    cancelled: 'cancel',
    active: 'reactivate',
  }[status];
  let analyticObj = {
    eventName,
    id: offerId,
    name: offerId,
    ...(osOfferId && { osOfferId }),
    type,
    ...(errorCode && { errorCode }),
    ...(errorMessage && { errorMessage }),
  };
  action.asyncDispatch(
    analyticAction({
      ...analyticObj,
    }),
  );
};

export const triggerRetentionSegmentAnalytics = (
  eventName,
  action,
  additionalProps = {},
) => {
  const url = get(action, 'apiActionContext.url', '');
  const isApiV3 = isApiUrlV3(url);

  const { payload } = action;
  const subscription = get(
    payload,
    isApiV3 ? 'data.purchaseHistory' : 'data',
    [],
  );
  const { id: offerId = '', title: name = '' } = get(
    subscription,
    'offerSnapshot',
    {},
  );
  const osOfferId = get(subscription, 'osOfferId', '');
  const { code: errorCode = '', message: errorMessage = '' } = get(
    payload,
    isApiV3 ? 'error' : 'error.errors[0]',
    {},
  );
  let analyticObj = {
    eventName,
    ...(offerId && { id: offerId }),
    ...(name && { name }),
    ...(osOfferId && { osOfferId }),
    ...(errorCode && { errorCode }),
    ...(errorMessage && { errorMessage }),
    ...additionalProps,
  };
  action.asyncDispatch(
    analyticAction({
      ...analyticObj,
    }),
  );
};

export default function retentionReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case start(RETENTION_DETAILS_FETCH): {
      return {
        ...state,
        offers: null,
        error: null,
      };
    }
    case success(RETENTION_DETAILS_FETCH): {
      const items = get(payload, 'data', []);
      //triggerRetentionSegmentAnalytics('Get Retention Offers Successful', action);
      return {
        ...state,
        offers: items,
        error: null,
      };
    }
    case fail(RETENTION_DETAILS_FETCH): {
      const { offers } = state;
      //triggerRetentionSegmentAnalytics('Get Retention Offers Errored', action);
      return {
        ...state,
        offers: offers || [],
        error: payload,
      };
    }
    case RETENTION_REDEEM_RESET: {
      return {
        ...state,
        offers: null,
        subscription: null,
      };
    }
    case RETENTION_ERROR_RESET:
    case start(RETENTION_OFFER_REDEEM): {
      return {
        ...state,
        error: null,
      };
    }
    case success(RETENTION_OFFER_REDEEM): {
      action.asyncDispatch(getUserDetails({ forceRefreshCache: true }));
      action.asyncDispatch(
        getRecurringSubscriptionsAction(
          CUSTOMER_SUBSCRIPTION_GET_RECURRING,
          {},
        ),
      );
      const url = get(action, 'apiActionContext.url', '');
      const isApiV3 = isApiUrlV3(url);
      const subscription = get(
        payload,
        isApiV3 ? 'data.purchaseHistory' : 'data',
        [],
      );

      if (get(subscription, 'status', 'failed') === 'failed') {
        triggerRetentionSegmentAnalytics('Purchase Errored', action, {
          type: 'retention',
        });
      } else {
        triggerRetentionSegmentAnalytics('Purchase Successful', action, {
          type: 'retention',
        });
      }
      return {
        ...state,
        subscription,
        error: null,
      };
    }
    case fail(RETENTION_OFFER_REDEEM): {
      triggerRetentionSegmentAnalytics('Purchase Errored', action, {
        type: 'retention',
      });
      return {
        ...state,
        error: payload,
      };
    }
    case start(RETENTION_REDEEM_HISTORY_FETCH): {
      return {
        ...state,
        redeemHistory: null,
      };
    }
    case success(RETENTION_REDEEM_HISTORY_FETCH): {
      const redeemHistory = get(payload, 'data', []);
      return {
        ...state,
        redeemHistory,
      };
    }
    case fail(RETENTION_REDEEM_HISTORY_FETCH): {
      return {
        ...state,
        redeemHistory: null,
        error: payload,
      };
    }
    case success(CUSTOMER_SUBSCRIPTION_UPDATE): {
      const { redeemHistory = {} } = state;
      triggerSubscriptionSegmentAnalytics(
        'Subscription Update Successful',
        action,
        redeemHistory,
      );
      return state;
    }
    case fail(CUSTOMER_SUBSCRIPTION_UPDATE): {
      const { redeemHistory = {} } = state;
      triggerSubscriptionSegmentAnalytics(
        'Subscription Update Errored',
        action,
        redeemHistory,
      );
      return state;
    }
    default:
      return state;
  }
}
