import axios from 'axios';
import qs from 'qs';
import { API } from 'aws-amplify';
import { get, isEmpty } from 'lodash';
import defaultConfig from '../constants/defaultConfig';
import * as types from '../constants/actionTypes';
import { getAbsoluteStaticPrimaryPPUrl } from 'utils/path';

const loadSystemConfigs = () => {
  return function (dispatch) {
    dispatch({ type: types.GET_SYSTEM_CONFIG_INPROGRESS });

    return API.get(
      'fe-api-dev-metadataGetSystemConfig',
      `/systemConfigs/${defaultConfig.frontendConfigName}`,
      {},
    )
      .then((systemConfig) => {
        const query = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        });
        if (query?.allowPreview === 'true') {
          systemConfig.features.SELF_SERVE_OFFERS = true;
        }

        dispatch({ type: types.GET_SYSTEM_CONFIG_SUCCESS, systemConfig });
      })
      .catch((error) => {
        axios
          .get(getAbsoluteStaticPrimaryPPUrl(defaultConfig.backupFEConfigName))
          .then((data) => {
            const systemConfig = get(data, 'data', '');
            if (isEmpty(systemConfig)) {
              throw new Error();
            }
            dispatch({ type: types.GET_SYSTEM_CONFIG_SUCCESS, systemConfig });
          })
          .catch((error) => {
            dispatch({
              type: types.GET_SYSTEM_CONFIG_FAILURE,
              payload: {
                error: 'failed to get system config',
              },
            });
          });
      });
  };
};

export { loadSystemConfigs };
