import dataSource from './dataSource';
import { isMobile } from '../utils/userAgent';

const version = require('../../package.json').version;
const name = require('../../package.json').name;
const defaultPlatform = 'web';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

/* eslint-disable no-unused-vars */
const {
  REACT_APP_AWS_USER_AUTH_BASE_URL,
  REACT_APP_AWS_METADATA_BASE_URL,
  REACT_APP_AWS_TICKET_MANAGER_BASE_URL,
  REACT_APP_AWS_PLAYBACK_BASE_URL,
  REACT_APP_AWS_SPORTS_DATA_BASE_URL,
  REACT_APP_AWS_FRONTEND_BASE_URL,
  REACT_APP_AWS_CUSTOM_FRONTEND_BASE_URL,
  REACT_APP_AWS_SYSTEM_CONFIG_BASE_URL,
  //
  REACT_APP_YSP_SDK = true,
} = process.env;
/* eslint-enable no-unused-vars */

const defaultConfig = {
  yspSdk: REACT_APP_YSP_SDK,
  yspSdkLive: true,
  yspSdkV3: true,
  defaultApiTimeout: 15000,
  advertConsent: 0,
  playerName: 'BitmovinPlayer',
  defaultPlatform,
  platform: isMobile() ? 'mobileweb' : defaultPlatform,
  env: 'prod',
  name: name,
  version: version,
  supportDataSource: [dataSource.firestore, dataSource.cloudStorage],
  dataSource: dataSource.firestore,
  currentBaseUrl: 'https://sport.optus.com.au',
  staticBaseUrl: 'https://static.sport.optus.com.au',
  navigation: 'nav_v1',
  homepageVersion: 2,
  frontendConfigName: 'frontEndConfig',
  backupFEConfigName: '/frontEndConfig.json',

  image: {
    baseUrl: 'https://images.sport.optus.com.au/',
    sizes: {
      carouselImageSize: '_s_1920x1080_t_jpeg',
      panelCoverSize: '_s_768x432',
      panelCoverSizeLarge: '_s_300x450',
      siteSelectorSize: '_s_80x80',
      teamBackgroudSize: '_s_1920x1080_l_third-landscape_t_jpeg',
      teamBadgeSize: '_s_150x150',
      // Temp. sizes during cms transition
      carouselImageSizeMedium: '_s_1280x720_t_jpeg',
      carouselRailSize: '_s_320x180',
      default: '_s_1280x720',
    },
    sizesAspectRatio: {
      '1:1': { px: '_s_1000x1000', pct: '100%', widths: [991] },
      '9:16': { px: '_s_720x1280', pct: '177.777777%', widths: [1080] },
      '11:4': { px: '_s_1320x480', pct: '36.363636%', widths: [770, 1664] },
      '16:9': {
        px: '_s_1280x720',
        pct: '56.25%',
        widths: [320, 480, 640, 820, 1080, 1280, 1920, 2560],
      },
    },
    srcSetWidths: [320, 480, 640, 820, 1080],
  },

  vcmsBaseUrl: 'https://api-cdn.optussport.tv/api',

  VCMSConfig: {
    teamSummaryEndpoint: '/statsV2/soccer/teamsummary',
    fixturesEndpoint: '/statsV2/soccer/fixture',
    resultsEndpoint: '/statsV2/soccer/result',
    liveMatchesEndpoint: '/statsV2/soccer/livematches',
    resultsStandingsEndpoint: '/statsV2/soccer/resultsstandings',
    matchPreviewEnpoint: '/statsV2/soccer/prematch',
    compareTeamsEndpoint: '/statsV2/soccer/compareteams',
    lineupPreMatchEndpoint: '/statsV2/soccer/lineupprematch',
    matchHighlightsEndpoint: '/statsV2/soccer/matchhighlights',
    commentaryEndpoint: '/statsV2/soccer/commentary',
    teamStatsEndpoint: '/statsV2/soccer/seasonstatteam',
    teamSquadEndpoint: '/statsV2/soccer/teamsquad',
    matchLiveScoreEndpoint: '/statsV2/soccer/matchlivescore',
    allTeamsUrl: '/categories/all/teams',
    matchStatsEndpoint: '/statsV2/soccer/matchstat',
  },

  // DEV
  convivaKey: '4049c7d989b1188801fc97d406227690ab986dbd',
  muxCustomerKey: 'mgd0ee5iankkv3gidl14rm7c0',
  convivaGatewayHost: '',

  // PROD (Do not set this unless in production environment as it will affect current conviva metrics)
  //convivaKey: '4049c7d989b1188801fc97d406227690ab986dbd',

  awsRegion: 'ap-southeast-2',

  // DEV - direct url
  // awsUserAuthBaseUrl: 'https://web-dev.sport-ott.com/api',
  // awsMetadataBaseUrl: 'https://web-dev.sport-ott.com/api',
  // awsTicketManagerBaseUrl: 'https://web-dev.sport-ott.com/api',
  // awsPlaybackBaseUrl: 'https://web-dev.sport-ott.com/api',
  // awsSportsDataBaseUrl: 'https://web-dev.sport-ott.com/api',

  awsUserAuthBaseUrl: 'https://api-cdn.optussport.tv/api',
  awsMetadataBaseUrl: 'https://static.sport.optus.com.au/api',
  awsEPGMetadataBaseUrl: `https://epg.optusvideo.tv/api`,
  awsTicketManagerBaseUrl: 'https://api-cdn.optussport.tv/api',
  awsPlaybackBaseUrl: 'https://api-cdn.optussport.tv/api',
  awsSportsDataBaseUrl: 'https://api-cdn.optussport.tv/api',
  awsFrontEndBaseUrl: 'https://api-cdn.optussport.tv/api',
  awsSystemConfigBaseUrl: 'https://static.sport.optus.com.au/api',
  awsCustomFrontEndBaseUrl: 'https://api-cdn.optussport.tv',

  // DEV - MAP url to avoid
  // awsApiLambdaBaseUrl: '//web-dev.sport-ott.com/api',
  // awsMetadataBaseUrl: '//web-dev.sport-ott.com/api',

  // PP - direct url
  // awsUserAuthBaseUrl: 'https://n8m7atiqn9.execute-api.ap-southeast-2.amazonaws.com/pp',
  // awsMetadataBaseUrl: 'https://vdm3gezbbk.execute-api.ap-southeast-2.amazonaws.com/pp',
  // awsTicketManagerBaseUrl: 'https://l3lsuzdw25.execute-api.ap-southeast-2.amazonaws.com/pp',
  // awsPlaybackBaseUrl: 'https://ab15i4zcrc.execute-api.ap-southeast-2.amazonaws.com/pp',
  // awsSportsDataBaseUrl: 'https://ex69egqku7.execute-api.ap-southeast-2.amazonaws.com/pp',

  // Prd - direct url
  // awsUserAuthBaseUrl: 'https://8rbw1ccq43.execute-api.ap-southeast-2.amazonaws.com/prd',
  // awsMetadataBaseUrl: 'https://ojmqgxttxe.execute-api.ap-southeast-2.amazonaws.com/prd',
  // awsTicketManagerBaseUrl: 'https://x5ju8incd6.execute-api.ap-southeast-2.amazonaws.com/prd',
  // awsPlaybackBaseUrl: 'https://us500mwhjh.execute-api.ap-southeast-2.amazonaws.com/prd',
  // awsSportsDataBaseUrl: 'https://o4l9c6k21l.execute-api.ap-southeast-2.amazonaws.com/prd',

  // PP - MAP url to avoid
  // awsApiLambdaBaseUrl: '//sport-pp.sport.optus.com.au/api',
  // awsMetadataBaseUrl: '//sport-pp.sport.optus.com.au/api',

  // DEV - cognito Ids
  // awsUserPoolId: 'ap-southeast-2_fm9jqVdRg',
  // awsUserPoolWebClientId: '1r84fscar98l1d24q8s3bl21t5',

  // PP - cognito Ids
  awsUserPoolId: 'ap-southeast-2_uDrelnnhk',
  awsUserPoolWebClientId: '4f9j45k3024143l8hck1k1o2tp',

  // production - cognito Ids
  // awsUserPoolId: 'ap-southeast-2_uDrelnnhk',
  // awsUserPoolWebClientId: '4f9j45k3024143l8hck1k1o2tp',

  awsAuthenticationFlowType: 'USER_PASSWORD_AUTH',

  saagEnabled: !isLocalhost && !window.isPrerenderRequest,
  saagCompetitions: [
    'EPL',
    'WSL',
    'JPJL',
    'UNL',
    // 'WQE',
    'DFB',
    // 'ECQ2020',
    // 'EUC',
    'NWSL',
    // 'COA',
    'FAC',
  ],
  saagTotalCount: 15,
  saagPostMatchesDuration: 3,
  saagDisabledAssetTypes: ['news', 'fitness'],

  // dataDog - Local - Avoid tracking from localhost
  dataDogRumAppId: 'b1027393-cac7-4e8d-8e21-191623b212a9',
  dataDogRumClientToken: 'pubf58ca088cbe90e01380512cb385fdedf',

  // dataDog - PP
  // dataDogRumAppId: '95a6fe8b-9835-4804-8572-929a45988847',
  // dataDogRumClientToken: 'puba09329b770b64c705dc96fa671a45917',

  getCompetitionTitle: (compId) => {
    const compIdStr = compId && compId.toString();
    switch (compIdStr) {
      case '8':
      case 'epl':
        return 'Premier League';
      case '5':
      case 'ucl':
      case '232':
      case 'uclq':
        return 'UEFA Champions League';
      case '6':
      case 'uel':
        return 'UEFA Europa League';
      case '941':
      case 'unl':
        return 'UEFA Nations League';
      case '88':
      case 'int':
        return 'International Friendlies';
      case '235':
      case 'ecq2020':
        return 'EURO 2024 Qualifiers';
      case '408':
      case 'fwwc':
      case 'wwc':
        return "FIFA Women's World Cup 2023™";
      case '334':
        return 'Premier League Asia Trophy';
      case '174':
      case 'usc':
        return 'UEFA Super Cup';
      case '550':
      case 'wsl':
        return "Women's Super League";
      case '669':
      case 'fcw':
        return "Women's FA Cup";
      case '335':
      case 'fcwc':
        return 'FIFA Club World Cup™';
      case '20':
      case 'j-league':
      case 'jpjl':
        return 'J.League';
      case '3':
      case 'euc':
        return 'UEFA EURO 2024';
      case '832':
      case 'nws':
      case 'nwsl':
        return "National Women's Soccer League";
      case 'ec2020':
        return 'UEFA EURO 2020™';
      case '205':
      case 'k-league':
      case 'kl':
      case 'kl1':
        return 'K League';
      case '336':
      case 'wqe':
        return 'FIFA World Cup European Qualifiers';
      case '128':
      case 'coa':
        return 'Copa America USA 2024™';
      case '642':
      case 'wwq':
        return "Women's World Cup Qualifiers";
      case '1166':
      case 'cuf':
        return 'CONMEBOL/UEFA Finalissima';
      case '231':
      case 'dfb':
        return 'DFB Pokal';
      case '1211':
      case 'pls':
        return 'Premier League Summer Series';
      case '38':
      case 'cos':
        return 'Community Shield';
      case '1':
      case 'fac':
        return 'Emirates FA Cup';
      default:
        return 'Premier League';
    }
  },

  getCategoryTitle: (title) => {
    const titleLowerCase = title && title.toLowerCase();
    switch (titleLowerCase) {
      case 'linear channels':
        return 'Live Channels';
      default:
        return title;
    }
  },

  networkDiagnosticsUrl: 'https://eplnetinfo-https.optusnet.com.au/netinfo',

  // recaptcha apiKey
  recaptchaApiKey: '6LcPsjQUAAAAACg9eEzD5vRy9Dzt9B_MGXtsSM6S',

  //campaign redirect URL(voucher)
  campaignSuccessRedirectURL: '/',

  // Medallia embed script url
  medalliaEmbedJsUrl: 'https://nebula-cdn.kampyle.com/wau/60853/onsite/embed.js',

  // DEV - loggly Customer Token and subDomain
  logglyCustomerToken: '2fbc9545-0a4a-48f6-9bf6-f8d1cfcbc5e6',
  logglySubdomain: 'https://optussport.loggly.com',
  logglyEnv: 'Prod',

  // PP - loggly Customer Token and subDomain
  // logglyCustomerToken: '',
  // logglySubdomain: 'https://optussport.loggly.com',
  // logglyEnv: 'Pre Prod',

  // PROD - loggly Customer Token and subDomain
  // logglyCustomerToken: '',
  // logglySubdomain: 'https://optussport.loggly.com',
  // logglyEnv: 'Prod'

  //Google Analytics Tracking Id
  gaTrackingId: 'G-XCY749FCE5',

  //Facebook SDK
  fbAppId: '1581556128573768',
  fbClientToken: 'c412adc0d14322981dc9a3c04c0a53b8',
  fbApiVersion: 'v8.0',

  //Segment SDK
  segmentDomain: 'https://evs.seg-cdn.optussport.tv',
  segmentId: 'kdGy3ijOG0CIdlQnzdbW5dXxIT0xh1Vd',
  segmentAnalyticsAlias: 'tHftFRpkqmgH2z4rANqfr',

  //Bitmovin Player
  bitmovinPlayerKey: '091F8084-B544-4DAB-B090-94ECF8506D12',

  // Braze SDK
  brazeApiKey: 'cd0aa21c-5ae9-4398-92ee-aa755b092c16',
  brazeBrowserApiKey: 'deed0472-2819-4a3b-abcc-0f0a19daf1b7',
  brazeMobileApiKey: '9332efd8-9539-48a7-a84b-1660818836f1',
  brazeSdkEndpoint: 'sdk.iad-07.braze.com',

  // Offers Bucket Suffix Key
  offerBucketSuffixKey: '',

  //Flags for calling the API's v1 or v2
  // false => call the api v1
  // true => call the api v2
  apiFlags: {
    assets: true,
    editorials: true,
    search: true,
  },

  metaDescription: 'Watch on Optus Sport',

  baseImgResizerUrl: 'https://sport.optus.com.au/cms/resources/images',
  routeWithoutReactHelmet: ['videos', 'play', 'articles'],
  optusCustomerSignupRedirectUrl:
    'https://www.optus.com.au/entertainment/sport/os-fitness#already-with-optus',
  optusCustomerRegisterRedirectUrl:
    'https://subhub.optus.com.au/streaming-services/optussport',

  // Feature toggles
  features: {
    PLAYER_CONFIG: {
      enableSaveUserSettings: false,
      yspPlaybackPolicy: {
        enabled: true,
        allowSeekPastAds: true,
      },
      adHoliday: {
        enabled: false,
        duration: 180,
        allowSkipButton: false,
        useAdImmunity: false,
      },
      keyboardControl: {
        enabled: false,
      },
    },
    FEATURE_BITMOVIN_NATIVE_YOSPACE: true,
    FEATURE_JUMBOTRON_DISABLED: ['home', 'news'],
    FEATURE_EXPLORE_RAIL: true,
    FEATURE_PHONE_CODE_VERIFICATION: true,
    // FEATURE_PHONE_CODE_VERIFICATION_DUMMY_NUMBERS: true,
    // If the SMS functionality has been used more than 5 times in the last 5 minutes, then it will be on cooldown for 5 minutes to avoid spam. The 'Resend code' link deactivates and there is a timer that counts down each second from 5:00. When it reaches 0, the link activates again. These parameters need to be configurable.
    // src: https://optussport.atlassian.net/browse/WB-959
    FEATURE_PHONE_CODE_VERIFICATION_USAGE_LIMIT_ATTEMPTS: 5,
    FEATURE_PHONE_CODE_VERIFICATION_USAGE_LIMIT_DURATION: 300000, // 5 minutes
    FEATURE_PHONE_CODE_VERIFICATION_USAGE_LIMIT_COOLDOWN: 300000, // 5 minutes

    FEATURE_ZOMBIE_KICK: true,
    FEATURE_ZOMBIE_KICK_TIMEOUT_HOURS: 6,

    FEATURE_PAYMENT_PAGE: false,
    FEATURE_PAYMENT_COUPON: true,
    FEATURE_PAYMENT_WORKAROUND_SUBSCRIPTION_STATUS_DELAY: 1500,
    FEATURE_CREDIT_CARD_INTEGRATION_DISPLAY_MODE: `custom`, // displayMode: PropTypes.oneOf([`inline`, `modal`, `redirect`, `custom`])
    FEATURE_PAYMENT_MERCHANT_ID: `97ba3cf6-92a2-4f3b-b7f8-c3bfc90ee07b`,
    // FEATURE_PAYMENT_MERCHANT_ID: `0f473b37-232f-4bf6-8894-3c08824cc02a`,
    FEATURE_PAYMENT_CUSTOM_CHECKOUT_URL: `https://customcheckout-uat.bambora.net.au/2.0.0/customcheckout.js`,
    FEATURE_CONVIVA: true,
    FEATURE_DATADOG_RUM: false,
    FEATURE_DATADOG_RUM_SESSION_SAMPLE_RATE: 5,
    FEATURE_INTERNATIONAL_PHONE_ALLOWED: true,
    CONNECT_CONFIG: {
      CANCEL_INTERVAL: 300000,
      INTERVAL: 30000,
    },
    DEFAULT_REGISTER_CONFIG: {
      messages: {},
      images: {
        logo: '',
        banner: '',
      },
    },
    CTV_CONFIG: {
      lg: {
        offers: {},
        tags: ['ctv_lg'],
        messages: {},
        images: {},
        platformTypeId: 'lgtv',
      },
      samsung: {
        offers: {},
        tags: ['ctv_samsung'],
        messages: {
          title: 'Sign up to watch Optus Sport on Samsung TV',
          info: 'The Terrace',
          markup: [
            {
              name: 'terms',
              value: `<p>Optus Sport free subscription for 2 months if you sign up with a credit card and use coupon code until 21st December. Subscription will be charged at $24.99 per month after two months unless cancelled earlier. New customers only. <a target="_blank" href="/samsung/terms">Click here</a> for full T&Cs</p>`,
            },
          ],
        },
        images: {
          logo: '/images/vendor/samsung/logo.svg',
          banner: '/images/vendor/samsung/banner.png',
        },
        platformTypeId: 'samsungtv',
      },
      playstation: {
        offers: {},
        tags: ['ctv_playstation'],
        messages: {},
        images: {},
        platformTypeId: 'playstation',
      },
      androidtv: {
        offers: {},
        tags: ['ctv_androidtv'],
        messages: {},
        images: {},
        platformTypeId: 'androidtv',
      },
      fetch: {
        offers: {},
        tags: ['ctv_fetch'],
        messages: {},
        images: {},
        platformTypeId: 'fetch',
      },
      appletv: {
        offers: {},
        tags: [],
        messages: {},
        images: {},
        platformTypeId: '',
      },
      foxtel: {
        offers: {},
        tags: ['ctv_foxtel'],
        messages: {},
        images: {},
        platformTypeId: 'foxtel',
      },
      hisense: {
        offers: {},
        tags: ['ctv_hisense'],
        messages: {},
        images: {},
        platformTypeId: 'hisensetv',
      },
      vidaa: {
        offers: {},
        tags: ['ctv_hisense'],
        messages: {},
        images: {},
        platformTypeId: 'hisensetv',
      },
      hubbl: {
        offers: {},
        tags: ['ctv_hubbl'],
        messages: {},
        images: {},
        platformTypeId: 'hubbl',
      },
    },
    FEATURE_RELATED_ASSET: {
      enabled: true,
      nextAssetAutoPlayCountdown: 6000, //milliseconds
      cardSlideAnimationTimer: 500, //milliseconds
    },
    SUBSCRIPTION_UPDATE_NOTIFICATION_ENABLED: false,
    SUBSCRIPTION_UPDATE_NOTIFICATION_CONFIG: {
      paymentSource_postpaid: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing to <b>$6.99 per month</b>. The changes will take effect from your first subscription renewal <b>after 31 July 2022</b>. <br /><p class='pt-8'>The Cancel Subscription button below will take you to Optus SubHub, our subscription management tool, where you can unsubscribe using your Optus My Account login. Please note, you must be the Optus account holder to make changes to your subscription.</p>`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
      },
      paymentSource_prepaid: {
        // notificationTitle: 'Changes to your subscription',
        // notificationMsg: `From 1 August 2022, Optus Sport Prepaid subscription prices are changing to <b>$11.99</b> for <b>28 days</b>. The changes will take effect from your first subscription renewal <b>after 31 July 2022</b>.`,
        // actionLinkText: '',
        // actionUrl: '',
      },
      paymentSource_cc: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing to <b>$24.99 per month or $199.00 per year</b> for our standard subscriptions. If you are currently subscribed to a special offer, please refer to the email we have sent you about the changes to your offer. All price changes will take effect from your next subscription renewal <b>after 31 July 2022</b>.`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
        priceChangeDate: '01-Aug-2022',
        newPrice: {
          '1m': 24.99,
          '3months': 49.99,
          '12m': 199,
        },
        newPriceOffer: {
          S631361160_AU: 159,
          S344110223_AU: 159,
          S923762200_AU: 159,
          S666769808_AU: 159,
          S360763790_AU: 159,
        },
      },
      paymentSource_playstore: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing.`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
      },
      paymentSource_appstore: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing.`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
      },
      paymentSource_hyperion: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing to <b>$6.99 per month</b>. The changes will take effect from your first subscription renewal <b>after 31 July 2022</b>. <br /><p class='pt-8'>The Cancel Subscription button below will take you to Optus SubHub, our subscription management tool, where you can unsubscribe using your Optus My Account login. Please note, you must be the Optus account holder to make changes to your subscription.</p>`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
      },
      paymentSource_optus_subhub: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing to <b>$6.99 per month</b>. The changes will take effect from your first subscription renewal <b>after 31 July 2022</b>. <br /><p class='pt-8'>The Cancel Subscription button below will take you to Optus SubHub, our subscription management tool, where you can unsubscribe using your Optus My Account login. Please note, you must be the Optus account holder to make changes to your subscription.</p>`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
      },
      paymentSource_saturn_migration_pass: {
        notificationTitle: 'Notice',
        notificationMsg: `You currently have access to Optus Sport subscription content until 31 May 2023. We will advise you of further changes to your Optus Sport account and subscription closer to this date. Please contact your Optus account holder to make any changes to your account.`,
        actionLinkText: '',
        actionUrl: '',
      },
      checkout_page: {
        notificationTitle: 'Prices are changing',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing to <b>$24.99 per month or $199.00 per year</b> for our standard subscriptions. If you are currently subscribed to a special offer, please refer to the email we have sent you about the changes to your offer. All price changes will take effect from your next subscription renewal <b>after 31 July 2022</b>.`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
        priceChangeDate: '01-Aug-2022',
        newPrice: {
          '1m': 24.99,
          '3months': 49.99,
          '12m': 199,
        },
        newPriceOffer: {
          S631361160_AU: 159,
          S344110223_AU: 159,
          S923762200_AU: 159,
          S666769808_AU: 159,
          S360763790_AU: 159,
        },
      },
    },
    FEATURE_SUBSCRIPTION_ACTIONS_CONFIG: {
      paymentSource_postpaid: {
        active: {
          updateActionText: 'Manage subscription',
          updateActionUrl: `https://subhub.optus.com.au/sso-redirect?target=/service-details/optussport`,
          updateActionUrlTarget: '_self',
        },
      },
      paymentSource_hyperion: {
        active: {
          updateActionText: 'Cancel subscription',
          // updateActionUrl: 'https://subhub.optus.com.au/unsubscribe/optussport',
          updateActionUrl: '',
          updateActionUrlTarget: '_self',
        },
      },
      paymentSource_optus_subhub: {
        active: {
          updateActionText: 'Manage subscription',
          updateActionUrl: `https://subhub.optus.com.au/sso-redirect?target=/service-details/optussport`,
          // updateActionUrl: '',
          updateActionUrlTarget: '_self',
        },
      },
    },
    FEATURE_RETENTION_OFFER: true,
    RETENTION_OFFERS_CONFIG: {
      'web-creditcard-one-month-free-2021-07': {
        heroImageMobile: `/images/amazon-fire-tv-rotator-euro-1920-x-720-mobile.png`,
        heroImageDesktop: `/images/amazon-fire-tv-rotator-euro-1920-x-720-desktop.png`,
        heroImageAltText: `Football players`,
        headLineText: `Before you go we have a special offer just for you`,
        headingText: `Have a month subscription on us - <span class='text-green'>Save $24.99</span>`,
        yesBtnText: `Yes please!`,
        noBtnText: `No I want to cancel`,
        offerTnCText: `Redeemable only on Web, <a href='https://sport.optus.com.au/terms' target='_blank' class='text-teal os-transition-btn hover:text-teal-dark active:text-teal-light'>Terms & Conditions</a> apply`,
        successHeadingText: `A free month has been added to your subscription`,
        successContentText: `On {{next_billing_date}}, you will be charged {{subscription_discounted_price}}. After that date, you will be billed {{subscription_period}} for {{subscription_price}} until you cancel or change your plan.`,
      },
      'web-creditcard-one-day-free-2021-07': {
        heroImageMobile: `/images/amazon-fire-tv-rotator-euro-1920-x-720-mobile.png`,
        heroImageDesktop: `/images/amazon-fire-tv-rotator-euro-1920-x-720-desktop.png`,
        heroImageAltText: `Football players`,
        headLineText: `Before you go we have a special offer just for you`,
        headingText: `Have a day subscription on us - <span class='text-green'>Save $2.99</span>`,
        yesBtnText: `Yes please!`,
        noBtnText: `No I want to cancel`,
        offerTnCText: `Redeemable only on Web, <a href='https://sport.optus.com.au/terms' target='_blank' class='text-teal os-transition-btn hover:text-teal-dark active:text-teal-light'>Terms & Conditions</a> apply`,
        successHeadingText: `A free day has been added to your subscription`,
        successContentText: `On {{next_billing_date}}, you will be charged {{subscription_discounted_price}}. After that date, you will be billed {{subscription_period}} for {{subscription_price}} until you cancel or change your plan.`,
      },
      'web-creditcard-one-month-free-2025-02': {
        heroImageDesktop: '/images/retention-desktop-07-2024.png',
        successHeadingText: 'A free month has been added to your subscription',
        yesBtnText: 'Yes please!',
        headingText:
          "\u003Cspan class='text-2xl'\u003EStay a little longer? Enjoy a free month on us - \u003Cspan class='text-green'\u003ESave $24.99\u003C/span\u003E\u003C/span\u003E",
        noBtnText: 'No I want to cancel',
        heroImageMobile: '/images/retention-mobile-07-2024.png',
        heroImageAltText: 'Football players',
        successContentText:
          'On {{next_billing_date}}, you will be charged {{subscription_discounted_price}}. After that date, your subscription will renew for {{subscription_price}} until you cancel or change your plan.',
        offerTnCText:
          "\u003Cp\u003E\u003Cb\u003E$0 for 1 Month:\u003C/b\u003E available until withdrawn. Eligible customers & channels (excl. SubHub). Subscription automatically renews at $24.99/mth after $0 for 1 month period. AU personal viewing only. Content may change. Click for full \u003Ca href='/0-first-month-terms-jul-2024.pdf' target='_blank' class='text-teal os-transition-btn hover:text-teal-dark active:text-teal-light'\u003ET&Cs\u003C/a\u003E. [copy and t&cs to be updated]\u003C/p\u003E",
      },
    },
    // Chartbeat
    CHARTBEAT_CONFIG: {
      enabled: false,
      uid: 67182,
      routesAllowed: ['/news/:category/:id/:slug'], // will be checked by useRouteMatch
    },
    PROMOTIONAL_PLACEMENTS_CONFIG: {
      enabled: false,
      source: 'cms',
      braze: {
        enabled: false,
        disableLoggedOutUsers: {
          always: false,
          whenUsingIncognito: true,
        },
        // filterBrowser: [], // If it's falsy, defaultConfig.inAppBrowserKeys is the default value
        filterMobile: true,
        disableContentCards: true,
        contentCardLocationKey: 'web_location_id',
        contentCardAssetKey: 'asset_id',
        analytics: {
          logContentCardImpressions: false,
          logContentCardClick: false,
        },
      },
      contentCard: {
        actions: {
          // [asset]
          asset: ['/videos/', '/news/'],
          // [internal] - key: cms string, value: web route path
          internal: [
            { '/home': '/' },
            { '/epg': '/category/home_linear_channels' },
            { '/browse': '/category/home_linear_channels' },
            { '/settings': '/usersettings' },
            { '/register': '/register' },
            { '/signup': '/signup' },
            { '/login': '/login' },
            { '/checkout': '/checkout' },
            // Competition paths
            { '/competition/epl': '/epl' },
            { '/competition/womens': '/womens' },
            { '/competition/laliga': '/laliga' },
            { '/competition/j-league': '/j-league' },
            { '/competition/k-league': '/k-league' },
            { '/competition/internationals': '/internationals' },
            { '/competition/fitness': '/fitness' },
            { '/competition/womens-world-cup-2023': '/womens-world-cup-2023' },
            { '/competition/dfb-pokal': '/dfb-pokal' },
            { '/competition/nwsl': '/nwsl' },
            { '/competition/uefa-euro-2024': '/uefa-euro-2024' },
            { '/competition/copa-america-2024': '/copa-america-2024' },
          ],
          // [internal-dynamic] - key: cms string pattern, regExp: to parse key, path: to generate inner route path
          'internal-dynamic': {
            '/user-competitions/{id}': {
              regExp: '^/user-competitions/([^/]+)$',
              path: ['/competitions', '(1)'],
            },
          },
          // [component] - key string of component,
          component: ['popupImageOverlay'],
        },
      },
    },
    // This will be overridden by fe-config > systemConfigs; these are dummy data for testing
    OFFERS_CONFIG: {
      S792796051_AU: {
        promoText: 'Billed annually',
        beforePrice: '$299.98',
        badgeText: 'Save over 33%*',
        promoDescription: `*Annual Pass offer available until 27, September 2020. Subscription will renew at $139.`,
      },
      S794694526_AU: {
        promoText: 'Get it while its hot.^',
      },
      P625421570_AU: {
        promoText: '1 July 2023 - 22 August 2023',
      },
      'web-creditcard-six-month-intro-2023-11': {
        badgeText: 'Save over 33%',
        beforePrice: 24.99,
        promoText:
          'per mth, for first 6 mths, $24.99/mth thereafter. Billed monthly, cancel anytime.',
        promoDescription:
          'Mid Season Sale: New and returning Optus Sport customers only via sport.optus.com.au/signup between 20/11/2023 and 10/12/2023. Access Optus Sport for $16.50/mth for 6 months. Debit or credit card only. Price reverts to $24.99/mth on 7th month. Cancel anytime. AU personal viewing only. Content may change. Fair Go Policy applies. <a href="/optus-sport-midseason-sale-terms_27nov2023.pdf" target="_blank" class="os-transition-btn font-MarkProBold text-teal hover:text-teal-dark active:text-teal-light no-underline" rel="noopener noreferrer">Full T&Cs</a>.',
        title: 'Mid Season Sale',
        offers: [
          { id: 'S377576321_AU', isBefore: true },
          { id: 'S738447349_AU', isBefore: true },
          { id: 'S303857758_AU', isBefore: true },
        ],
        successMessage:
          'You have been billed for $16.50 for your first month. You will continue to be billed this amount for your first 6 months with us, after which the amount will revert back to our standard monthly plan price.',
      },
      'web-creditcard-two-day-intro-2023-11': {
        badgeText: 'Save over 33%',
        beforePrice: 15.99,
        promoText: '34% off for first 2 days, then normal price afterwards.',
        title: 'Daily 2 Pass',
        offers: [{ id: 'S794694526_AU', isBefore: true }],
      },
      'web-creditcard-six-day-intro-2023-11': {
        badgeText: 'Save over 33%',
        beforePrice: 15.99,
        promoText: '34% off for first 6 days, then normal price afterwards.',
        title: 'Daily 6 Pass',
        offers: [{ id: 'S794694526_AU', isBefore: true }],
      },
    },
    FEATURE_PAYMENT_DEFAULT_ERROR: `There was a problem processing your credit card; please double check your payment information and try again.`,
    FEATURE_ALLOWED_CARD_TYPES: ['mastercard', 'visa', 'amex'],
    FEATURE_CONNECT_PAGE: true,
    FEATURE_EXTERNAL_SIGNUP_PAGE: true,
    SUBHUB_PROMO_ACTIVE: false,
    FEATURE_DEVICE_MANAGEMENT: false,
    SIGNUP_PROMO_CONFIG: {
      headerText: `Football and fitness all on Optus Sport`,
      primaryText: `Sign up to a free Optus Sport account and get access to football highlights, Fitness content and more. <br />Subscribe for full access to Premier League, Women's Super League, LALIGA, J.League, and more.`,
      secondaryText: ``,
    },
    SIGNUP_SUCCESS_CONFIG: {
      SUBSCRIBE_LATER_SECTION: {
        headingText: 'Not ready to subscribe to Optus Sport just yet?',
        headingTextPaymentFlagOff:
          'Not ready to subscribe to Optus Sport just yet.?',
        subText:
          'Check out all the great free content we have to offer including Match Highlights and Optus Sport Fitness',
        subTextPaymentFlagOff:
          'Check out all the great free content we have to offer including Match Highlights and Optus Sport Fitness.',
      },
      FOOTER_DISCLAIMER_SECTION: {
        disclaimerText: `
          <p class='font-MarkPro text-light-indicator-grey w-full text-center text-xs leading-16px'>*New and existing Optus Consumer customers with an eligible Optus service, who receive an Optus bill and are the account holder of that service&nbsp;<a href='https://www.optus.com.au/about/legal/standard-forms-agreement/personal/television' target='_blank' class='os-transition-btn font-MarkProBold text-teal hover:text-teal-dark active:text-teal-light no-underline' rel='noopener noreferrer'>Learn more</a><br/>SubHub monthly savings are based on RRP of $24.99, Annual Plan savings are based on 12 Monthly Plan payments.<br/>Personal viewing in AU. Content may change.&nbsp;<a href='https://sport.optus.com.au/terms' target='_blank' class='os-transition-btn font-MarkProBold text-teal hover:text-teal-dark active:text-teal-light no-underline' rel='noopener noreferrer'>Optus Sport T&Cs</a> apply.&nbsp;<a href='https://www.optus.com.au/content/dam/optus/appendix/Appendix%20S/AppS.doc' target='_blank' class='os-transition-btn font-MarkProBold text-teal hover:text-teal-dark active:text-teal-light no-underline' rel='noopener noreferrer'>Fair Go Policy</a> applies</p>
        `,
      },
      ACCOUNT_CREATED: {
        FOR_INTERNATIONAL_USER: {
          title: `Account Created`,
          content: `<div class='text-center m-auto max-w-500'>
                      <p>Welcome {firstName}, Your Optus Sport Account email is <b>{email}</b></p><br />
                      <p>As you have linked an international mobile number to your Optus Sport Account, you are only eligible for the Tournament Pass plan.
                        All Optus Sport Accounts with international mobile numbers will automatically be closed on 23 August 2023.
                      </p>
                    </div>`,
          primaryButton: {
            text: '',
            link: '',
          },
          secondaryButton: {
            text: '',
            link: '',
          },
          includedLink: {},
        },
        FROM_PREMIUM_ASSET: {
          title: `Account Created`,
          content: `<div class='text-center m-auto max-w-500'><p>Welcome {firstName}, Your Optus Sport Account email is <b>{email}</b></p><br />
                  <p>Subscribe now in order to start watching <b>{assetTitle}</b>.</p></div>`,
          primaryButton: {
            text: 'Subscribe',
            link: '/checkout',
          },
          secondaryButton: {
            text: 'Not now',
            link: '/',
          },
          includedLink: {
            text: `What's included in a subscription?`,
            link: '/signup',
          },
        },
        FROM_FREE_ASSET: {
          title: `Account Created`,
          content: `<div class='text-center m-auto max-w-500'><p>Welcome {firstName}, Your Optus Sport Account email is <b>{email}</b></p><br />
                  <p>You can now enjoy Optus Sport <span class='text-green'>free content</span>, including hundreds of free Fitness videos, football news, articles and selected highlights.</p></div>`,
          primaryButton: {
            text: 'Watch now',
            link: '{assetLink}',
          },
          secondaryButton: {
            text: 'Subscribe',
            link: '/checkout',
          },
          includedLink: {
            text: `What's included in a subscription?`,
            link: '/signup',
          },
        },
        FROM_OTHER_SIGNUP_LINK: {
          title: `Account Created`,
          content: `<div class='text-center m-auto max-w-500'><p>Welcome {firstName}, Your Optus Sport Account email is <b>{email}</b></p><br />
                  <p>Now add a paid subscription to your account to access all of Optus Sport.</p></div>`,
          primaryButton: {
            text: 'Explore Optus Sport',
            link: '/',
          },
          secondaryButton: {
            text: 'Subscribe',
            link: '/checkout',
          },
          includedLink: {
            text: `What's included in a subscription?`,
            link: '/signup',
          },
        },
        PREPAID_BOLT_ON_ACCOUNT: {
          title: 'Your account is ready to go',
          content: 'You can not access all Optus Sport subscription content.',
          primaryButton: {
            text: 'Browse Optus Sport',
            link: '/',
          },
          secondaryButton: {
            text: 'Create your support ticket',
            link: '/help',
          },
        },
      },
    },
    FEATURE_EDIT_PROFILE: true,
    PROFILE_PAGE_CONFIG: {
      personalisedAd: {
        enabled: true,
        label: 'Personalised ads',
        subText: `Turning this off will limit our ability to deliver relevant ads to you but will not reduce the number of ads you receive`,
      },
      offersAndPromotions: {
        enabled: true,
        label: 'Offers and Promotions',
        subText: `See personalised offers from Optus Sport or Optus. If turned off, generic offers may still be visible.`,
      },
      resetIdentifier: {
        enabled: true,
        label: 'Reset identifier',
        subText: 'This will reset the identifier used for personalised ads',
      },
    },
    CHECKOUT_PAGE_CONFIG: {
      enableSubscribeViaSubhub: true,
      optusSubHubCard: {
        offerCode: 'Credit Card Monthly',
        promoText: 'Billed monthly, cancel anytime',
        price: 6.99,
        title: `Optus&nbsp<p class='text-cyan-medium-dark'>Sub</p><p class='text-cyan'>Hub</p>`,
        planType: 'Billed monthly, cancel anytime',
        isOptusCustomer: true,
        beforePrice: '$24.99',
        badgeText: 'Save over 70%',
        subscribeViaSubhubLink: `https://subhub.optus.com.au/streaming-services/optussport`,
      },
      subscribeViaOptusSection: {
        showSection: true,
        headingText: `Activate your subscription in Optus <span class='text-cyan-medium-dark'>Sub</span><span class='text-cyan'>Hub</span>`,
        imageUrl: '/images/signup/setup-subhub-mobile.png',
        subscriptionSteps: [
          {
            imageUrl: `/images/signup/subscribe-via-subhub-login.png`,
            title: 'Log in to Optus SubHub',
            subText: 'Use your Optus My Account details.*',
          },
          {
            imageUrl: `/images/signup/subscribe-via-subhub-add-subscription.png`,
            title: 'Add your subscription',
            subText: 'Select Optus Sport and proceed to set up.',
          },
          {
            imageUrl: '/images/signup/subscribe-via-subhub-link-acc.png',
            title: 'Link your account',
            subText: 'Add your Optus Sport account details.',
          },
        ],
        disclaimerText: `
          <p class='font-MarkPro text-alt-grey w-full text-left sm:text-center text-xs leading-16px sm:text-sm sm:leading-20px'>
            * This is what you may use to log in into the Optus App or website.
            <br/>
            Note: Optus SubHub is not available for Optus Pre-Paid or Business Customers.&nbsp<a
              href='https://www.optus.com.au/about/legal/standard-forms-agreement/personal/television'
              target='_blank'
              class='os-transition-btn font-MarkProBold text-cyan-blue hover:text-cyan-blue-lighter active:text-cyan-blue-light no-underline'
              rel='noopener noreferrer'
            >Terms & Conditions</a>
          </p>`,
        subscribeViaSubhubFormData: {
          postEnabled: true,
          postUrl: `https://subhub-pp.subhub.optus.com.au/api/session/sso-redirect`,
          appBaseUrl: 'https://pp-mock.subhub.tv',
          target:
            '/streaming-services/optussport?eventPageId=pl-2023&utm_source=os-web&utm_medium=referral&utm_campaign=checkout',
          continueToSubHubLink: `https://subhub-pp.subhub.optus.com.au/sso-redirect?target=%2Fstreaming-services%2Foptussport%3FeventPageId%3Dpl-2023%26utm_source%3Dos-web%26utm_medium%3Dreferral%26utm_campaign%3Dcheckout`,
        },
      },
      skipSubscriptionLink: {
        title: `Skip subscription`,
        subText: 'Check out all the great free content we have to offer',
      },
      checkoutInitiatedFrom: {
        USER_COMPETITION: {
          successBtnText: 'Back to competition',
        },
      },
    },
    SIGNUP_PAGE_CONFIG: {
      availableSignupThemes: [
        'default',
        'laliga',
        'womens-world-cup-2023',
        'pl',
        'wsl',
      ],
      heroSection: {
        default: {
          showSection: true,
          showButton: false,
          headingText: `Every Match. Live on Optus Sport.`,
          subText: `Subscribe to access every match of the Premier League and Women's Super League live and on demand.`,
          bottomText: ``,
          className: 'signup-hero-section-default',
          mobileImageUrl: `/images/signup/signup-hero-mid-season-sale-mobile.jpg`,
          tabletImageUrl: `/images/signup/signup-hero-mid-season-sale-tablet.jpg`,
          desktopImageUrl: `/images/signup/signup-hero-mid-season-sale-desktop.jpg`,
        },
        laliga: {
          showSection: true,
          showButton: false,
          headingText: 'Every Match. Live on Optus Sport.',
          subText:
            "Subscribe to access every LALIGA match live and on demand. Plus Premier League and more of the World's Best Football.",
          className: 'signup-hero-section-laliga',
          mobileImageUrl: `/images/signup/signup-hero-laliga-mobile-0823.png`,
          tabletImageUrl: `/images/signup/signup-hero-laliga-tablet-0823.png`,
          desktopImageUrl: `/images/signup/signup-hero-laliga-desktop-0823.png`,
        },
        pl: {
          showSection: true,
          showButton: false,
          headingText: 'Every Match. Live on Optus Sport.',
          subText:
            'Subscribe to access every match of the Premier League live and on demand.',
          className: 'signup-hero-section-epl',
          mobileImageUrl: `/images/signup/signup-hero-pl-mobile.png`,
          tabletImageUrl: `/images/signup/signup-hero-pl-tablet.png`,
          desktopImageUrl: `/images/signup/signup-hero-pl-desktop.png`,
        },
        'womens-world-cup-2023': {
          showSection: true,
          showButton: false,
          headingText: "FIFA Women's World Cup 2023™",
          subText:
            'Live and on-demand coverage of all 64 games, exclusively on Optus Sport',
          className: 'signup-hero-section-wwc',
          mobileImageUrl: `/images/signup/signup-hero-wwc-23-mobile-2.png`,
          tabletImageUrl: `/images/signup/signup-hero-wwc-23-tablet-2.png`,
          desktopImageUrl: `/images/signup/signup-hero-wwc-23-desktop-2.png`,
        },
        wsl: {
          showSection: true,
          showButton: false,
          headingText: 'Every Match. Live on Optus Sport.',
          subText: `Subscribe to access every match of the Women's Super League live and on demand.`,
          className: 'signup-hero-section-wsl',
          mobileImageUrl: `/images/signup/wsl/signup-hero-wsl-mobile.png`,
          tabletImageUrl: `/images/signup/wsl/signup-hero-wsl-tablet.png`,
          desktopImageUrl: `/images/signup/wsl/signup-hero-wsl-desktop.png`,
        },
      },
      sellingPointsSection: {
        showSection: true,
        themes: {
          default: {
            itemsList: [
              {
                enabled: true,
                headerText: `The World's Best Football`,
              },
              {
                enabled: true,
                headerText: `Watch it live, watch it later`,
              },
              {
                enabled: true,
                headerText: `Follow your favourite team`,
              },
            ],
            images: {
              desktop: '/images/signup/pl-selling-points-desktop-2.png',
              tablet: '/images/signup/pl-selling-points-tablet-2.png',
              mobile: '/images/signup/pl-selling-points-mobile-2.png',
            },
          },
          pl: {
            images: {
              desktop: '/images/signup/pl-selling-points-desktop-2.png',
              tablet: '/images/signup/pl-selling-points-tablet-2.png',
              mobile: '/images/signup/pl-selling-points-mobile-2.png',
            },
          },
          laliga: {
            images: {
              desktop: '/images/signup/laliga-selling-points-desktop.png',
              tablet: '/images/signup/laliga-selling-points-tablet.png',
              mobile: '/images/signup/laliga-selling-points-mobile.png',
            },
          },
          wsl: {
            images: {
              desktop: '/images/signup/wsl/wsl-selling-points-desktop.png',
              tablet: '/images/signup/wsl/wsl-selling-points-tablet.png',
              mobile: '/images/signup/wsl/wsl-selling-points-mobile.png',
            },
          },
        },
      },
      exploreSubhubSection: {
        showSection: true,
        headingText: `How does Optus <span class='text-cyan-medium-dark'>Sub</span><span class='text-cyan'>Hub</span> work?`,
        subText: `Easily manage your subscriptions, by bringing them together in the one place. You'll enjoy the ease of making just one simple payment each month, and it can help you make some big savings too!`,
        learnMoreLinkURL: 'https://subhub.com.au/',
        partners: [
          {
            url: '/images/signup/partners/partner-optus-sport.png',
            alt: 'Optus sport',
          },
          {
            url: '/images/signup/partners/partner-prime.png',
            alt: 'Prime',
          },
          {
            url: '/images/signup/partners/partner-netflix.png',
            alt: 'Netflix',
          },
          {
            url: '/images/signup/partners/partner-paramount.png',
            alt: 'Paramount',
          },
          {
            url: '/images/signup/partners/partner-binge.png',
            alt: 'Binge',
          },
          {
            url: '/images/signup/partners/partner-brit-box.png',
            alt: 'Brit box',
          },
          {
            url: '/images/signup/partners/partner-youtube.png',
            alt: 'Youtube',
          },
          {
            url: '/images/signup/partners/partner-calm.png',
            alt: 'Calm',
          },
          {
            url: '/images/signup/partners/partner-kindle.png',
            alt: 'Kindle',
          },
          {
            url: '/images/signup/partners/partner-microsoft.png',
            alt: 'Microsoft',
          },
          {
            url: '/images/signup/partners/partner-master-class.png',
            alt: 'Master class',
          },
          {
            url: '/images/signup/partners/partner-glyph.png',
            alt: 'Glyph',
          },
          {
            url: '/images/signup/partners/partner-iwonder.png',
            alt: 'IWonder',
          },
          {
            url: '/images/signup/partners/partner-iqiyi.png',
            alt: 'IQIYI',
          },
          {
            url: '/images/signup/partners/partner-inkl.png',
            alt: 'INKL',
          },
        ],
      },
      devicesSection: {
        showSection: true,
        headingText: `Watch the way you want`,
        subText: `Watch Optus Sport on your Mobile, Smart TV, Gaming Console, Chromecast, FireTV and more.`,
        imageUrl: `/images/signup/ways-of-watching.png`,
        imageUrlMobile: `/images/signup/ways-of-watching-mobile.png`,
        showDeviceItemsSection: false,
      },
      pageThemeSection: {
        default: {
          'signup-page-theme-section-1': {
            showSection: true,
            showButton: false,
            headingText: `Beyond the live game`,
            subText: `Follow football like never before with mini matches, highlights, shows and 24/7 Live TV channels, all on the Optus Sport app.`,
            mobileImageUrl: `/images/signup/page-theme-section-laliga-mobile.png`,
            tabletImageUrl: `/images/signup/page-theme-section-laliga-mobile.png`,
            desktopImageUrl: `/images/signup/page-theme-section-laliga-desktop.png`,
            className: `signup-page-theme-section signup-page-theme-section-grey-bg`,
          },
          'signup-page-theme-section-2': {
            showSection: false,
            showButton: true,
            buttonText: 'Learn More',
            buttonUrl: '#',
            buttonTarget: '',
            headingText: `Optus Plus Promo Plan`,
            subText: `Get Optus Sport Subscription free for 6 months if paired with a new Optus mobile plan*`,
            mobileImageUrl: `/images/signup/page-theme-section-epl-2-mobile.png`,
            tabletImageUrl: `/images/signup/page-theme-section-epl-2-mobile.png`,
            desktopImageUrl: `/images/signup/page-theme-section-epl-2.png`,
            className: `signup-page-theme-section signup-page-theme-section-no-bg`,
          },
        },
        'womens-world-cup-2023': {
          'signup-page-theme-section-1': {
            showSection: true,
            showButton: false,
            headingText: `Premier League`,
            subText: `Enjoy exclusive access to every single match of the Premier League live and on demand`,
            mobileImageUrl: `/images/signup/page-theme-section-wwc-1-mobile.png`,
            tabletImageUrl: `/images/signup/page-theme-section-wwc-1-mobile.png`,
            desktopImageUrl: '/images/signup/page-theme-section-wwc-1.png',
          },
          'signup-page-theme-section-2': {
            showSection: false,
            showButton: true,
            headingText: 'Watch live, pause and rewind',
            subText: 'Making it easy to watch every moment of the tournament',
            mobileImageUrl:
              '/images/signup/page-theme-section-wwc-2-mobile.png',
            tabletImageUrl:
              '/images/signup/page-theme-section-wwc-2-mobile.png',
            desktopImageUrl: '/images/signup/page-theme-section-wwc-2.png',
          },
        },
        laliga: {
          'signup-page-theme-section-1': {
            showSection: true,
            showButton: false,
            headingText: 'Beyond the live game',
            subText:
              'Follow LALIGA like never before with mini matches, highlights, shows and a 24/7 LALIGA TV channel in the Optus Sport app',
            mobileImageUrl:
              '/images/signup/page-theme-section-laliga-mobile.png',
            tabletImageUrl:
              '/images/signup/page-theme-section-laliga-mobile.png',
            desktopImageUrl:
              '/images/signup/page-theme-section-laliga-desktop.png',
          },
          'signup-page-theme-section-2': {
            showSection: false,
            showButton: true,
            headingText: 'Lorem ipsum dolor sit amet',
            subText:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sagittis, mi quis ornare ultricies, nisl tortor rutrum magna',
            mobileImageUrl:
              '/images/signup/page-theme-section-laliga-2-mobile.png',
            tabletImageUrl:
              '/images/signup/page-theme-section-laliga-2-mobile.png',
            desktopImageUrl: '/images/signup/page-theme-section-laliga-2.png',
          },
        },
        pl: {
          'signup-page-theme-section-1': {
            showSection: true,
            showButton: false,
            headingText: 'Beyond the live game',
            subText:
              'Follow Premier League like never before with mini matches, highlights, shows and a 24/7 Premier League TV channel, all in the Optus Sport app',
            mobileImageUrl:
              '/images/signup/page-theme-section-epl-1-mobile.png',
            tabletImageUrl:
              '/images/signup/page-theme-section-epl-1-mobile.png',
            desktopImageUrl: '/images/signup/page-theme-section-epl-1.png',
          },
          'signup-page-theme-section-2': {
            showSection: false,
            showButton: true,
            headingText: 'Lorem ipsum dolor sit amet',
            subText:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sagittis, mi quis ornare ultricies, nisl tortor rutrum magna',
            mobileImageUrl:
              '/images/signup/page-theme-section-epl-2-mobile.png',
            tabletImageUrl:
              '/images/signup/page-theme-section-epl-2-mobile.png',
            desktopImageUrl: '/images/signup/page-theme-section-epl-2.png',
          },
        },
        wsl: {
          'signup-page-theme-section-1': {
            showSection: true,
            showButton: false,
            headingText: `Catch the Matildas play`,
            subText: `Follow your favourite Matildas into the Women's Super League with matches and highlights each week, all in the Optus Sport app.`,
            mobileImageUrl: `/images/signup/wsl/page-theme-section-wsl-mobile.png`,
            tabletImageUrl: `/images/signup/wsl/page-theme-section-wsl-mobile.png`,
            desktopImageUrl: `/images/signup/wsl/page-theme-section-wsl-desktop.png`,
          },
          'signup-page-theme-section-2': {
            showSection: false,
            showButton: true,
            headingText: `Lorem ipsum dolor sit amet`,
            subText: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sagittis, mi quis ornare ultricies, nisl tortor rutrum magna`,
            mobileImageUrl: `/images/signup/page-theme-section-epl-2-mobile.png`,
            tabletImageUrl: `/images/signup/page-theme-section-epl-2-mobile.png`,
            desktopImageUrl: `/images/signup/page-theme-section-epl-2.png`,
          },
        },
      },
      createAccountSection: {
        showSection: true,
        className: `bg-core-dark-grey`,
        headingText: `Create your free account now`,
        subText: `Not sure if you want a subscription yet? Create an account for free and see what Optus Sport has to offer`,
        hideViewActionButton: false,
        disclaimerText: `
          <p class='font-MarkPro text-light-indicator-grey w-full text-center text-xs leading-16px'>*New and existing Optus Consumer customers with an eligible Optus service, who receive an Optus bill and are the account holder of that service&nbsp;<a href='https://www.optus.com.au/about/legal/standard-forms-agreement/personal/television' target='_blank' class='os-transition-btn font-MarkProBold text-teal hover:text-teal-dark active:text-teal-light no-underline' rel='noopener noreferrer'>Learn more</a><br/>SubHub monthly savings are based on RRP of $24.99, Annual Plan savings are based on 12 Monthly Plan payments.<br/>Personal viewing in AU. Content may change.&nbsp;<a href='https://sport.optus.com.au/terms' target='_blank' class='os-transition-btn font-MarkProBold text-teal hover:text-teal-dark active:text-teal-light no-underline' rel='noopener noreferrer'>Optus Sport T&Cs</a> apply.&nbsp;<a href='https://www.optus.com.au/content/dam/optus/appendix/Appendix%20S/AppS.doc' target='_blank' class='os-transition-btn font-MarkProBold text-teal hover:text-teal-dark active:text-teal-light no-underline' rel='noopener noreferrer'>Fair Go Policy</a> applies</p>
        `,
      },
      logosSection: {
        showSection: true,
        headingText: `What's on at Optus Sport?`,
        logos: [
          {
            url: '/images/competition/PL.png',
            alt: 'English Premier League',
          },
          {
            url: '/images/competition/WSL.png',
            alt: `Women's Super League`,
          },
          {
            url: '/images/competition/NWSL.png',
            alt: 'National Women Soccer League',
          },
          {
            url: '/images/competition/LL-2023.png',
            alt: 'LALIGA',
          },
          {
            url: '/images/competition/EURO.png',
            alt: 'UEFA Euro 2024',
          },
          {
            url: '/images/competition/copa-america-2024.svg',
            alt: 'Copa America 2024',
          },
          {
            url: '/images/competition/EQ.png',
            alt: 'European Qualifiers',
          },
          {
            url: '/images/competition/NL.png',
            alt: 'UEFA Nations League',
          },
          {
            url: '/images/competition/JL.png',
            alt: 'J.League',
          },
          {
            url: '/images/competition/DFB.png',
            alt: 'DFB Pokal',
          },
          {
            url: '/images/competition/DFBfrauen.png',
            alt: 'DFB Pokal Frauen',
          },
          {
            url: '/images/competition/KLeague.png',
            alt: 'K League',
          },
        ],
      },
      subscriptionPlanSection: {
        showSection: true,
        headingText: 'Pick your plan',
        disclaimerText: ``,
        subscriptionPlansList: [
          {
            showSubscription: true,
            planTitle: `<p class='text-cyan-medium-dark'>Optus&nbsp</p>customers`,
            subscriptionSubtext: `Eligible Optus Customers*`,
            beforePrice: '$24.99',
            price: `$6.99`,
            promoText: `Billed monthly, cancel anytime`,
            badgeText: 'Save over 70% *',
            canSubscribeViaSubhub: true,
            isInternationalPass: false,
            subscribeViaSubhubLink: `https://subhub.optus.com.au/streaming-services/optussport`,
          },
          {
            id: [
              { planTitle: 'Monthly Plan', planId: 'S377576321_AU' },
              { planTitle: 'Monthly Plan', planId: 'S377576321_AU_PROD_TBD' },
            ],
            showSubscription: true,
            planTitle: 'Monthly Plan',
            subscriptionSubtext: '',
            beforePrice: null,
            price: '$24.99',
            promoText: 'Billed monthly, cancel anytime',
            badgeText: null,
            canSubscribeViaSubhub: false,
            isInternationalPass: false,
          },
          {
            id: [
              {
                planTitle: 'Three Month Plan',
                planId: 'pp_tbc',
              },
              {
                planTitle: 'Three Month Plan',
                planId: 'prod_tbc',
              },
            ],
            showSubscription: false,
            planTitle: 'Three Month Plan',
            subscriptionSubtext: `New and returning customers only <sup class='text-2xs font-MarkProHeavy'>3</sup>`,
            beforePrice: '$75.00',
            price: '$50.00',
            promoText: `Billed quarterly, cancel anytime`,
            badgeText: 'Limited offer (ends 14/07/24)',
            canSubscribeViaSubhub: false,
            isInternationalPass: false,
          },
          {
            id: [
              { planTitle: '6 Month Plan', planId: 'S344110223_AU' },
              { planTitle: '6 Month Plan', planId: 'S344110223_AU_PROD_TBD' },
            ],
            showSubscription: false,
            planTitle: '6 Month Plan',
            subscriptionSubtext: 'Get 50% off for a limited time*',
            beforePrice: '$149.95',
            price: '$74.97',
            promoText: 'Billed every 6 months',
            badgeText: 'Limited time offer*',
            canSubscribeViaSubhub: false,
            isInternationalPass: false,
          },
          {
            id: [
              { planTitle: 'Annual Plan', planId: 'S792796051_AU' },
              { planTitle: 'Annual Plan', planId: 'S792796051_AU_PROD_TBD' },
            ],
            showSubscription: true,
            planTitle: 'Annual Plan',
            subscriptionSubtext: '',
            beforePrice: '$299.88',
            price: '$199.00',
            promoText: 'Billed annually, cancel anytime',
            badgeText: 'Save over 33% *',
            canSubscribeViaSubhub: false,
            isInternationalPass: false,
          },
          {
            id: [
              {
                planTitle: 'Tournament Pass',
                planId: 'P625421570_AU',
              },
              {
                planTitle: 'Tournament Pass',
                planId: 'P625421570_AU_PROD_TBD',
              },
            ],
            showSubscription: false,
            planTitle: 'Tournament Pass',
            subscriptionSubtext: 'International Visitors Only*',
            beforePrice: null,
            price: '$39.99',
            promoText: '1 July - 22 August 2023',
            badgeText: '',
            canSubscribeViaSubhub: false,
            isInternationalPass: true,
          },
        ],
      },
      subscriptionDetailsSection: {
        headingText: `What you get in a plan`,
        showSection: true,
        hideRedeemVoucherLink: false,
        showButton: false,
        itemsListHeading: { col1: 'Content:', col2: 'Free', col3: 'Plan' },
        itemsList: [
          { name: 'Match Highlights', free: true, plan: true },
          { name: 'Breaking news', free: true, plan: true },
          { name: 'Scores & Stats', free: true, plan: true },
          { name: 'Optus Sport Fitness', free: true, plan: true },
          { name: 'Live Matches', free: false, plan: true },
          { name: 'Full Match Replays', free: false, plan: true },
          { name: 'Mini-Matches & Mega-Minis', free: false, plan: true },
          { name: '24/7 Live Channel', free: false, plan: true },
          { name: 'Shows & Classic Matches', free: false, plan: true },
          { name: 'Optus Sport Originals', free: false, plan: true },
        ],
      },
      promotionalBannerSection: {
        imgURL: '/images/promotional-1.png',
        imgAlt: 'Win the ultimate Premier League trip',
        title: 'Win the ultimate Premier League trip!',
        text: '<b>Optus Sport and SubHub</b> are giving you the chance to win the football trip of a lifetime with our Ultimate Premier League Giveaway.',
        ctaText: 'Learn more',
        ctaLink:
          'https://subhub.optus.com.au/optus-sport-competition?utm_source=os&utm_medium=signup_banner&utm_campaign=os_subhubcomp',
      },
      themes: {
        default: {
          sections: [
            'heroSection',
            'signupPromotionalBanner',
            'subscriptionPlanSection',
            'subscriptionDetailsSection',
            'signup-page-theme-section-2',
            'sellingPointsSection',
            'logosSection',
            'signup-page-theme-section-1',
            'devicesSection',
            // 'exploreSubhubSection',
            'createAccountSection',
          ],
        },
        pl: {
          sections: [
            'heroSection',
            'signupPromotionalBanner',
            'sellingPointsSection',
            'logosSection',
            'signup-page-theme-section-1',
            'subscriptionDetailsSection',
            'signup-page-theme-section-2',
            'subscriptionPlanSection',
            'devicesSection',
            'exploreSubhubSection',
            'createAccountSection',
          ],
        },
        laliga: {
          sections: [
            'heroSection',
            'signupPromotionalBanner',
            'sellingPointsSection',
            'logosSection',
            'signup-page-theme-section-1',
            'subscriptionDetailsSection',
            'signup-page-theme-section-2',
            'subscriptionPlanSection',
            'devicesSection',
            'exploreSubhubSection',
            'createAccountSection',
          ],
        },
        'womens-world-cup-2023': {
          sections: [
            'heroSection',
            'signupPromotionalBanner',
            'sellingPointsSection',
            'logosSection',
            'signup-page-theme-section-1',
            'subscriptionDetailsSection',
            'signup-page-theme-section-2',
            'subscriptionPlanSection',
            'devicesSection',
            'exploreSubhubSection',
            'createAccountSection',
          ],
        },
      },
    },
    PAYMENT_PAGE_CONFIG: {
      optusCustomerSection: {
        enabled: true,
        headerText: `Are you an <span class='text-yellow'>Optus</span> Customer?`,
        primaryText: `If you're an eligible Optus customer, activate and manage your subscription through Optus SubHub to receive a discounted price of $6.99 per month.`,
        divider: {
          enabled: true,
          text: 'or',
        },
        ctaButton: {
          enabled: true,
          text: 'Go to Subhub',
          link: 'https://subhub.optus.com.au/streaming-services/optussport',
        },
      },
    },
    HELP_SUPPORT_CONFIG: {
      faq_links: [
        {
          text: 'Login issues',
          url: 'https://www.optus.com.au/for-you/support/answer?id=7011&utm_source=OSWeb-Help',
          showWhenLoggedIn: false,
          showWhenLoggedOut: true,
        },
        {
          text: 'Streaming & buffering issues',
          url: 'https://www.optus.com.au/for-you/support/answer?id=7009&utm_source=OSWeb-Help',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true,
        },
        {
          text: 'Error message while viewing content',
          url: 'https://www.optus.com.au/for-you/support/answer?id=7019&utm_source=OSWeb-Help',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true,
        },
        {
          text: 'Delete account',
          url: 'https://www.optus.com.au/for-you/support/answer?id=20253&utm_source=OSWeb-Help',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true,
        },
      ],
    },
    FEATURE_HELP_SUPPORT_V2: false,
    HELP_SUPPORT_CONFIG_V2: {
      headingText: 'Optus Sport Help',
      subText:
        "Need a hand? Visit the <a class='link-css os-transition os-transitionproperty-all' href='https://www.optus.com.au/for-you/support/entertainment/optus-sport-hub?utm_source=OSWeb-Help' target='_blank' rel='noopener noreferrer'>Optus Sport Support Hub</a> or check the list below for answers to some common questions and troubleshooting:",
      faqSection: {
        title: 'View common topics:',
        faqLinks: [
          {
            text: 'Login issues',
            url: 'https://www.optus.com.au/for-you/support/answer?id=7011&utm_source=OSWeb-Help',
            showWhenLoggedIn: false,
            showWhenLoggedOut: true,
          },
          {
            text: 'Streaming & buffering issues',
            url: 'https://www.optus.com.au/for-you/support/answer?id=7009&utm_source=OSWeb-Help',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
          {
            text: 'Error message while viewing content',
            url: 'https://www.optus.com.au/for-you/support/answer?id=7019&utm_source=OSWeb-Help',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
          {
            text: 'Delete account',
            url: 'https://www.optus.com.au/for-you/support/answer?id=20253&utm_source=OSWeb-Help',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
          {
            text: 'Smart TV troubleshooting',
            url: 'https://www.optus.com.au/for-you/support/answer?id=6989&utm_source=OSWeb-Help',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
          {
            text: 'Casting issues',
            url: 'https://www.optus.com.au/support/answer?id=20273&utm_source=OSWeb-Help',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
          {
            text: 'Why am I seeing ads?',
            url: 'https://www.optus.com.au/for-you/support/answer?id=7003#:~:text=devices%20within%20Australia.-,Advertising,-Optus%20Sport%20is&utm_source=OSWeb-Help',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
        ],
      },
      chatSection: {
        showWhenLoggedIn: true,
        showWhenLoggedOut: true,
        titleWhenSocialsEnabled: "Don't have socials? Chat with us 24/7",
        titleWhenSocialsDisabled: "Can't solve it? Chat with us 24/7",
        chatBtn: {
          text: 'Chat with an expert',
          link: 'https://www.optus.com.au/support/entertainment/optus-sport-hub?utm_source=OSWeb-Help',
          type: 'secondary',
          analytics: { source: 'chat with an expert' },
        },
        supportHubBtn: {
          text: 'Visit Support hub',
          link: 'https://www.optus.com.au/support/entertainment/optus-sport-hub?utm_source=OSWeb-Help',
          type: 'secondary',
          analytics: { source: 'visit support hub' },
        },
      },
      reportIssueSection: {
        title:
          'Urgent streaming issue? Please log in or create an account to report an issue',
        loginBtn: {
          text: 'Log in or sign up',
          link: '/login',
          type: 'secondary',
        },
        showWhenLoggedIn: false,
        showWhenLoggedOut: true,
      },
      socialsSection: {
        title:
          'Urgent streaming issue? Shoot us a DM on our social platforms below',
        socials: [
          {
            icon: '/icons/icon-messenger.png',
            text: 'Messenger',
            url: 'http://m.me/optussport',
            cssClass: 'w-80',
            analytics: { source: 'fb messenger' },
          },
          {
            icon: '/icons/icon-x.png',
            text: 'X (Twitter)',
            url: 'https://twitter.com/messages/compose?recipient_id=4196267478',
            cssClass: 'w-72',
            analytics: { source: 'x' },
          },
        ],
        showWhenLoggedIn: true,
        showWhenLoggedOut: false,
      },
      activeIssuesSection: {
        linkText: 'View active issues',
        showWhenLoggedIn: true,
        showWhenLoggedOut: false,
      },
    },
    GET_HELP_CONFIG: {
      faq_links: [
        {
          text: 'Login issues',
          url: 'https://www.optus.com.au/for-you/support/answer?id=7011&utm_source=OSMobileApp-Help',
          showWhenLoggedIn: false,
          showWhenLoggedOut: true,
        },
        {
          text: 'Streaming & buffering issues',
          url: 'https://www.optus.com.au/for-you/support/answer?id=7009&utm_source=OSMobileApp-Help',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true,
        },
        {
          text: 'Error message while viewing content',
          url: 'https://www.optus.com.au/for-you/support/answer?id=7019&utm_source=OSMobileApp-Help',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true,
        },
        {
          text: 'Delete account',
          url: 'https://www.optus.com.au/for-you/support/answer?id=20253&utm_source=OSMobileApp-Help',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true,
        },
      ],
    },
    FEATURE_GET_HELP_V2: false,
    GET_HELP_CONFIG_V2: {
      headingText: '',
      subText:
        "Need a hand? Visit the <a class='link-css os-transition os-transitionproperty-all' href='https://www.optus.com.au/for-you/support/entertainment/optus-sport-hub?utm_source=OSMobileApp-Help&targetHandler=external_browser' target='_blank' rel='noopener noreferrer'>Optus Sport Support Hub</a> or check the list below for answers to some common questions and troubleshooting:",
      faqSection: {
        title: '',
        faqLinks: [
          {
            text: 'Login issues',
            url: 'https://www.optus.com.au/for-you/support/answer?id=7011&utm_source=OSMobileApp-Help&targetHandler=external_browser',
            showWhenLoggedIn: false,
            showWhenLoggedOut: true,
          },
          {
            text: 'Streaming & buffering issues',
            url: 'https://www.optus.com.au/for-you/support/answer?id=7009&utm_source=OSMobileApp-Help&targetHandler=external_browser',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
          {
            text: 'Error message while viewing content',
            url: 'https://www.optus.com.au/for-you/support/answer?id=7019&utm_source=OSMobileApp-Help&targetHandler=external_browser',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
          {
            text: 'Delete account',
            url: 'https://www.optus.com.au/for-you/support/answer?id=20253&utm_source=OSMobileApp-Help&targetHandler=external_browser',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
          {
            text: 'Smart TV troubleshooting',
            url: 'https://www.optus.com.au/for-you/support/answer?id=6989&utm_source=OSMobileApp-Help&targetHandler=external_browser',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
          {
            text: 'Casting issues',
            url: 'https://www.optus.com.au/support/answer?id=20273&utm_source=OSMobileApp-Help&targetHandler=external_browser',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
          {
            text: 'Why am I seeing ads?',
            url: 'https://www.optus.com.au/for-you/support/answer?id=7003#:~:text=devices%20within%20Australia.-,Advertising,-Optus%20Sport%20is&utm_source=OSMobileApp-Help&targetHandler=external_browser',
            showWhenLoggedIn: true,
            showWhenLoggedOut: true,
          },
        ],
      },
      chatSection: {
        showWhenLoggedIn: true,
        showWhenLoggedOut: true,
        titleWhenSocialsEnabled: "Don't have socials? Chat with us 24/7",
        titleWhenSocialsDisabled: "Can't solve it? Chat with us 24/7",
        chatBtn: {
          text: 'Chat with an expert',
          link: 'https://www.optus.com.au/support/entertainment/optus-sport-hub?utm_source=OSMobileApp-Help&targetHandler=internal_browser',
          analytics: { source: 'chat with an expert' },
        },
        supportHubBtn: {
          text: 'Visit Support hub',
          link: 'https://www.optus.com.au/support/entertainment/optus-sport-hub?utm_source=OSMobileApp-Help&targetHandler=internal_browser',
          analytics: { source: 'visit support hub' },
        },
      },
      reportIssueSection: {
        title:
          'Urgent streaming issue? Please log in or create an account to report an issue',
        loginBtn: {
          text: 'Log in or sign up',
          link: 'https://sport.optus.com.au/login?targetHandler=app',
        },
        showWhenLoggedIn: false,
        showWhenLoggedOut: true,
      },
      socialsSection: {
        title:
          'Urgent streaming issue? Shoot us a DM on our social platforms below',
        socials: [
          {
            icon: '/icons/icon-messenger.png',
            text: 'Messenger',
            url: 'http://m.me/optussport?targetHandler=external_browser',
            cssClass: 'w-80',
            analytics: { source: 'fb messenger' },
          },
          {
            icon: '/icons/icon-x.png',
            text: 'X (Twitter)',
            url: 'https://twitter.com/messages/compose?recipient_id=4196267478&targetHandler=external_browser',
            cssClass: 'w-72',
            analytics: { source: 'x' },
          },
        ],
        showWhenLoggedIn: true,
        showWhenLoggedOut: false,
      },
      activeIssuesSection: {
        linkText: 'View active issues',
        url: 'https://sport.optus.com.au/support?targetHandler=app',
        showWhenLoggedIn: true,
        showWhenLoggedOut: false,
      },
    },
    SAMSUNG_TV_CONFIG: {
      headerText: `The World's Best Football.`,
      primaryText: `
        <div>Optus Sport is the place to watch every match of the FIFA Women's World Cup 2023™. Live and on demand on your Samsung TV.</div>
        <br />
        <div>Catch all the action of the Premier League, LALIGA, the Women's Super League, J.League, K League and DFB Pokal along with the FIFA Women's World Cup 2023™ and UEFA Euro 2024™.</div>
      `,
      signupCtaUrl: '/register',
      signupCta: false,
      bgImageLarge: ['/images/samsungtv_wwc_bg_2x.jpg 1x'], // 3840 × 2160
      bgImageMedium: ['/images/samsungtv_wwc_bg_1x.jpg 1x'], // 1920 × 1080
      bgImageSmall: [
        '/images/samsungtv_wwc_bg_small_1x.jpg 1x', // 480 x 480
        '/images/samsungtv_wwc_bg_small_2x.jpg 2x', // 960 x 960
      ],
      ctaButtonText: 'Get Optus Sport now',
      disclaimerText: `Compatible device required. Personal viewing in AU. Data charges may apply. Content can change. Fair Go Policy, T&Cs apply.`,
    },
    FITNESS_CONFIG: {
      paywallEnabled: true,
      headerText: `Workouts for every body<br/>All in one place.`,
      primaryText: `
      Sign up to <strong class='font-MarkProHeavy'>Optus Sport </strong> and start working out today for <strong class='font-MarkProHeavy'>$24.99 </strong> per month or it’s included with selected Optus plans.
      `,
      secondaryText: ``,
    },
    ARTICLE_DETAIL_CONFIG: {
      carousel: {
        id: 'manual_article_related',
        title: 'Read More',
        showViewAllLink: false,
      },
    },
    ASSET_PLAY_CONFIG: {
      fitness: {
        load: {
          TOP_PICKS: {
            id: 'top_picks',
            limit: 16,
          },
          SIMILAR_CLASSES: {
            id: 'similar_classes',
            limit: 32,
          },
        },
        carousel: [
          {
            id: 'similar_classes',
            title: 'More like this',
            viewAllUrl: '/fitness',
          },
          {
            id: 'top_picks',
            title: 'Top picks',
          },
        ],
      },
    },
    FITNESS_LANDING_CONFIG: {
      carousel: {
        id: 'top_picks',
        title: 'Find your next workout',
        sectionPath: '/fitness',
      },
    },
    FEATURE_SEARCH: true,
    HOMEPAGE_CONFIG: {
      freeOnlyPanels: [],
    },
    ANALYTICS: {
      mux: true,
      muxDebug: true,
      //  disabledEvents: []
      //    to disable Competition from all vendor ['Competition'],
      //    to disable Competition from braze only ['Competition::braze']
    },
    SEO_CONFIG: {
      categoryNames: ['the athletic'],
      tagNames: ['athletic'],
    },
    SELF_SERVE_PAGE_PREFIX: '',
    SELF_SERVE_VOUCHER_CAMPAIGN: false,
    SELF_SERVE_USER_COMPETITION: false,
    SELF_SERVE_SIGNUP: false,
    SELF_SERVE_OFFERS: false,
    CUSTOM_WIDGET: {
      recently_watched: {
        assetStructure: ['data', 'viewedAssets'],
        showViewAllLink: false,
        loginRequired: true,
      },
      explore: {
        showViewAllLink: false,
      },
      dynamic_rail: {
        isCarousel: true,
      },
      banner: {
        isCarousel: true,
      },
      content_card_location: {
        isCarousel: true,
      },
    },
    FEATURE_BITMOVIN_SEGMENT_ENABLED: true,
    FOOTER_LINKS: [
      {
        widgettype: 'help-links',
        links: [
          {
            text: 'Chat with an expert',
            url: `https://www.optus.com.au/for-you/support/entertainment/optus-sport-hub?utm_source=OSWeb-footer`,
          },
          {
            text: 'Login issues',
            url: `https://www.optus.com.au/for-you/support/answer?id=20254&utm_source=OSWeb-footer`,
          },
          {
            text: 'Streaming & buffering issues',
            url: `https://www.optus.com.au/for-you/support/answer?id=7009&utm_source=OSWeb-footer`,
          },
          {
            text: 'Error message while viewing content',
            url: `https://www.optus.com.au/for-you/support/answer?id=7019&utm_source=OSWeb-footer`,
          },
          {
            text: 'Payment & billing',
            url: `https://www.optus.com.au/for-you/support/answer?id=9704&utm_source=OSWeb-footer`,
          },
          {
            text: 'Manage account',
            url: `https://www.optus.com.au/for-you/support/answer?id=20259&utm_source=OSWeb-footer`,
          },
          {
            text: 'Log a streaming issue',
            url: `https://sport.optus.com.au/help?utm_source=OSWeb-footer`,
          },
        ],
      },
    ],
    PAYMENT_CONFIG: {
      vendor: 'bambora',
      vendor_config: {
        fingerprintScript: 'https://h.online-metrix.net/fp/tags.js',
        defaultErrorMessage: 'An error occurred',
        ctaErrorMessage:
          'Please try again. If the error persists, please contact Optus Sport support.',
        ctaErrorMessageHtml: `<p>Please try again. If the error persists, please contact <a class='text-teal cursor-pointer no-underline' href="https://www.optus.com.au/for-you/support/entertainment/optus-sport-hub" target="_blank" rel="noopener noreferrer">Optus Sport support</a>.</p> <p>Please <a class='text-teal cursor-pointer' onClick='location.reload()'>click here</a> to refresh this page.</p>`,
      },
      useCurrentVendor: false,
    },
  },

  // OSN-1438: Temporary until OSN-1432 is ready to be deployed (can be removed once ready)
  redirectFitnessOnOptusSport: false,

  // Social app browsers
  inAppBrowserKeys: ['instagram', 'facebook', 'linkedin'],
};

export const DefaultDocumentTitle = 'Optus Sport';

// Allow for testing in non prod env
if (window.location.hash === '#noys' && defaultConfig.env !== 'prod') {
  defaultConfig.yspSdk = false;
}

export default defaultConfig;
