import { placeholderImg } from 'components/ui/ui-kit/Articles/utils';
import { extractText } from 'utils';

export const SubhubKey = 'SUBHUB';

export const offersMapping = (offers) => {
  return offers?.map((offer) => {
    const {
      frontendConfig: {
        price: displayPrice,
        promoText,
        title: planTitle,
        subscriptionSubtext,
        badgeText,
        badgeTextCheckout,
        beforePrice,
        tncShortForm,
        tncLongForm,
        subscribeViaSubhubLink,
        successMessage,
      } = {},
      planKey,
    } = offer;
    return {
      ...offer,
      price: offer?.discount?.price || offer?.normalPrice,
      showSubscription: true,
      displayPrice,
      promoText,
      planTitle,
      subscriptionSubtext,
      badgeText,
      badgeTextCheckout,
      beforePrice,
      tncShortForm,
      tncLongForm,
      successMessage,
      canSubscribeViaSubhub: planKey === SubhubKey,
      subscribeViaSubhubLink:
        subscribeViaSubhubLink ||
        'https://subhub.optus.com.au/streaming-services/optussport',
    };
  });
};

export const retentionOfferMapping = (offers) => {
  return offers?.map((offer) => {
    const {
      frontendConfig: {
        headingText,
        headLineText,
        noButtonText: noBtnText,
        offerTnCText,
        successContentText,
        successHeadingText,
        yesButtonText: yesBtnText,
        heroImageMobile = placeholderImg,
        heroImageDesktop = placeholderImg,
      } = {},
    } = offer || {};

    return {
      ...offer,
      headingText,
      headLineText,
      noBtnText,
      offerTnCText,
      successContentText,
      successHeadingText,
      yesBtnText,
      heroImageMobile,
      heroImageDesktop,
      heroImageAltText: extractText(headLineText),
    };
  });
};
