import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as brazeSDK from '@braze/web-sdk';

import { withFeatureFlags } from 'components/context/withFeatureFlags';
import { getUserId } from 'selectors/user';
import * as contentCardsAction from 'actions/contentCards';
import {
  brazeState,
  destroyBraze,
  isBrazeEnabled,
  setBrazeUserProfile,
  setupBraze,
} from 'utils/braze';
import { find, forEach } from 'lodash';
import { isSuccessAnyOf } from 'ducks/loading';
import { PAYMENT_INTENT } from 'ducks/payment';
import { CUSTOMER_SUBSCRIPTION_UPDATE } from 'ducks/subscription';
import withLogin from 'components/hoc/withLogin';
import { log } from '../utils';

const PromotionalPlacements = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const debugBraze = location.hash === '#debugOSBraze';

  const { getFeatureFlags, userLoggedIn, didUserLogin, didUserLogout } = props;
  const { userId, userProfile, userSettings, isSubscriptionUpdated } =
    useSelector((state) => ({
      userId: getUserId(state.user),
      userProfile: state.user?.profile,
      userSettings: state.user?.settings,
      isSubscriptionUpdated: isSuccessAnyOf(state.loading, [
        PAYMENT_INTENT,
        CUSTOMER_SUBSCRIPTION_UPDATE,
      ]),
      dynamicContentCards: state.dynamicContentCards,
    })) || {};

  const [isSubscribedBraze, setIsSubscribedBraze] = useState();
  const isUserSettingsUpdated = userSettings?.isUpdated || false;

  const promotionalPlacementsConfig = getFeatureFlags(
    'PROMOTIONAL_PLACEMENTS_CONFIG',
  );

  const {
    enabled: isPromotionalPlacementEnabled,
    braze: {
      disableContentCards: isBrazeContentCardsDisabled,
      useCache: useBrazeCache = false,
      contentCardLocationKey = 'web_location_id',
      contentCardAssetKey = 'asset_id',
    } = {},
  } = promotionalPlacementsConfig || {};

  const extractDynamicContentIds = (cards) => {
    const brazeDynamicCardsList = [];
    forEach(cards, (brazeContentCard) => {
      const {
        [contentCardLocationKey]: dynamic_location_id,
        [contentCardAssetKey]: dynamic_asset_id,
      } = brazeContentCard?.extras || {};

      // Accept first unique key and discard duplicates
      if (
        dynamic_location_id &&
        dynamic_asset_id &&
        !find(brazeDynamicCardsList, (c) => !!c[dynamic_location_id])
      ) {
        brazeDynamicCardsList.push({
          [`${dynamic_location_id}`]: dynamic_asset_id,
          card: brazeContentCard,
        });
      }
    });

    return brazeDynamicCardsList;
  };

  const dispatchSetDynamicContentCards = (cards) => {
    dispatch(contentCardsAction.setDynamicContentCards(cards));
  };

  const resetContentCards = () => {
    dispatchSetDynamicContentCards([]);
  };

  const checkBeforeSetBrazeContentCards = (brazeCards) => {
    const brazeDynamicContentIds = extractDynamicContentIds(brazeCards);
    log('=====Filtered list of content cards=====', brazeDynamicContentIds);
    dispatchSetDynamicContentCards(brazeDynamicContentIds);
  };

  const brazeContentCardsUpdatedHandler = (result) => {
    log('=====Full list of content cards returned from braze=====', result);
    if (result?.cards) {
      checkBeforeSetBrazeContentCards(result.cards);
    }
  };

  // fetch content cards from braze and CMS
  const fetchContentCards = () => {
    fetchGetContentCards();
    fetchGetAllContentCards();
    fetchBrazeContentCards();
  };

  const fetchBrazeContentCards = () => {
    !isBrazeContentCardsDisabled && brazeSDK.requestContentCardsRefresh();
  };

  const fetchGetContentCards = () => {
    dispatch(contentCardsAction.getContentCards(userId));
  };

  const fetchGetAllContentCards = () => {
    dispatch(contentCardsAction.getAllContentCards());
  };

  const initBraze = () => {
    const isBrazeReady = setupBraze({
      featureConfig: promotionalPlacementsConfig,
      userId,
      userLoggedIn,
      brazeContentCardsUpdatedHandler,
    });

    if (isBrazeReady) {
      if (useBrazeCache) {
        // initially set the braze content cards from cached
        const { cards: cachedBrazeContentCards = [] } =
          (!isBrazeContentCardsDisabled && brazeSDK.getCachedContentCards()) ||
          {};
        log('======cachedBrazeContentCards=====', cachedBrazeContentCards);
        checkBeforeSetBrazeContentCards(cachedBrazeContentCards);
      }

      // subscribe to braze content cards update
      if (!isSubscribedBraze) {
        setIsSubscribedBraze(true);
      }
    } else {
      if (isSubscribedBraze) {
        !isBrazeContentCardsDisabled && brazeSDK.removeAllSubscriptions();
        setIsSubscribedBraze(false);
        resetContentCards();
      }
    }
  };

  // This useEffect will be called on initial load, on user login and on user logout.
  useEffect(() => {
    const isBrazeEnable = isBrazeEnabled({
      featureConfig: promotionalPlacementsConfig,
      userLoggedIn,
    });
    // Destroy braze session when user logs in or logs out (wont run on initial load as braze has not been initialized)
    destroyBraze();
    if (isPromotionalPlacementEnabled) {
      fetchGetContentCards();
      fetchGetAllContentCards();

      if (isBrazeEnable) {
        brazeState.setData({ enableDebug: debugBraze });
        initBraze();
        fetchBrazeContentCards();
      } else {
        // Clear braze content cards from redux when user logs out and braze is disabled for logged out users
        dispatchSetDynamicContentCards([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didUserLogin, didUserLogout]);

  // fetchContentCards() when the user setting/profile gets updated
  useEffect(() => {
    if (isUserSettingsUpdated) {
      setBrazeUserProfile({
        ...userProfile,
      });
      fetchContentCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserSettingsUpdated]);

  // fetchContentCards when the user details get updated by subscription actions
  useEffect(() => {
    if (promotionalPlacementsConfig && isSubscriptionUpdated) {
      fetchContentCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscriptionUpdated]);

  return <></>;
};

// to prevent the component being re-rendered unnecessarily
export default React.memo(withFeatureFlags(withLogin(PromotionalPlacements)));
