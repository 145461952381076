/*
 * Optus Sport Entry point.
 * It render the application entry react component which is App.js
 * */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import _ from 'lodash';
import ThemeProvider from './common/Theme/ThemeProvider';
import 'unfetch/polyfill';
import App from './components/App';

import createPortalStore from './store/createPortalStore';

import { loadSystemConfigs } from './actions/systemConfig';
import { initUserObserver } from './actions/user';
import { initTheTheme, checkThemeAlert } from './actions/theme';

import defaultConfig from './constants/defaultConfig';

// Temp. dev as we don't completely use amplify cli for all aws services as
// some are being managed via sls (i.e. lambda)
import { Amplify } from 'aws-amplify';

// import './utils/raygun';
import { getJwtToken } from './utils/getJwtToken';

import { generateDeviceId, getTvid } from './utils';

// Facebook track
/*const {
  fbAppId,
  fbApiVersion
} = defaultConfig;
if (fbAppId && fbApiVersion) {
  const fbScriptEl = document.createElement('script');
  fbScriptEl.id = 'fbsdkjs';

  fbScriptEl.innerHTML = `
    window.fbAsyncInit = function() {
      FB.init({
        appId : '${fbAppId}',
        cookie : true,
        xfbml : true,
        version : '${fbApiVersion}'
      });

      FB.AppEvents.logPageView();
    };

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      js.async = true;
      js.defer = true;
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  `;

  document.head.appendChild(fbScriptEl);
}*/

// Segment track
const { segmentId, segmentDomain, segmentAnalyticsAlias } = defaultConfig;
if (segmentId) {
  const segmentScriptEl = document.createElement('script');

  // Segment CDN ref
  // segmentScriptEl.innerHTML = `
  //   !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var t=analytics.methods[e];analytics[t]=analytics.factory(t)}analytics.load=function(e,t){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+e+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=t};analytics.SNIPPET_VERSION="4.1.0";
  //   analytics.load('${segmentId}');
  //   analytics.page();
  //   }}();
  // `;

  // Optus CDN ref
  segmentScriptEl.innerHTML = `
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="${segmentDomain}/LB4BkJnt56/${segmentAnalyticsAlias}.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentId}";analytics._cdn = "${segmentDomain}";analytics.SNIPPET_VERSION="4.15.3";
    analytics.load("${segmentId}");

    if(window.location.href.indexOf('/usersettings/devices') === -1) {
      analytics.page();
    }
    }}();
  `;

  document.head.appendChild(segmentScriptEl);
}

// Auth.configure({
//   // REQUIRED - Amazon Cognito Region
//   region: defaultConfig.awsRegion,

//   // OPTIONAL - Amazon Cognito User Pool ID
//   userPoolId: defaultConfig.awsUserPoolId,

//   // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//   userPoolWebClientId: defaultConfig.awsUserPoolWebClientId,

//   // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
//   authenticationFlowType: defaultConfig.awsAuthenticationFlowType,
// });

Amplify.configure({
  // REQUIRED - Amazon Cognito Region
  region: defaultConfig.awsRegion,

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: defaultConfig.awsUserPoolId,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: defaultConfig.awsUserPoolWebClientId,

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  authenticationFlowType: defaultConfig.awsAuthenticationFlowType,
  endpoints: [
    {
      name: 'fe-api-dev-authLogin',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/login`,
    },
    {
      name: 'fe-api-dev-authForgotPassword',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/forgotPassword/v2`,
    },
    {
      name: 'fe-api-dev-authResetPassword',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/resetPassword/v2`,
    },
    {
      name: 'fe-api-dev-authChangePassword',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/changePassword/v2/users`,
      custom_header: async () => {
        const token = await getJwtToken('fe-api-dev-authChangePassword', true);
        const idJwtToken = _.get(token, 'idJwtToken');
        const accessJwtToken = _.get(token, 'accessJwtToken');
        return {
          Authorization: idJwtToken,
          // Possible better way to pass the access token?
          AccessAuthorization: accessJwtToken,
        };
      },
    },
    {
      name: 'fe-api-favouriteteams',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/favouriteteams`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-favouriteteams') };
      },
    },
    {
      name: 'fe-api-userGetUser',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/user/users`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-userGetUser') };
      },
    },
    {
      name: 'fe-api-logoutUser',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/logout/users`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-userGetUser') };
      },
    },
    {
      name: 'fe-api-recentActivity',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/user/devices/users`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-recentActivity') };
      },
    },
    {
      name: 'fe-api-globalLogout',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/globalLogout/users`,
      custom_header: async () => {
        const token = await getJwtToken('fe-api-globalLogout', true);
        const idJwtToken = _.get(token, 'idJwtToken');
        const accessJwtToken = _.get(token, 'accessJwtToken');
        return {
          Authorization: idJwtToken,
          // Possible better way to pass the access token?
          AccessAuthorization: accessJwtToken,
        };
      },
    },
    {
      name: 'fe-api-userGetUserDetails',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/details/users`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-userGetUserDetails'),
        };
      },
    },
    {
      name: 'fe-api-dev-userGetOrders',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/orders/users`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-dev-userGetOrders') };
      },
    },
    {
      name: 'fe-api-dev-validateEmail',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/validate/web/username`,
    },
    {
      name: 'fe-api-createUser',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/users/web`,
    },
    {
      name: 'fe-api-createUser-hyperion',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/users/hyperion`,
    },
    {
      name: 'fe-api-createUser-hyperion-session',
      // endpoint: `https://web-dev.sport-ott.com/api/userauth/hyperion/session`
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/userauth/hyperion/session`,
    },
    {
      name: 'fe-api-dev-subscriptionVoucher-existing-user',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/subscription`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken(
            'fe-api-dev-subscriptionVoucher-existing-user',
          ),
        };
      },
    },
    {
      name: 'fe-api-dev-subscriptionVoucher-new-user',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/subscription`,
    },
    {
      name: 'fe-api-dev-subscriptionUserCreate',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/subscription/users`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-dev-subscriptionUserCreate'),
        };
      },
    },
    {
      name: 'fe-api-dev-playbackGetPlayback',
      endpoint: `${defaultConfig.awsPlaybackBaseUrl}/playback/generalPlayback`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-dev-playbackGetPlayback'),
        };
      },
    },
    {
      name: 'fe-api-dev-playbackGetFreePlayback',
      endpoint: `${defaultConfig.awsPlaybackBaseUrl}/playback/freePlayback`,
    },
    {
      name: 'fe-api-dev-playbackGetPlaybackHistory',
      endpoint: `${defaultConfig.awsPlaybackBaseUrl}/playback/playbackHistory/users`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken(
            'fe-api-dev-playbackGetPlaybackHistory',
          ),
        };
      },
    },
    {
      name: 'fe-api-dev-metadataGetEpg',
      endpoint: `${defaultConfig.awsMetadataBaseUrl}/metadata/epg/assets`,
    },
    {
      name: 'fe-api-dev-ticketCreateTicket',
      endpoint: `${defaultConfig.awsTicketManagerBaseUrl}/ticket`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-dev-ticketCreateTicket'),
        };
      },
    },
    {
      name: 'fe-api-dev-ticketGetTickets',
      endpoint: `${defaultConfig.awsTicketManagerBaseUrl}/ticket`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-dev-ticketGetTickets'),
        };
      },
    },
    {
      name: 'fe-api-dev-ticketCreateTicketComment',
      endpoint: `${defaultConfig.awsTicketManagerBaseUrl}/ticket`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken(
            'fe-api-dev-ticketCreateTicketComment',
          ),
        };
      },
    },
    {
      name: 'fe-api-dev-ticketGetTicketComments',
      endpoint: `${defaultConfig.awsTicketManagerBaseUrl}/ticket`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken(
            'fe-api-dev-ticketGetTicketComments',
          ),
        };
      },
    },
    {
      name: 'fe-api-cleegSubscriptions',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/subscription/subscriptions`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-cleegSubscriptions'),
        };
      },
    },
    {
      name: 'fe-api-dev-metadataGetSystemConfig',
      endpoint: `${defaultConfig.awsSystemConfigBaseUrl}/metadata`,
    },
    {
      name: 'fe-api-dev-metadataGetNavigation',
      endpoint: `${defaultConfig.awsSystemConfigBaseUrl}/metadata`,
    },
    {
      name: 'fe-api-dev-metadataGetPage',
      endpoint: `${defaultConfig.awsSystemConfigBaseUrl}/metadata`,
    },
    {
      name: 'fe-api-dev-appManagerGetPage',
      endpoint: `${defaultConfig.awsFrontEndBaseUrl}/appmanager`,
    },
    {
      name: 'fe-api-dev-appManagerGetPageV2',
      endpoint: `${defaultConfig.awsFrontEndBaseUrl}/appmanager/v2`,
    },
    {
      name: 'fe-api-dev-metadataGetEditorial',
      endpoint: `${defaultConfig.awsMetadataBaseUrl}/metadata`,
    },
    {
      name: 'fe-api-dev-userSettings',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/user/setting/web/users`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-dev-userSettings'),
        };
      },
    },
    {
      name: 'fe-api-dev-userProfile',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/user/profile/web/users`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-dev-userProfile'),
        };
      },
    },
    {
      name: 'fe-api-dev-customGetEditorial',
      endpoint: `${defaultConfig.awsCustomFrontEndBaseUrl}`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-dev-customGetEditorial'),
        };
      },
    },
    {
      name: 'fe-api-dev-metadataGetAsset',
      endpoint: `${defaultConfig.awsMetadataBaseUrl}/metadata`,
    },
    {
      name: 'fe-api-dev-metadataGetChannelEpg',
      endpoint: `${defaultConfig.awsEPGMetadataBaseUrl}/channels`,
    },
    {
      name: 'fe-api-upnext',
      endpoint: `${defaultConfig.awsPlaybackBaseUrl}/playlist/upnext/web/users`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-upnext'),
        };
      },
    },
    {
      name: 'fe-api-dev-sportsdataGetSaagData',
      endpoint: `${defaultConfig.awsSportsDataBaseUrl}/sportsdata/v2/saag/`,
    },
    {
      name: 'fe-api-streamSessionManager',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}1/sessionmanager`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-streamSessionManager'),
        };
      },
    },
    {
      name: 'fe-api-authStartSession',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/msisdn/startSession`,
    },
    {
      name: 'fe-api-authCreateValidateUser',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/msisdn/verifyMobile`,
    },
    {
      name: 'fe-api-confirmCode',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/msisdn/confirmCode`,
    },
    {
      name: 'fe-api-resendConfirmationCode',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/msisdn/resendConfirmCode`,
    },
    {
      name: 'fe-api-createAccount',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/msisdn/createAccount`,
    },
    {
      name: 'fe-api-changePlan',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/creditCard`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-changePlan') };
      },
    },
    /**
     * fe-api-createOrder
     * `http://localhost:8000/paymentIntents`,
     * `${defaultConfig.awsUserAuthBaseUrl}/purchase/paymentIntents`,

     */
    {
      name: 'fe-api-newPlan',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/paymentIntents`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-newPlan') };
      },
    },
    /**
     * fe-api-createOrder
     * endpoint: `http://localhost:8000/orders`,
     * endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/orders`,
     */
    {
      name: 'fe-api-auth',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/auth`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-auth') };
      },
    },
    /**
     * fe-api-createOrder
     * endpoint: `http://localhost:8000/orders`,
     * endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/orders`,
     */
    {
      name: 'fe-api-createOrder',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/orders`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-createOrder') };
      },
    },
    {
      name: 'fe-api-redeemHistory',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/web/users`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-redeemHistory') };
      },
    },
    {
      name: 'fe-api-userEligibleCampaigns',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/web/users`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-userEligibleCampaigns'),
        };
      },
    },
    {
      name: 'fe-api-redeemRetentionOffer',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/v2/creditCard/users`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-redeemRetentionOffer'),
        };
      },
    },
    {
      name: 'fe-api-recurringSubscriptions',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/subscription/users`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-recurringSubscriptions'),
        };
      },
    },
    {
      name: 'fe-api-payments',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/payments`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-payments') };
      },
    },
    {
      name: 'fe-api-getOffers',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/subscription/offers`,
    },
    {
      name: 'fe-api-getOsOffers',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/web/users/`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-getOsOffers') };
      },
    },

    {
      name: 'fe-api-subscriptions-update',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/subscription/update`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-subscriptions-update'),
        };
      },
    },
    {
      name: 'fe-api-search',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/search`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-search'),
        };
      },
    },
    {
      name: 'fe-api-OSCompetitions',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/competitions`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-OSCompetitions'),
        };
      },
    },
    {
      name: 'fe-api-userCompetitionEligibility',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/competitions/eligibility`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-userCompetitionEligibility'),
        };
      },
    },
    {
      name: 'post-data-to-subhub',
      endpoint: `https://subhub-pp.subhub.optus.com.au/api/session/sso-redirect`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('post-data-to-subhub'),
        };
      },
    },
    {
      name: 'fe-api-getContentCards',
      endpoint: `${defaultConfig.awsFrontEndBaseUrl}1/contentcard/assets`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-userGetUserDetails'),
        };
      },
    },
    {
      name: 'fe-api-getAllContentCards',
      endpoint: `${defaultConfig.awsMetadataBaseUrl}/metadata/editorials/v2/content-cards/web`,
    },
    {
      name: 'fe-api-getPaymentSessions',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-getPaymentSessions'),
        };
      },
    },
    {
      name: 'fe-api-getPublicOffers',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/`,
    },
    {
      name: 'fe-api-getPurchaseOffers',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/`,
      custom_header: async () => {
        return {
          Authorization: await getJwtToken('fe-api-getPurchaseOffers'),
        };
      },
    },
    {
      name: 'fe-api-makePurchaseOffer',
      endpoint: `${defaultConfig.awsUserAuthBaseUrl}/purchase/v3/creditCard/`,
      custom_header: async () => {
        return { Authorization: await getJwtToken('fe-api-makePurchaseOffer') };
      },
    },
  ],
});

// Dev hack to sign user out on page load
//Auth.signOut()

// Inject Medallia embed script into body

const medalliaEmbedJsUrl = defaultConfig.medalliaEmbedJsUrl;
if (document && medalliaEmbedJsUrl) {
  const medalliaScriptEl = document.createElement('script');
  medalliaScriptEl.async = true;
  medalliaScriptEl.src = medalliaEmbedJsUrl;
  document.body.appendChild(medalliaScriptEl);
}

const store = createPortalStore();
export const appDispatch = store.dispatch;
appDispatch(loadSystemConfigs());
appDispatch(initUserObserver());
appDispatch(initTheTheme());
appDispatch(checkThemeAlert());

// deviceId in uuid
generateDeviceId();
getTvid();

// Prerender waiting: set interval to check when page is mostly rendered
// if (window.isPrerenderRequest) {
//   const checkReady = () => {
//     if (!window.prerenderReady) {
//       if (document.querySelectorAll('[data-react-helmet="true"]').length) {
//         window.prerenderReady = true;
//         return true;
//       }
//     } else {
//       return true;
//     }
//
//     return false;
//   };
//   if (!checkReady()) {
//     const prerenderIntervalCheck = setInterval(() => {
//       if (checkReady()) {
//         return clearInterval(prerenderIntervalCheck);
//       }
//     }, 500);
//   }
// }

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
