import _ from 'lodash';
import { fail, start, success } from 'middleware/fetch';
import * as types from '../constants/actionTypes';
import initialState from './initialState';
import { getUserType } from 'actions/user';

export default function user(state, action) {
  state = typeof state === 'undefined' ? initialState.user : state;

  let stateChangedObj = {};

  switch (action.type) {
    case types.SET_USER: {
      let cognitoUser = action.cognitoUser || {};

      stateChangedObj.loggedIn = !_.isEmpty(cognitoUser);
      stateChangedObj.authData = {
        cognitoUser,
      };

      if (state.initObserver === false) {
        stateChangedObj.initObserver = true;
      }

      return _.merge({}, state, stateChangedObj);
    }
    case types.UNSET_USER: {
      // Return initial state with initObserver adjusted to be true
      return _.assign({}, initialState.user, { initObserver: true });
    }
    case types.SET_USER_ORDERS: {
      let orderData = action.orderData || {};

      if (orderData) {
        stateChangedObj.orders = orderData;
      }

      return _.merge({}, state, stateChangedObj);
    }
    case types.UNSET_USER_ORDERS: {
      return _.merge({}, state, {
        orders: null,
      });
    }
    case types.SET_AUTH_INPROGRESS: {
      return _.merge({}, state, {
        inProgress: true,
      });
    }
    case types.UNSET_AUTH_INPROGRESS: {
      return _.merge({}, state, {
        inProgress: false,
      });
    }
    case types.USER_LOGIN_SUCCESS: {
      let userSuccessData = action.successData || {};

      if (_.get(userSuccessData, 'user')) {
        stateChangedObj.loggedIn = true;
        stateChangedObj.loginFailure = null;

        // POSSIBLE @TODO:: Set refresh token into cookie so can keep it in session post 1hr expiry of custom token
      }

      stateChangedObj.inProgress = false;

      return _.merge({}, state, stateChangedObj);
    }
    case types.USER_LOGIN_FAILURE: {
      let userFailureData = action.failureData || {};
      const errorCode = _.get(userFailureData, ['error', 'code'], '');
      let errorDesc = _.get(
        userFailureData,
        ['error', 'description'],
        _.get(userFailureData, 'message', 'Server Error Encountered'),
      );

      // Cognito invalid credentials
      if (errorCode.search(/LOGIN_COGNITO_USER_AUTHENTICATION_FAILED/i) >= 0) {
        errorDesc = 'Incorrect username or password';
      }

      // let errorCode = _.get(userFailureData, 'refCode', 'server_err');

      return _.merge({}, state, {
        inProgress: false,
        loggedIn: false,
        loginFailure: `${errorDesc}`,
      });
    }
    case types.USER_LOGOUT_SUCCESS: {
      return _.assign({}, initialState.user, { initObserver: true });
    }
    case types.USER_LOGOUT_FAILURE: {
      let userFailureData = action.failureData || {};
      let errorDesc = _.get(
        userFailureData,
        ['error', 'description'],
        _.get(userFailureData, 'message', 'Server Error Encountered'),
      );
      let errorCode = _.get(userFailureData, 'refCode', 'server_err');

      return _.merge({}, state, {
        inProgress: false,
        logoutFailure: `${errorDesc} (${errorCode})`,
      });
    }
    case types.SET_USER_FORGOT_PASSWORD: {
      let statusData = action.statusData || {};

      return _.merge({}, state, {
        inProgress: false,
        forgotPasswordResult: statusData,
      });
    }
    case types.UNSET_USER_FORGOT_PASSWORD: {
      return _.merge({}, state, {
        forgotPasswordResult: null,
      });
    }
    case types.SET_USER_RESET_PASSWORD: {
      let statusData = action.statusData || {};

      return _.merge({}, state, {
        inProgress: false,
        resetPasswordResult: statusData,
      });
    }
    case types.UNSET_USER_RESET_PASSWORD: {
      return _.merge({}, state, {
        resetPasswordResult: null,
      });
    }
    case types.SET_USER_CHANGE_PASSWORD: {
      let statusData = action.statusData || {};

      return _.merge({}, state, {
        inProgress: false,
        changePasswordResult: statusData,
      });
    }
    case types.UNSET_USER_CHANGE_PASSWORD: {
      return _.merge({}, state, {
        changePasswordResult: null,
      });
    }
    case types.SET_USER_CUSTOM_TOKEN: {
      // @TODO
      return state;
    }
    case types.SET_USER_PROFILE: {
      let profileData = action.profileData || {};

      if (profileData) {
        stateChangedObj.profile = profileData;
      }

      return _.merge({}, state, stateChangedObj);
    }
    case start(types.UPDATE_USER_PROFILE): {
      action.asyncDispatch({ type: types.RESET_USER_SETTINGS_ALERT });
      action.asyncDispatch({ type: types.UPDATE_USER_SETTINGS_INPROGRESS });
      return _.merge({}, state, {
        profile: {
          ...state.profile,
          inProgress: true,
        },
      });
    }
    case success(types.UPDATE_USER_PROFILE): {
      action.asyncDispatch(getUserType());
      action.asyncDispatch({ type: types.UPDATE_USER_SETTINGS_SUCCESS });
      let alertText = '';
      const apiActionContextOptionsBody = _.get(
        action,
        'apiActionContext.options.body',
        {},
      );
      if (apiActionContextOptionsBody.hasOwnProperty('ppidReset')) {
        alertText = 'Your advertising identifier will be reset within 3 hours.';
      }
      action.asyncDispatch({
        type: types.SHOW_USER_SETTINGS_SUCCESS_ALERT,
        payload: alertText,
      });
      return _.merge({}, state, {
        profile: {
          ...state.profile,
          inProgress: false,
        },
      });
    }
    case fail(types.UPDATE_USER_PROFILE): {
      let alertText = '';
      const apiActionContextOptionsBody = _.get(
        action,
        'apiActionContext.options.body',
        {},
      );
      if (apiActionContextOptionsBody.hasOwnProperty('ppidReset')) {
        alertText = `We couldn't reset your identifier for some reason. Please try again later.`;
      }
      action.asyncDispatch({ type: types.UPDATE_USER_SETTINGS_FAILURE });
      action.asyncDispatch({
        type: types.SHOW_USER_SETTINGS_FAILURE_ALERT,
        payload: alertText,
      });
      return _.merge({}, state, {
        profile: {
          ...state.profile,
          inProgress: false,
          error: action.payload || { description: action.type },
        },
      });
    }
    case types.SET_USER_SETTINGS_SUCCESS: {
      return _.merge({}, state, {
        settings: {
          ...action.settings,
          inProgress: false,
        },
      });
    }

    case types.UPDATE_USER_SETTINGS_INPROGRESS: {
      return _.merge({}, state, {
        settings: {
          ...state.settings,
          isUpdated: false,
        },
      });
    }
    case types.UPDATE_USER_SETTINGS_SUCCESS: {
      return _.merge({}, state, {
        settings: {
          ...state.settings,
          isUpdated: true,
        },
      });
    }
    case types.UPDATE_USER_SETTINGS_FAILURE: {
      return _.merge({}, state, {
        settings: {
          ...state.settings,
          isUpdated: false,
        },
      });
    }
    case types.SET_USER_SETTINGS_FAILURE: {
      return _.merge({}, state, {
        settings: {
          ...action.settings,
          inProgress: false,
        },
      });
    }
    case types.SET_USER_SETTINGS_INPROGRESS: {
      return _.merge({}, state, {
        settings: {
          ...state.settings,
          inProgress: true,
        },
      });
    }

    case types.VALIDATE_EMAIL_INPROGRESS: {
      return {
        ...state,
        validateEmail: {
          inProgress: true,
          isSuccess: false,
          isEmailRegistered: false,
          errorData: {},
          email: null,
        },
      };
    }
    case types.VALIDATE_EMAIL_SUCCESS: {
      const { isEmailRegistered, username: email } = action;
      return {
        ...state,
        validateEmail: {
          inProgress: false,
          isSuccess: true,
          isEmailRegistered,
          errorData: {},
          email,
        },
      };
    }
    case types.VALIDATE_EMAIL_FAILURE: {
      const { errorData } = action;
      return {
        ...state,
        validateEmail: {
          inProgress: false,
          isSuccess: false,
          isEmailRegistered: false,
          errorData,
          email: null,
        },
      };
    }
    case types.CREATE_USER_BY_VOUCHER_INPROGRESS: {
      return _.merge({}, state, {
        createUserByVoucher: {
          inProgress: true,
          isSuccess: false,
          updateVoucherSubmitButton: false,
        },
      });
    }
    case types.CREATE_USER_BY_VOUCHER_SUCCESS: {
      return _.merge({}, state, {
        createUserByVoucher: {
          inProgress: false,
          isSuccess: true,
          updateVoucherSubmitButton: false,
        },
      });
    }
    case types.CREATE_USER_BY_VOUCHER_FAILURE: {
      return _.merge({}, state, {
        createUserByVoucher: {
          inProgress: false,
          isSuccess: false,
          updateVoucherSubmitButton: false,
        },
      });
    }
    case types.UPDATE_VOUCHER_SUBMIT_BUTTON: {
      return _.merge({}, state, {
        createUserByVoucher: {
          inProgress: false,
          isSuccess: false,
          updateVoucherSubmitButton: true,
        },
      });
    }
    case types.SUBSCRIPTION_VOUCHER_INPROGRESS: {
      return _.merge({}, state, {
        subscriptionVoucher: {
          inProgress: true,
          isSuccess: false,
          voucherData: {},
        },
      });
    }
    case types.SUBSCRIPTION_VOUCHER_SUCCESS: {
      let voucherData = action.voucherData;
      return _.merge({}, state, {
        subscriptionVoucher: {
          inProgress: false,
          isSuccess: true,
          voucherData: voucherData,
        },
      });
    }
    case types.SUBSCRIPTION_VOUCHER_FAILURE: {
      let voucherData = action.voucherData;

      return _.merge({}, state, {
        subscriptionVoucher: {
          inProgress: false,
          isSuccess: false,
          voucherData: voucherData,
        },
      });
    }
    case types.SUBSCRIPTION_USER_CREATE_INPROGRESS: {
      return _.merge({}, state, {
        subscriptionUserCreate: {
          inProgress: true,
          isSuccess: false,
        },
      });
    }
    case types.SUBSCRIPTION_USER_CREATE_SUCCESS: {
      const subscriptionData = action.subscriptionData;
      return _.merge({}, state, {
        subscriptionUserCreate: {
          inProgress: false,
          isSuccess: true,
          subscriptionData: subscriptionData,
        },
      });
    }
    case types.SUBSCRIPTION_USER_CREATE_FAILURE: {
      return _.merge({}, state, {
        subscriptionUserCreate: {
          inProgress: false,
          isSuccess: false,
        },
      });
    }
    case types.SET_USER_TYPE: {
      let userType = action.userType || {};

      if (userType) {
        stateChangedObj.userType = userType;
      }

      return _.merge({}, state, stateChangedObj);
    }
    case types.SET_ANALYTICS_USER_ID: {
      let analyticUserId = action.analyticUserId || {};

      if (analyticUserId) {
        stateChangedObj.analytics = analyticUserId;
      }

      return _.merge({}, state, stateChangedObj);
    }
    case types.SET_USER_TAGS: {
      let userTags = action.userTags || {};

      if (userTags) {
        stateChangedObj.userTags = userTags;
      }

      return _.merge({}, state, stateChangedObj);
    }
    case types.SET_USER_ACCESS_GROUPS: {
      let accessGroups = action.accessGroups || [];

      return _.merge({}, state, { accessGroups });
    }
    case types.SET_IS_INTL_USER: {
      let isIntlUser = action.isIntlUser || {};

      if (isIntlUser) {
        stateChangedObj = isIntlUser;
      }

      return _.merge({}, state, stateChangedObj);
    }
    case types.SET_PREVIEW_CYBERSOURCE: {
      let previewCyberSource = action.previewCyberSource || {};

      if (previewCyberSource) {
        stateChangedObj = previewCyberSource;
      }

      return _.merge({}, state, stateChangedObj);
    }
    case types.SHOW_USER_SETTINGS_SUCCESS_ALERT: {
      return _.merge({}, state, {
        settings: {
          ...state.settings,
          alert: {
            type: 'success',
            message:
              action.payload ||
              'Thank you, your details have successfully been updated',
          },
        },
      });
    }
    case types.SHOW_USER_SETTINGS_FAILURE_ALERT: {
      return _.merge({}, state, {
        settings: {
          ...state.settings,
          alert: {
            type: 'failure',
            message:
              action.payload || 'There was an error updating your details',
          },
        },
      });
    }
    case types.RESET_USER_SETTINGS_ALERT: {
      return _.merge({}, state, {
        settings: {
          ...state.settings,
          alert: {
            type: '',
            message: '',
          },
        },
      });
    }
    default: {
      return state;
    }
  }
}
