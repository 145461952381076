import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import qs from 'query-string';
import { encode } from 'js-base64';

import { getSearchPlaybackPath } from 'utils/path';
import {
  getArticleSectionPathName,
  shouldShowFreeBadge,
} from 'utils/getAssetData';
import FreemiumBadge from '../../../ui/ui-kit/Icons/FreemiumBadge';

import DetailSection from './DetailSection';
import ImageSection from './ImageSection';
import {
  analyticSearchAssetClicked,
  setCommingFromSearchResultsCookie,
} from '../../__analytics__';

export default function Item(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    type,
    id: assetId,
    slug: assetSlug,
    categoryId,
    categorySectionPath,
    lazyLoaded = true,
    externalurl: extURL,
    isPremiumUser,
    title,
    description = '',
    live = false,
    duration,
    isInTransition = false,
    assetPosition,
    selectedFilters,
    isLoggedIn,
  } = props;

  const assetPath = useMemo(() => {
    if (type === 'article') {
      return assetId && assetSlug
        ? getArticleSectionPathName({
            id: assetId,
            slug: assetSlug,
            categoryId,
          })
        : `/news`;
    } else {
      return getSearchPlaybackPath({
        assetId,
        assetSlug,
        categorySectionPath,
        categoryId,
      });
    }
  }, [type, assetId, assetSlug, categorySectionPath, categoryId]);
  const assetItem = {
    duration,
    isLive: live,
    isInTransition: isInTransition,
    description: description || '',
    id: assetId,
    title,
    type,
  };
  const analyticSearchAssetClickedPayload = {
    asset: assetItem,
    filters: selectedFilters,
    pathName: document.title,
    assetPosition,
  };

  const params = qs.parse(history.location.search);
  const redirectPath = `?${qs.stringify({
    ...params,
    redirect: true,
    to: assetPath,
    payload: encode(JSON.stringify(analyticSearchAssetClickedPayload)),
  })}`;

  const onHandleClick = (e) => {
    e.preventDefault();

    if (type !== 'article') {
      setCommingFromSearchResultsCookie();
    }
    analyticSearchAssetClicked(dispatch, analyticSearchAssetClickedPayload);
    history.push(assetPath);
  };

  return (
    <a
      href={redirectPath}
      target={extURL ? '_blank' : ''}
      rel='noreferrer'
      onClick={onHandleClick}
      className={
        'relative h-96 md:h-108 flex text-decoration-none text-white bg-core-dark-grey no-underline rounded-xl mb-8 p-16'
      }
    >
      {shouldShowFreeBadge(isPremiumUser, isLoggedIn, { ...props }) ? (
        <FreemiumBadge className={'os-top--4 os-right--4'} />
      ) : null}
      {lazyLoaded && <ImageSection {...props} className={'flex-none'} />}
      <DetailSection {...props} />
    </a>
  );
}
