import isArray from 'lodash/isArray';

export const LOADER_RESET = `loading/RESET`;
export const LOADER_IDLE = `loading/IDLE`;
export const LOADER_LOADING = `loading/LOADING`;
export const LOADER_LOADED = `loading/LOADED`;
export const LOADER_SUCCESS = `loading/SUCCESS`;
export const LOADER_FAIL = `loading/FAIL`;

const initialState = {
  status: LOADER_IDLE,
};

const getStatus = (loading, name) => {
  const currentStatus = loading[name] || LOADER_IDLE;
  return name ? currentStatus.status : loading.status;
};

const isLoading = (loading, name) => {
  return getStatus(loading, name) === LOADER_LOADING;
};

const isLoaded = (loading, name) => {
  const status = getStatus(loading, name);
  return status === LOADER_SUCCESS || status === LOADER_FAIL;
};

const isFailure = (loading, name) => {
  return getStatus(loading, name) === LOADER_FAIL;
};

const isSuccess = (loading, name) => {
  return getStatus(loading, name) === LOADER_SUCCESS;
};

const isSuccessAnyOf = (loading, events) => {
  return events.filter((event) => isSuccess(loading, event)).length > 0;
};

const isLoadingAnyOf = (loading, events) => {
  return events.filter((event) => isLoading(loading, event)).length > 0;
};

const hasLoadedAllOf = (loading, events) => {
  return (
    events.filter((event) => isLoaded(loading, event)).length === events.length
  );
};

const hasLoadedAnyOf = (loading, events) => {
  return events.filter((event) => isLoaded(loading, event)).length > 0;
};

// Use as mergedProps in the connected component.
// Need to add the following:
// 1. mapStateToProps: add `loading: state.loading`
// 2. mapDispatchToProps: add `resetLoader`.
const isSuccessOnce = (event, propName) => {
  return (stateProps, dispatchProps, ownProps) => {
    const success = isSuccess(stateProps.loading, event);
    if (success) {
      // Do the reset here so that componentDidUpdate will be cleaner
      dispatchProps.resetLoader(event);
    }
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      [propName]: success,
    };
  };
};

const loaderReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADER_RESET:
      const loaders = isArray(payload)
        ? payload.reduce((acc, name) => ({ ...acc, [name]: initialState }), {})
        : { [payload]: initialState };
      return {
        ...state,
        ...loaders,
        // [payload]: initialState,
      };
    case LOADER_IDLE:
    case LOADER_LOADING:
    case LOADER_LOADED:
    case LOADER_SUCCESS:
    case LOADER_FAIL:
      const status = type;
      const { status: prevStatus } = state;
      const currState = {
        timestamp: Date.now(),
        status,
        prevStatus,
      };
      return {
        ...state,
        ...currState,
        [payload]: currState,
      };
    default:
      return state;
  }
};

const resetLoader = (name) => {
  return {
    type: LOADER_RESET,
    payload: name,
  };
};

export {
  getStatus,
  isLoading,
  isLoaded,
  isFailure,
  isSuccess,
  isSuccessAnyOf,
  isLoadingAnyOf,
  hasLoadedAllOf,
  hasLoadedAnyOf,
  isSuccessOnce,
  resetLoader,
};

export default loaderReducer;
