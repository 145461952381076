import _ from 'lodash';
import jsCookie from 'js-cookie';
import { API, Auth } from 'aws-amplify';
// import rg4js from 'raygun4js';
import moment from 'moment';

import * as types from '../constants/actionTypes';
import { clearAllTickets, clearAllComments } from '../actions/ticket';
import { analyticAction, dispatchAnalytic } from '../ducks/analytic';

import parseJwt from '../utils/parseJwt';
import randomNumber from '../utils/randomNumber';
import {
  triggerGoogleAnalyticsEvent,
  replaceNeedlesInHaystack,
} from '../utils';

import {
  ANALYTIC_TIME_FORMAT,
  ANALYTIC_NEEDLES,
  ANALYTIC_NEEDLE_REPLACER,
  APIEVENT,
} from '../analytic/config';

import {
  segmentSessionHandleLogin,
  segmentSessionHandleLogout,
} from '../services/segment/segmentSession';

import defaultConfig from '../constants/defaultConfig';
import {
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { getDevice, getParsedUA, isMobile } from '../utils/userAgent';
import { getUserId } from 'selectors/user';
import { apiAction, fetchApi } from 'middleware/fetch';
import { resetLoader } from 'ducks/loading';
import { OFFERS_PURCHASE_FETCH } from 'ducks/offers';

const REFRESH_USER_ACCESS_COOKIE = 'OSF5UAC';
const USER_ANALYTICS_ID = 'OSAUID';
const USER_ANALYTICS_TYPE = 'OSAUTYPE';

export const USER_LOGGED_IN_COOKIE = 'OSLOGGEDIN';
export const USER_PLAY_COUNT_COOKIE = 'OSVDPLYS';
export const USER_VISIT_COUNT_COOKIE = 'OSVISITED';

export function getUserAccessCookie() {
  return jsCookie.get(REFRESH_USER_ACCESS_COOKIE);
}

export function setUserAccessCookie(expires) {
  return jsCookie.set(REFRESH_USER_ACCESS_COOKIE, Date.now(), {
    expires,
  });
}

export function removeUserAccessCookie() {
  return jsCookie.remove(REFRESH_USER_ACCESS_COOKIE);
}

export function setUserLoggedInCookie(loggedIn) {
  return jsCookie.set(USER_LOGGED_IN_COOKIE, loggedIn);
}

export function incrementVisitCount() {
  // Increment cookie value by 1 - OSVISITED
  const appOpenedCookie = jsCookie.get(USER_VISIT_COUNT_COOKIE);
  const appOpenedCount = parseInt(appOpenedCookie, 10);

  return typeof appOpenedCookie !== 'undefined' && _.isNumber(appOpenedCount)
    ? jsCookie.set(USER_VISIT_COUNT_COOKIE, appOpenedCount + 1)
    : jsCookie.set(USER_VISIT_COUNT_COOKIE, 1, { expires: 90 });
}

function cleanUpMedalliaCookies() {
  setUserLoggedInCookie(false);
  jsCookie.remove(USER_PLAY_COUNT_COOKIE);
  return jsCookie.remove(USER_VISIT_COUNT_COOKIE);
}

export function getUserAnalyticsCookie() {
  return jsCookie.get(USER_ANALYTICS_ID);
}

export function setUserAnalyticsCookie(id) {
  return jsCookie.set(USER_ANALYTICS_ID, id);
}

export function removeUserAnalyticsCookie() {
  return jsCookie.remove(USER_ANALYTICS_ID);
}

export function getUserTypeAnalyticsCookie() {
  return jsCookie.get(USER_ANALYTICS_TYPE);
}

export function setUserTypeAnalyticsCookie(type) {
  return jsCookie.set(USER_ANALYTICS_TYPE, type);
}

export function removeUserTypeAnalyticsCookie() {
  return jsCookie.remove(USER_ANALYTICS_TYPE);
}

export function resetUserAnalyticsCookies() {
  return (dispatch) => {
    const userIdCookie = getUserAnalyticsCookie();
    if (!userIdCookie || userIdCookie !== '-1') {
      setUserAnalyticsCookie('-1');
      dispatch(setAnalyticUserId({ analyticUserId: '-1' }));
    }

    const userTypeCookie = getUserTypeAnalyticsCookie();
    if (!userTypeCookie || userTypeCookie !== 'basic') {
      setUserTypeAnalyticsCookie('basic');
    }
  };
}

export function getDefaultExpiryInterval() {
  // If basic -> randomised check between 10 - 30 minutes
  // If premium ->  randomised check between 12 - 24 hours
  /*
    return type === 'basic' ?
    randomNumber(60 * 30 * 1000, 60 * 10 * 1000) :
    randomNumber(60 * 60 * 24 * 1000, 60 * 60 * 12 * 1000)
  */

  return randomNumber(60 * 30 * 1000, 60 * 10 * 1000);
}

export function initUserObserver() {
  return (dispatch) => {
    // Retrieves current authenticated session (if applicable)
    Auth.currentSession()
      .then((userSession) => {
        //console.log('userSession > ', userSession);

        // Retrieves current authenticated user (if applicable)
        Auth.currentAuthenticatedUser()
          .then((user) => {
            // Increment visit count
            incrementVisitCount();

            // Get user details
            const userId = _.get(user, ['attributes', 'sub']);
            if (userId) {
              /*
              rg4js('setUser', {
                identifier: userId,
                isAnonymous: false
              });
              */
              // Fetch user type
              dispatch(getUserType(userId));

              segmentSessionHandleLogin(userId);
            }

            dispatch(setUser(user));
          })
          .catch((err) => {
            console.log(err);

            // Incase of error pass device id to segment analytics
            const { deviceId } = getDevice();
            segmentSessionHandleLogin(deviceId);
            // Remove user related cookies (i.e. refresh user access cookie)

            cleanUpMedalliaCookies();
            dispatch(resetUserAnalyticsCookies());

            dispatch(unsetUser(null));
          });
      })
      .catch((err) => {
        console.log(err);
        // Incase of error pass device id to segment analytics
        const { deviceId } = getDevice();
        segmentSessionHandleLogin(deviceId);
        // Remove user related cookies (i.e. refresh user access cookie)
        //removeUserAccessCookie();
        cleanUpMedalliaCookies();
        dispatch(resetUserAnalyticsCookies());

        dispatch(unsetUser(null));
      })
      .finally(() => {
        dispatch(toggleAuthInProgress(false));
      });
  };
}

export function loginUserUtil(
  username,
  password,
  dispatch,
  response,
  getState,
  voucherUser = false,
  newVoucherUser = false,
) {
  const code = _.get(response, 'code');
  const result = _.get(response, 'result');

  // Only allow amplify sign in if authLogin lambda responds with authenticated
  if (code === 'AUTHENTICATION_OK') {
    // If we have the tokens try to perform a manual sign in rather than
    // duplicating
    const { AccessToken, RefreshToken, IdToken } = result;

    if (AccessToken && RefreshToken && IdToken) {
      const cognitoUserPool = new CognitoUserPool({
        UserPoolId: defaultConfig.awsUserPoolId,
        ClientId: defaultConfig.awsUserPoolWebClientId,
      });

      const signedInSession = new CognitoUserSession({
        AccessToken: new CognitoAccessToken({ AccessToken }),
        RefreshToken: new CognitoRefreshToken({ RefreshToken }),
        IdToken: new CognitoIdToken({ IdToken }),
      });

      const user = new CognitoUser({
        Username: username,
        Pool: cognitoUserPool,
      });

      user.setSignInUserSession(signedInSession);

      const parsedIdToken = parseJwt(IdToken);
      //const defaultExpiryInterval = getDefaultExpiryInterval();

      // Set User Access Check Interval cookie so it does not get checked initially on login
      //setUserAccessCookie(new Date(Date.now() + defaultExpiryInterval));

      dispatch(initUserObserver());

      if (voucherUser) {
        const currentState = getState();
        const voucherData = _.get(
          currentState,
          'user.subscriptionVoucher.voucherData',
        );
        const voucherCode = _.get(voucherData, 'voucherCode');
        const userId = _.get(parsedIdToken, 'sub');
        if (newVoucherUser) {
          dispatch(subscriptionUserCreate(userId, voucherCode));
        } else {
          dispatch(subscriptionVoucher(userId, voucherCode, true));
        }

        return true;
      }
    } else {
      Auth.signIn(username, password)
        .then((user) => {
          //const defaultExpiryInterval = getDefaultExpiryInterval();

          // Set User Access Check Interval cookie so it does not get checked initially on login
          //setUserAccessCookie(new Date(Date.now() + defaultExpiryInterval));

          dispatch(initUserObserver());
        })
        .catch((err) => {
          console.log(err);
          dispatch(toggleAuthInProgress(false));
          dispatch(
            loginFailure(_.get(err, ['response', 'data'], err), 'credentials'),
          );
        });
    }
  } else {
    dispatch(loginFailure({}, code));
    dispatch(toggleAuthInProgress(false));
    return false;
  }
}

export const updateUserFavouriteTeam = (
  userId,
  optusTeamCode,
  updatedFavouriteTeamIds,
  dispatch,
) => {
  return fetchApi('fe-api-favouriteteams', {
    method: 'put',
    path: `/users/${userId}/teams`,
    body: {
      teams: updatedFavouriteTeamIds,
    },
  }).then(() => {
    dispatchAnalytic({
      name: 'Favourite Add Saved',
      option: {
        selected_team: optusTeamCode,
      },
    });
    dispatch(getUserType(userId));
  });
};

export function getUserType(userId) {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_USER_SETTINGS_INPROGRESS });
    const state = getState();
    const { user } = state;
    const currentUserId = getUserId(user);
    const finalUserId = userId || currentUserId;
    if (finalUserId) {
      API.get('fe-api-userGetUserDetails', `/${finalUserId}`, {
        queryStringParameters: {
          ts: Date.now(),
          include: 'profile',
        },
      })
        .then(async (response) => {
          const responseItem = _.get(response, ['data', 'item']);
          const type = _.get(responseItem, 'type', 'premium');
          const subscriptions = _.get(responseItem, 'subscriptions', []);
          const settings = _.get(responseItem, ['settings'], []);
          const profile = _.get(responseItem, ['profile'], []);

          dispatch(setUserOrders(subscriptions));

          dispatch(setUserProfile(profile));

          // Attempt to set the analytics cookie for new google tag manager
          const analyticUserId = _.get(responseItem, 'analyticUserId');
          dispatch(setAnalyticUserId({ analyticUserId }));
          const currentCookieAnalyticsUserId = getUserAnalyticsCookie();
          const analyticsUserType = _.get(responseItem, 'type');
          const currentCookieAnalyticsUserType = getUserTypeAnalyticsCookie();

          const accessGroups = _.get(responseItem, 'accessGroups', []);
          dispatch(setUserAccessGroups(accessGroups));

          const isIntlUser = _.get(responseItem, ['isIntlUser'], false);
          dispatch(setisIntlUser({ isIntlUser }));

          const previewCyberSource = _.get(
            responseItem,
            ['previewCyberSource'],
            false,
          );
          dispatch(setPreviewCyberSource({ previewCyberSource }));

          if (
            analyticUserId &&
            analyticUserId !== currentCookieAnalyticsUserId
          ) {
            setUserAnalyticsCookie(analyticUserId);
          }
          if (
            analyticsUserType &&
            analyticsUserType !== currentCookieAnalyticsUserType
          ) {
            setUserTypeAnalyticsCookie(analyticsUserType);
          }

          // Todo: add userSegmentTags
          const userTags = _.get(responseItem, 'userSegmentTags');
          dispatch(setUserTags(userTags));

          dispatch(
            setUserType({
              type,
              updated: true,
            }),
          );
          dispatch(setUserSettings(settings)); // moved this action to the end so this can be used as a flag that all user detail data is ready.
        })
        .catch((err) => {
          dispatch(setUserSettingsFailure());
        });
    }
  };
}

export function getUserOrders(userId) {
  return (dispatch) => {
    if (userId) {
      API.get('fe-api-dev-userGetOrders', `/${userId}`, {})
        .then((response) => {
          if (!response.error) {
            // Successfully fetched user orders
            dispatch(setUserOrders(response));
          } else {
            dispatch(unsetUserOrders());
          }
        })
        .catch((err) => {
          // Error so clear user orders
          dispatch(unsetUserOrders());
        });
    }
  };
}

export const loginUser = (userData) => {
  return (dispatch, getState) => {
    const username = _.toLower(_.trim(_.get(userData, 'email')));
    const password = _.get(userData, 'password');
    const voucherUser = _.get(userData, 'voucherUser');

    const params = {
      body: {
        password,
        username,
        rememberMe: 'false',
      },
      headers: {},
    };

    const endpoint = 'fe-api-dev-authLogin';
    API.post(endpoint, '', params)
      .then((response) => {
        loginUserUtil(
          username,
          password,
          dispatch,
          response,
          getState,
          voucherUser,
        );

        setUserLoggedInCookie(true);

        // Time setter for Analytic
        const now = moment().format(ANALYTIC_TIME_FORMAT);

        const traversedResponse = replaceNeedlesInHaystack(
          ANALYTIC_NEEDLES,
          ANALYTIC_NEEDLE_REPLACER,
        )(response);

        const traversedBodyParams = replaceNeedlesInHaystack(
          ANALYTIC_NEEDLES,
          ANALYTIC_NEEDLE_REPLACER,
        )(params.body);

        const payload = {
          http: {
            name: 'OS ML Auth API',
            request: {
              endPoint: endpoint,
              requestBodyStr: JSON.stringify(traversedBodyParams),
              requestTime: now,
            },
            response: {
              httpStatus: 200,
              responseBodyStr: JSON.stringify(traversedResponse),
              responseHeader: {},
              responseTime: now,
            },
          },
        };
        const { userId } = response;
        dispatch(
          analyticAction({
            eventName: APIEVENT.AUTH,
            level: 'info',
            userId,
            ...payload,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          loginFailure(_.get(err, ['response', 'data'], err), 'credentials'),
        );

        // Time setter for Analytic
        const now = moment().format(ANALYTIC_TIME_FORMAT);

        // Destructure keys
        const { response = {} } = err;
        const { status, data = {}, headers } = response;

        const traversedData = replaceNeedlesInHaystack(
          ANALYTIC_NEEDLES,
          ANALYTIC_NEEDLE_REPLACER,
        )(data);

        const traversedHeaders = replaceNeedlesInHaystack(
          ANALYTIC_NEEDLES,
          ANALYTIC_NEEDLE_REPLACER,
        )(headers);

        const traversedBodyParams = replaceNeedlesInHaystack(
          ANALYTIC_NEEDLES,
          ANALYTIC_NEEDLE_REPLACER,
        )(params.body);

        const payload = {
          http: {
            name: 'OS ML Auth API',
            request: {
              endPoint: endpoint,
              requestBodyStr: JSON.stringify(traversedBodyParams),
              requestTime: now,
            },
            response: {
              httpStatus: status,
              responseBodyStr: JSON.stringify(traversedData),
              responseHeader: JSON.stringify(traversedHeaders),
              responseTime: now,
            },
          },
        };

        dispatch(
          analyticAction({
            eventName: 'OS ML Auth API',
            level: 'error',
            userId: response?.userId,
            ...payload,
          }),
        );
      });
  };
};

export const logoutUser = ({ isPasswordChanged } = {}) => {
  return (dispatch, getState) => {
    dispatch(toggleAuthInProgress(true));

    const userSignOut = () => {
      Auth.signOut()
        .then(() => {
          dispatch(
            analyticAction({
              eventName: APIEVENT.LOGOUT,
              level: 'info',
              result: {
                status: true,
              },
            }),
          );
          dispatch(logoutSuccess(true));
          isPasswordChanged &&
            dispatch(setChangePassword({ status: true, isChanged: true }));
          // Remove all user related tickets and ticket comments
          dispatch(clearAllTickets());
          dispatch(clearAllComments());

          // Reset user specific purchase offers
          dispatch(resetLoader([OFFERS_PURCHASE_FETCH]));

          // Remove user related cookies (i.e. refresh user access cookie)
          cleanUpMedalliaCookies();
          dispatch(resetUserAnalyticsCookies());
          segmentSessionHandleLogout();
          const { deviceId } = getDevice();
          segmentSessionHandleLogin(deviceId);
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            logoutFailure(_.get(err, ['response', 'data'], err), 'server_err'),
          );

          const traversedErr = replaceNeedlesInHaystack(
            ANALYTIC_NEEDLES,
            ANALYTIC_NEEDLE_REPLACER,
          )(err);

          dispatch(
            analyticAction({
              eventName: APIEVENT.LOGOUT,
              level: 'error',
              result: {
                status: false,
                err: JSON.stringify(traversedErr),
              },
            }),
          );
        })
        .finally(() => {
          dispatch(toggleAuthInProgress(false));
        });

      /*firebase.auth().signOut().then(() => {
        // Sign-out successful.
        // Optional: Proceed to signout of vimond via the cloud function
        dispatch(logoutSuccess(true));

        // Remove refresh user access cookie
        _removeRefreshUserAccessCookie();

        clearTimeout(window.refreshTokenTimeout);
        window.refreshTokenTimeout = null;
      }).catch(error => {
        dispatch(logoutFailure(_.get(error, ['response', 'data'], error), 'server_err'));
      });*/
    };
    const state = getState();
    const { user } = state;
    const userId = getUserId(user);
    const { defaultPlatform, version: appVersion } = defaultConfig;
    const parsedUA = getParsedUA();
    const osName = _.get(parsedUA, ['os', 'name'], '');
    const osVersion = _.get(parsedUA, ['os', 'version'], '0');
    const browserVersion = _.get(parsedUA, ['browser', 'version'], '0');
    let browserName = _.get(parsedUA, ['browser', 'name'], 'Unknown');
    if (isMobile()) {
      if (!browserName.toLowerCase().includes('mobile')) {
        browserName = `Mobile ${browserName}`;
      }
    }
    const { deviceId } = getDevice();
    const queryStringParameters = {
      deviceId,
      platform: defaultPlatform,
      osName,
      osVersion,
      appVersion,
      browserName,
      browserVersion,
    };
    API.del('fe-api-logoutUser', `/${userId}`, { queryStringParameters })
      .then(() => {
        userSignOut();
      })
      .catch(() => {
        // Do nothing but sign user out
        userSignOut();
      });
  };
};

export const toggleAuthInProgress = (inprogress) => {
  return (dispatch) => {
    if (inprogress) {
      dispatch({
        type: types.SET_AUTH_INPROGRESS,
        inprogress,
      });
    } else {
      dispatch({
        type: types.UNSET_AUTH_INPROGRESS,
        inprogress,
      });
    }
  };
};

export const forgotPassword = (userEmail) => {
  return (dispatch) => {
    const params = {
      body: {
        email: userEmail,
      },
      headers: {},
    };

    API.post('fe-api-dev-authForgotPassword', '', params)
      .then((response) => {
        dispatch(setForgotPassword(response));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setForgotPassword(_.get(err, ['response', 'data'], err)));
      });
  };
};

export const postDataToSubhub = ({ preferredEmail, target }) => {
  return (dispatch) => {
    const params = {
      body: {
        preferredEmail,
        target,
      },
      headers: {},
    };

    API.post('post-data-to-subhub', '', params)
      .then((response) => {
        dispatch(setDataToSubhub(response));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setDataToSubhub(_.get(err, ['response', 'data'], err)));
      });
  };
};

export const resetPassword = ({ resetToken, resetCode, newPassword }) => {
  return (dispatch) => {
    const params = {
      body: {
        token: resetToken,
        code: resetCode,
        password: newPassword,
      },
      headers: {},
    };

    API.post('fe-api-dev-authResetPassword', '', params)
      .then((response) => {
        dispatch(setResetPassword(response));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setResetPassword(_.get(err, ['response', 'data'], err)));
      });
  };
};

export const changePassword = (user, oldPassword, newPassword) => {
  return (dispatch) => {
    const userId = _.get(
      user,
      ['analytics', 'analyticUserId'],
      _.get(user, ['authData', 'cognitoUser', 'attributes', 'sub'], '0'),
    );

    const params = {
      body: {
        oldPassword,
        newPassword,
      },
      headers: {},
    };
    if (!userId) {
      return;
    }

    API.post('fe-api-dev-authChangePassword', `/${userId}`, params)
      .then((response) => {
        dispatch(setChangePassword(response));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setChangePassword(_.get(err, ['response', 'data'], err)));
      });
  };
};

// export function refreshUser(user, tokenData) {
//   return dispatch => {
//     const currentUser = typeof user !== 'undefined' && user !== null ? user : firebase.auth().currentUser;
//
//     const userClaims = _.get(tokenData, 'claims');
//     const userId = _.get(userClaims, 'user_id', _.get(userClaims, 'uid', '0'));
//     const osToken = _.get(tokenData, 'token');
//
//     let refreshUserUrl = `${defaultConfig.cloudFunctionBaseUrl}/userRefreshUser/users/${userId}/refresh`;
//
//     axios.post(
//       refreshUserUrl,
//       {},
//       {
//         'headers':{
//           'osToken': osToken
//         }
//       }
//     )
//       .then(response => {
//         //console.log(response.data);
//
//         currentUser.getIdTokenResult(true)
//           .then(refreshData => {
//             dispatch(setUser(refreshData));
//
//             // Set refresh user access cookie
//             //_setRefreshUserAccessCookie();
//
//             // Reset the timer for keeping user token fresh as new token has been generated
//             //_keepUserFresh(currentUser, refreshData, dispatch);
//           });
//       })
//       .catch(error => {
//         // Error
//         // Do nothing
//       });
//   };
// }

export function setUser(cognitoUser) {
  return {
    type: types.SET_USER,
    cognitoUser,
  };
}

export function unsetUser(anonData) {
  return {
    type: types.UNSET_USER,
    anonData,
  };
}

export function setUserOrders(orderData) {
  return {
    type: types.SET_USER_ORDERS,
    orderData,
  };
}

export function unsetUserOrders() {
  return {
    type: types.UNSET_USER_ORDERS,
  };
}

export function loginSuccess(successData) {
  return {
    type: types.USER_LOGIN_SUCCESS,
    successData,
  };
}

export function loginFailure(failureData, code) {
  if (typeof code !== 'undefined' && typeof failureData === 'object') {
    failureData.refCode = code;
  }

  return {
    type: types.USER_LOGIN_FAILURE,
    failureData,
  };
}

export function logoutSuccess(successData) {
  return {
    type: types.USER_LOGOUT_SUCCESS,
    successData,
  };
}

export function logoutFailure(failureData, code) {
  if (typeof code !== 'undefined' && typeof failureData === 'object') {
    failureData.refCode = code;
  }

  return {
    type: types.USER_LOGOUT_FAILURE,
    failureData,
  };
}

export function setForgotPassword(statusData) {
  return {
    type: types.SET_USER_FORGOT_PASSWORD,
    statusData,
  };
}

export function setDataToSubhub(statusData) {
  return {
    type: types.SEND_DATA_TO_SUBHUB,
    statusData,
  };
}

export function unsetForgotPassword() {
  return {
    type: types.UNSET_USER_FORGOT_PASSWORD,
  };
}

export function setResetPassword(statusData) {
  return {
    type: types.SET_USER_RESET_PASSWORD,
    statusData,
  };
}

export function unsetResetPassword() {
  return {
    type: types.UNSET_USER_RESET_PASSWORD,
  };
}

export function setChangePassword(statusData) {
  return {
    type: types.SET_USER_CHANGE_PASSWORD,
    statusData,
  };
}

export function unsetChangePassword() {
  return {
    type: types.UNSET_USER_CHANGE_PASSWORD,
  };
}

export function setUserCustomToken(tokenData) {
  return {
    type: types.SET_USER_CUSTOM_TOKEN,
    tokenData,
  };
}

export function setUserType(userType) {
  return {
    type: types.SET_USER_TYPE,
    userType,
  };
}

export function setisIntlUser(isIntlUser) {
  return {
    type: types.SET_IS_INTL_USER,
    isIntlUser,
  };
}

export function setPreviewCyberSource(previewCyberSource) {
  return {
    type: types.SET_PREVIEW_CYBERSOURCE,
    previewCyberSource,
  };
}

export function setAnalyticUserId(analyticUserId) {
  return {
    type: types.SET_ANALYTICS_USER_ID,
    analyticUserId,
  };
}

export function setUserTags(userTags) {
  return {
    type: types.SET_USER_TAGS,
    userTags,
  };
}

export function setUserAccessGroups(accessGroups) {
  return {
    type: types.SET_USER_ACCESS_GROUPS,
    accessGroups,
  };
}

export function setUserSettings(settings) {
  return {
    type: types.SET_USER_SETTINGS_SUCCESS,
    settings: {
      isSuccess: true,
      ...settings,
    },
  };
}

export function setUserSettingsFailure() {
  return {
    type: types.SET_USER_SETTINGS_FAILURE,
    settings: {
      isSuccess: false,
    },
  };
}

export function setUserProfile(profileData) {
  return {
    type: types.SET_USER_PROFILE,
    profileData,
  };
}

export function validateEmail(username) {
  return (dispatch) => {
    if (!username) {
      return;
    }
    dispatch({ type: types.VALIDATE_EMAIL_INPROGRESS });

    API.get('fe-api-dev-validateEmail', `/${username}`, {
      timeout: defaultConfig.defaultApiTimeout,
    })
      .then((result) => {
        dispatch({
          type: types.VALIDATE_EMAIL_SUCCESS,
          username,
          isEmailRegistered: result && result.isEmailRegistered,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.VALIDATE_EMAIL_FAILURE, errorData: err });
      });
  };
}

export function createUserByVoucher(userInfo) {
  return (dispatch, getState) => {
    const {
      userName,
      password,
      firstName,
      lastName,
      mobileNumber,
      dateOfBirth = null,
    } = userInfo;
    dispatch({ type: types.CREATE_USER_BY_VOUCHER_INPROGRESS });
    const params = {
      body: {
        userName: userName,
        password: password,
        firstName: firstName,
        lastName: lastName,
        mobileNumber: mobileNumber,
        dateOfBirth: dateOfBirth,
        notifyUserOnCreation: 'NONE',
      },
      headers: {},
    };

    API.post('fe-api-createUser', '', params)
      .then((response) => {
        const userId = _.get(response, 'userId');
        if (userId) {
          const result = loginUserUtil(
            userName,
            password,
            dispatch,
            response,
            getState,
            true,
            true,
          );
          if (result) {
            dispatch({ type: types.CREATE_USER_BY_VOUCHER_SUCCESS });
          } else {
            dispatch({ type: types.UPDATE_VOUCHER_SUBMIT_BUTTON });
          }
        } else {
          dispatch({ type: types.CREATE_USER_BY_VOUCHER_FAILURE });
        }
      })
      .catch((error) => {
        console.log(error);
        const serverError = _.get(error, ['response', 'data', 'error'], error);
        if (serverError && serverError.userId) {
          // trylogin
          const result = loginUser({
            email: userName,
            password: password,
            // Refer to LoginForm and this.props.loginUser comments.
            // For now, the usage of `voucherUser` is still correct as CreateAccount
            // is the last step in the form.
            voucherUser: true,
          });
          if (result) {
            dispatch({ type: types.CREATE_USER_BY_VOUCHER_SUCCESS });
          } else {
            dispatch({ type: types.CREATE_USER_BY_VOUCHER_FAILURE });
          }
        } else {
          dispatch({ type: types.UPDATE_VOUCHER_SUBMIT_BUTTON });
        }
      });
  };
}

// Action creator
export function subscriptionVoucher(userId, voucherCode, loggedInUser = false) {
  return (dispatch) => {
    const params = {
      headers: {},
    };
    dispatch({ type: types.SUBSCRIPTION_VOUCHER_INPROGRESS });
    const path = userId
      ? `users/${userId}/vouchers/${voucherCode}`
      : `vouchers/${voucherCode}`;
    const endpoint = userId
      ? 'fe-api-dev-subscriptionVoucher-existing-user'
      : 'fe-api-dev-subscriptionVoucher-new-user';
    API.get(endpoint, `/${path}/eligibility`, params)
      .then((response) => {
        const voucherData = _.get(response, 'data');
        const item = _.get(voucherData, 'item');
        if (item) {
          const isValid = _.get(item, 'eligibility');
          dispatch({
            type: types.SUBSCRIPTION_VOUCHER_SUCCESS,
            voucherData: item,
          });

          if (userId && !isValid) {
            triggerGoogleAnalyticsEvent(
              'voucher',
              'login',
              'error(ineligible)',
            );
          }
          if (userId && isValid && loggedInUser) {
            dispatch(subscriptionUserCreate(userId, voucherCode));
          }
        } else {
          dispatch({
            type: types.SUBSCRIPTION_VOUCHER_FAILURE,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        const error = _.get(err, ['response', 'data'], err);
        dispatch({
          type: types.SUBSCRIPTION_VOUCHER_FAILURE,
          voucherData: error,
        });
      });
  };
}

export function subscriptionUserCreate(userId, voucherCode) {
  return (dispatch) => {
    if (!userId) {
      return;
    }
    dispatch({ type: types.SUBSCRIPTION_USER_CREATE_INPROGRESS });

    const params = {
      body: {
        subscription: {
          notifyUserOnCreation: 'EMAIL',
          payment: {
            paymentMethod: 'VOUCHER',
            appId: '',
            voucher: voucherCode,
            externalReference: '',
          },
          referrer: 'optus-it',
          appName: 'WEB',
        },
      },
    };

    API.post('fe-api-dev-subscriptionUserCreate', `/${userId}`, params)
      .then((response) => {
        const subscriptionData = _.get(response, 'data');
        const item = _.get(subscriptionData, 'item');
        //removeUserAccessCookie();

        if (item) {
          dispatch({
            type: types.SUBSCRIPTION_USER_CREATE_SUCCESS,
            subscriptionData: item,
          });
        } else {
          dispatch({ type: types.SUBSCRIPTION_USER_CREATE_FAILURE });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.SUBSCRIPTION_USER_CREATE_FAILURE });
      });
  };
}

export function updateUserProfile(profileData) {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const currentUserId = getUserId(user);
    dispatch(
      apiAction(types.UPDATE_USER_PROFILE, 'fe-api-dev-userProfile', {
        method: 'put',
        path: `/${currentUserId}`,
        body: {
          ...profileData,
        },
      }),
    );
  };
}

export function updateUserSettings(username, payload) {
  return (dispatch) => {
    if (!username) {
      return;
    }

    const params = {
      body: {
        ...payload,
      },
    };

    dispatch({ type: types.UPDATE_USER_SETTINGS_INPROGRESS });
    return API.put('fe-api-dev-userSettings', `/${username}`, params)
      .then((response) => {
        dispatch({ type: types.UPDATE_USER_SETTINGS_SUCCESS });
        dispatch(getUserType(username));
      })
      .catch((err) => {
        dispatch({ type: types.UPDATE_USER_SETTINGS_FAILURE });
        dispatch(setUserSettingsFailure());
        console.log(err);
      });
  };
}
